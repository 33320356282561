/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BiPackage } from "react-icons/bi";
import { addPackage , getPackage , editPackage, getLanguage} from '../../redux/MainActions'
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';

const PackageAddEdit = () => {
    const param = useParams()
    console.log("param", param.id);

    const dispatch = useDispatch()
    const [ packageData , setPackageData ] = useState({
        titleEn:'',
        titleAr:'',
        priority:'',
        isActive:"true"
    })

    const packageList = useSelector(state=>state?.HitFitAdmin?.Package)
    // const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
    // const Language = useSelector(state=>state?.HitFitAdmin?.Langauge)
   
    //  console.log("Language",Language);

    useEffect(()=> {
        if(param.id){
         findPackage(param.id)
        }
    },[param.id])

    const findPackage = (id) =>{
        var data = {
            "id":id,
        }
        dispatch(getPackage(data))
    }

    // useEffect(()=>{
    //     getLanguageData()
    // },[])

    // const getLanguageData = () => {
    //     dispatch(getLanguage())
    // }

    useEffect(()=> {
        if(packageList!==undefined && param.id){
            var spr = packageList.filter(data=>data.id==param.id)
            console.log("spr",spr);
            setPackageData({
                titleEn: spr[0]?.titleEn,
                titleAr: spr[0]?.titleAr,
                priority: spr[0]?.priority,
                isActive: spr[0]?.isActive?"true":"false"
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[packageList!==undefined])

    const onChangeHandle = (e) => {
        const { value , name } = e.target
        setPackageData({...packageData,[name]: value})
        if(e.target.name === 'languageId'){
            setPackageData({...packageData,[name]:parseInt(value)})
        }
    }
    
    const submitHandle = (e) => {
        e.preventDefault()
        if(packageData.titleEn===''){
            swal({
              title: 'Error!',
              text: "Please Add Title En!",
              icon: "warning",
              button: "Ok",
            });
            return false
        }else if(packageData.titleAr===''){
            swal({
                title: 'Error!',
                text: "Please Add Title Ar!",
                icon: "warning",
                button: "Ok",
              });
              return false
        }else if( packageData.isActive===''){
            swal({
                title: 'Error!',
                text: "Please Select Status!",
                icon: "warning",
                button: "Ok",
              });
              return false
        }
        
        if(param.id){
            var data ={
                id:param.id,
                titleEn: packageData.titleEn,
                titleAr: packageData.titleAr,
                priority: parseInt(packageData.priority),
                isActive: packageData.isActive == 'true'? true : false
            }
            dispatch(editPackage(data))
        }else {
            var data = {
                titleEn: packageData.titleEn,
                titleAr: packageData.titleAr,
                priority: parseInt(packageData.priority),
                // isActive: packageData.isActive == 'true'? true : false
            }
            dispatch(addPackage(data))
            console.log("data",data);
        }
    }

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
            <span>
                <Link to='/health'><span><BiPackage style={{color:"#CE7777"}}/></span>Package </Link>/
                <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined"><BiPackage style={{color:"#CE7777"}}/></span>{param.id?"Edit":"Add"} Package</h3>
            <div>
                <Link to='/package'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
            <form onSubmit={submitHandle} >
                <div className="InputF">
                    <div className="inputField_type_one">
                        <label htmlFor='title'>Title (En)</label>
                        <input type="text" id='title' name='titleEn' placeholder='E.g: packageTitle123' value={packageData.titleEn} onChange={onChangeHandle}/>
                       
                        <label htmlFor='titleAr'>Title (Ar)</label>
                        <input type="text" id='titleAr' name='titleAr' placeholder='E.g: packageTitle123' value={packageData.titleAr} onChange={onChangeHandle}/>
                    </div>
                    <div className="isActive">
                        <label htmlFor='isActive'>Status</label>
                        <select name="isActive" id='isActive' value={packageData.isActive} onChange={onChangeHandle} >
                            <option value='true'>Active</option>
                            <option value='false'>InActive</option>
                        </select>
                        
                        <label htmlFor='priority'>Priority</label>
                        <input type="number" id='priority' name='priority' placeholder='E.g: 123' value={packageData.priority} onChange={onChangeHandle}/>
                    </div>
                </div>
                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span>{param.id? "Update": "Save"}</button>
                    <Link to='/package'>Cancel</Link>
                </div>
            </form>
        </div>
    </div>
  )
}

export default PackageAddEdit