import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { assignSubscriptionToUser, getSubscriptionForUser } from '../../redux/MainActions'
import { MdClose } from "react-icons/md";
import { ASSIGN_SUBSCRIPTION_TO_USER } from '../../redux/types';

const SubscriptionModal = ({ setSubsModal, userData }) => {
    
    const [subscriptionValue, setSubscriptionValue] = useState('')

    const userSubscription = useSelector(state => state.HitFitAdmin.subscriptionForUser)
    const assignSubscResponse = useSelector(state => state.HitFitAdmin.assignSubscription)

    console.log("userSubscription", userSubscription, assignSubscResponse, userData);
    const dispatch = useDispatch()

    useEffect(() => {
        if(assignSubscResponse && Object.keys(assignSubscResponse)?.length>0){
            if(assignSubscResponse?.success){
                setSubsModal(false)
                dispatch({
                    type: ASSIGN_SUBSCRIPTION_TO_USER,
                    payload:{}
                })
            }
        }
    },[assignSubscResponse])

    useEffect(() => {
        dispatch(getSubscriptionForUser())
    },[])

    const onSubmitHandler = () => {
        const payload = {
            userId: parseInt(userData?.[0]?.id),
            subscriptionId: parseInt(subscriptionValue)
        }
        dispatch(assignSubscriptionToUser(payload))
    }
  return (
    <div className='subscription-modal'>
        <div className="subs-container">
            <nav>
                <h3>Manage Subscription</h3>
                <MdClose id="close-icon" onClick={() => setSubsModal(false)}/>
            </nav>
            <section>
                <select className='input-field' value={subscriptionValue} onChange={e => setSubscriptionValue(e.target.value)}>
                    <option value="">Select Subscription</option>
                    {
                        userSubscription?.map((subs, index) => <option key={index} value={subs?.id}>{subs?.titleEn} {subs?.price}</option>)
                    }
                </select>
                <button className='primary-btn' onClick={onSubmitHandler}>Submit</button>
            </section>
        </div>
    </div>
  )
}

export default SubscriptionModal