import React, { useEffect, useState } from 'react'
import { IoIosCall } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { addContactUs, getContactUs } from '../../redux/MainActions'

const ContactUsAdd = () => {
  const dispatch = useDispatch()

  const contactData = useSelector(state=> state?.HitFitAdmin?.ContactUs)

  const [ contact , setContact ] = useState({
    name:"",
    email:"",
    phone:null,
    message:"",
    address:""
  })

  const onHandleChange =(e)=>{
    const {name, value} = e.target
    setContact({...contact,[name]:value})
  }
console.log("contact",contact);

  useEffect(()=>{
    getContactList()
  },[])

  const getContactList = () => {
    var data ={
    }
    dispatch(getContactUs(data))
  }

  useEffect(()=>{
    if(contactData){
      var spr = contactData
      setContact({
        name: spr?.name,
        email: spr?.email,
        phone: spr?.phone,
        message: spr?.message,
        address: spr?.address
      })
    }
  },[contactData])

  const onSubmit = (e) => {
    e.preventDefault()

    if(contact.name===''){
      swal({
        title: 'Error!',
        text: "Please Add Name!",
        icon: "warning",
        button: "Ok",
      });
      return false
    } else if(contact.email===''){
      swal({
        title: 'Error!',
        text: "Please Add Email!",
        icon: "warning",
        button: "Ok",
      });
      return false
    } else if(contact.phone==='' && contact.phone===null){
      swal({
        title: 'Error!',
        text: "Please Add  phone No!",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else if(contact.address===''){
      swal({
        title: 'Error!',
        text: "Please Add Address!",
        icon: "warning",
        button: "Ok",
      });
      return false
    } else if(contact.message===''){
      swal({
        title: 'Error!',
        text: "Please Add Message!",
        icon: "warning",
        button: "Ok",
      })
    }



      var data = {
        name: contact.name,
        email:contact.email,
        phone:contact.phone,
        message:contact.message,
        address:contact.address
      }
       dispatch(addContactUs(data))
       console.log("DATA", data);
     }
  

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}><IoIosCall/></span> Contact-Us</Link>/
                <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}><IoIosCall/></span> Contact-Us</h3>
            <div>
                <Link to='/users'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
          <form onSubmit={onSubmit} >
                <div className='InputF'>
                  <div className="inputField_type_one">
                    <label htmlFor="name">Name</label>
                    <input type="text"  id='name'  name='name' placeholder='E.g: hitfit Admin' value={contact.name} onChange={onHandleChange}/>
                    <label htmlFor="number">Phone</label>
                    <input type="number"  id='number'  name='phone' placeholder='E.g:  +912985454584' value={contact.phone} onChange={onHandleChange}/>
                  </div>

                  <div className="inputField_type_one">
                    <label htmlFor="email">E-mail</label>
                    <input type="email"  id='email'  name='email' placeholder='E.g: hitfit@gmail.com ' value={contact.email} onChange={onHandleChange}/>
                    <label htmlFor="message">Message</label>
                    <input type="text"  id='message'  name='message' placeholder='E.g: this is message ' value={contact.message} onChange={onHandleChange}/>
                  </div>
                </div>

                <div className='decription'>
                        <label htmlFor='address'>Address</label>
                        <textarea type="text" id='address' rows="3" name='address' placeholder='E.g: hitfit HQ Offic #17/17C BDA 3rd Sector HSR Layout'  value={contact.address} onChange={onHandleChange} />
                </div>

                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span>Save</button>
                    <Link to='/nutrition/category'>Cancel</Link>
                </div>
          </form>
        </div>
    </div>
  )
}

export default ContactUsAdd