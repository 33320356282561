/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import Toggle from 'react-toggle'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSession, getSession } from '../../redux/MainActions'
import swal from 'sweetalert'

const SessionList = () => {

    const dispatch = useDispatch()
    
    const sessionLIST = useSelector(state=>state?.HitFitAdmin?.Session)
  
    const [ session , setSession ] = useState([])
  
    useEffect(()=>{
      getSessionList()
    },[])
 
    const getSessionList = () => {
      var data = {
        // "isActive":true
      }
      dispatch(getSession(data))
    }

    useEffect(()=>{
      if(sessionLIST){
        setSession(sessionLIST)
      }
    },[sessionLIST])

    const handleDelete = (id)=>{
      if(id){
        swal({
          title: "Are you sure ?",
          text: "Once deleted,you will not be able to recover this Data!",
          icon: "warning",
          dangerMode: true,
          buttons: true,
        })
        .then(willDelete => {
          if (willDelete) {
            var data = {
                "liveSessionId": id
               }
               dispatch(deleteSession(data))
              //  console.log("deleteID", data);
          }
        });
      }
    }

    const columns = [
      {
        name:"Media",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Image</span>
          },
          customBodyRender:(Media)=>{
            return<div className='tableIMG'>
              <img src={Media?.url} alt="No Image here!" />
            </div>
          }
        },
      },
        {
          name: "workoutName",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Name</span>
            }
          },
        },
        {
          name: "status",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>live Status</span>
            }
          },
        },
        {
            name: "isActive",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Status</span>
              },
              customBodyRender:(isActive)=>{
                return(
                    <div>
                    <Toggle
                        checked={isActive}
                        // onChange={onStatusChange}
                         />
                    </div>
                )
            }
            },
        },
        {
          name: "id",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Action</span>
            },
            customBodyRender:(liveSessionId)=>{
              return(
                  <div>
                    <Link className='editBTN' to={'/live-session/'+liveSessionId}>
                      <button>Edit</button>
                    </Link>
                  </div>
              )
          }
          },
        },
        {
          name: "id",
          options: {
            filter: true,
            sort: true,
            // display:false,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Action</span>
            },
            customBodyRender:(id)=>{
              return(
                  <div className='deleteBTN'>
                      <button onClick={()=>handleDelete(id)}>Delete</button>
                  </div>
              )
          }
          },
        },
    ]

    const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };
  return (
    <div className='MainContainer'>
         <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>sensors</span>Session</Link>/
                <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>sensors</span>Sessions</h3>
            <div>
                <Link to='/live-session/create'>
                    ADD
                </Link>
            </div>
        </div>

        <MUIDataTable
            className="table-responsive"
            data={session?session:[]}
            columns={columns}
            options={options}
        />
    </div>
  )
}

export default SessionList