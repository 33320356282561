import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BiHelpCircle } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { getEnquiry } from '../../redux/MainActions';

const EnquiryDetails = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const ENQUIRY = useSelector(state=>state?.HitFitAdmin?.Enquiry)

    const [ enquiryDetail , setEnquiryDetail ] = useState([])
    console.log("Params", enquiryDetail);
    useEffect(()=> {
        getList()
      },[]) 
  
    const getList = () => {
        var data ={
        }
      dispatch(getEnquiry(data))
    }

    useEffect(()=>{
        if(ENQUIRY&&params.id){
            var spr = ENQUIRY.filter(data=>data.id==params.id)
            setEnquiryDetail(spr[0])
        }
    },[ENQUIRY])

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}><BiHelpCircle/></span>Enquiry Details</Link>/
                <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
            </span>
        </div>
        
        <div className="Header">
            <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}><BiHelpCircle/></span>Enquiry Details</h3>
            <div>
                <Link to='/enquiry'>
                    Back
                </Link>
            </div>
        </div>
        <div className="Box_Container">
            <div className='enquiry_details'>
                <div className='metadata'>
                    <h4>Name</h4>
                    <span>{enquiryDetail?.name}</span>
                </div>
                <div className='metadata'>
                    <h4>E-mail</h4>
                    <span>{enquiryDetail?.email}</span>
                </div>
                <div className='metadata'>
                    <h4>Phone No</h4>
                    <span>{enquiryDetail?.phone}</span>
                </div>
                <div className='metadata'>
                    <h4>Meassage</h4>
                    <span>{enquiryDetail?.message}</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EnquiryDetails