import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import { MdNotifications } from 'react-icons/md'
// import Toggle from 'react-toggle'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, sendNotification } from '../../redux/MainActions'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import swal from "sweetalert";


const PushNotification = () => {

    const dispatch =useDispatch()

  const UserList = useSelector(state=>state?.HitFitAdmin?.User)

  const [notfyType, setNotfyType] = useState('all')

  const [selectedUsers, setSelectedUsers] = useState([])

  const [ info , setInfo] = useState({
    title:"",
    message: ""
  })

  const [open, setOpen] = useState(false);
  
  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => setOpen(false);

  const [ USER , setUSER ] = useState([])
  
  console.log("USER" ,USER);

  const handleChange =(e)=>{
      const {name,value}=e.target
      setInfo({...info,[name]:value})
    }

    const onUserSelect =(e)=>{
        var userId = parseInt(e.target.value)
        if(!selectedUsers.includes(userId)){
            setSelectedUsers([...selectedUsers,userId])
        }else{
            var filter = selectedUsers.filter(data=>data!==userId)
            setSelectedUsers(filter)
        }
      }

  useEffect(()=>{
    getUserList()
  },[])

  const getUserList = () =>{
    var data = {
       "status": true
    }
    dispatch(getUser(data))
  } 

  useEffect(()=>{
    if(UserList){
      setUSER(UserList)
    }
  },[UserList])

  console.log('selectedUsers',selectedUsers);

  const onSendNotification =(e)=>{
    e.preventDefault()

    if(info.title===''){
        swal({
            title: 'Please Add Title!',
            // text: "Please Add Title!",
            icon: "warning",
            button: "Ok",
        })
        return false
    }else if(info.message===''){
        swal({
            title: 'Please Add Message!',
            // text: "Please Add Title!",
            icon: "warning",
            button: "Ok",
        })
        return false
    }

    if(notfyType=='all'){
        var data = {
            "title": info.title,
            "message": info.message,
            userIds:[]
        }
    console.log('all',data);
    dispatch(sendNotification(data))
    }else{
        var data = {
            "title": info.title,
            "message": info.message,
            userIds:selectedUsers
        }
        console.log('selected',data);
        dispatch(sendNotification(data))
    }
      setOpen(false)
        setInfo({
            title:"",
            message:""
        })
    }


    const columns = [
    {
      name: "fullName",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Name</span>
        }
      },
    },
    {
      name: "email",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Email</span>
        }
      },
    },
    {
      name: "phone",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Phone No</span>
        }
      },
    },
    {
        name: "id",
        label: "Select",
        options: {
          filter: true,
          sort: true,
          display:notfyType=='all'?false:true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Select</span>
          },
          customBodyRender:(id)=>{
            return <>
                <input onChange={onUserSelect} className='SelectBOX' type="checkbox" value={id} />
            </>
          }
        },
      },
   
];

const options = {
  filterType: "dropdown",
  search:false,
  filter:false,
  viewColumns: false,
  print: false,
  pagination:true,
  download: false,
  selectableRows: "none",
  // responsive: 'scrollMaxHeight',
};

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><MdNotifications style={{color:"#CE7777"}}/></span>Push Notification</Link>/
              <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><MdNotifications style={{color:"#CE7777"}}/></span>Push Notification</h3>
            <div className='selected'>
            <select name="" id="" onChange={(e)=>setNotfyType(e.target.value)}>
                <option value="all">All Users</option>
                <option value="selected">Selected Users</option>
            </select>
            <a onClick={onOpenModal}>
                MESSAGE
            </a>
        </div>
      </div>

      <MUIDataTable
          className="table-responsive"
          data={USER?USER:[]}
          columns={columns}
          options={options}
      />
      <Modal open={open} onClose={onCloseModal} center>
          <div className="messageModal">
              <h4>Enter Message Here!</h4>
              <input type="text" placeholder='Title' name='title'  value={info.title} onChange={handleChange}  />
              <textarea rows={7} type="text" placeholder='Message' name='message'  value={info.message} onChange={handleChange}  />
              <div className='btn'>
                <button className='cancel' onClick={onCloseModal}>Cancel</button>
                <button className='send' onClick={onSendNotification}>Send</button>
              </div>
          </div>
        </Modal>
    </div>
  )
}

export default PushNotification