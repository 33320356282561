/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import MUIDataTable from 'mui-datatables'
import {
  IconButton
} from "@material-ui/core";
import { Link } from 'react-router-dom'
import { HiUserCircle } from 'react-icons/hi'
// import Toggle from 'react-toggle'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserAccount, editUserStatus, getUser } from '../../redux/MainActions'
import Toggle from 'react-toggle'
import swal from 'sweetalert'
import { GoFilter } from "react-icons/go";
import SubscriptionModal from './SubscriptionModal';


const User = () => {

  const dispatch =useDispatch()

  const UserList = useSelector(state=>state?.HitFitAdmin?.User)

  const [ USER , setUSER ] = useState([])
  const [ userStatus , setUserStatus]= useState()

  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [subsModal, setSubsModal] = useState(false)
  const [userData, setUserData] = useState('')
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const togglePopup1 = () => {
    setShowPopup1(!showPopup1);
  };

  const togglePopup2 = () => {
    setShowPopup2(!showPopup2);
  };

  const handleDateFromChange = (e) => {
    setDateFrom(e.target.value);
  };

  const handleDateToChange = (e) => {
    setDateTo(e.target.value);
  };

  const handleFiltr = () => {
    var data ={
      createdAtFrom:dateFrom,
      createdAtTo:dateTo,
    }
    dispatch(getUser(data))
    console.log(data);
    setShowPopup1(false)
    setDateFrom("")
    setDateTo("")
  }
  
  console.log("dateFrom" ,dateFrom);
  console.log("dateTo" ,dateTo);

  useEffect(()=>{
    getUserList()
  },[])

  const getUserList = () =>{
    var data = {
       "status": true
    }
    dispatch(getUser(data))
  } 

  useEffect(()=>{
    if(UserList){
      var spr = UserList?.filter(data=>data.roleId==3)
      // console.log("spr",spr);
      setUSER(spr)
    }
  },[UserList])

  // const submit = (e) =>{
  //   console.log("id",e.target.value);
  // }

  const onStatusChange = (status,userID) =>{
    var data = {
        "userId":userID,
        "status":!status
    }
    dispatch(editUserStatus(data))
  }

  const AddButton = () => (
    // <Tooltip  id='filTaggle'>
      <IconButton onClick={togglePopup1}>
        <GoFilter />
      </IconButton>
    // </Tooltip>
  );
  const handleDelete = (id)=>{
    if(id){
      swal({
        title: "Are you sure ?",
        text: "Once deleted,you will not be able to recover this Data!",
        icon: "warning",
        dangerMode: true,
        buttons: true,
      })
      .then(willDelete => {
        if (willDelete) {
          var data = {
              "userId": id
             }
             dispatch(deleteUserAccount(data))
            //  console.log("deleteID", data);
        }
      });
    }
  }

  const subscriptionHandler = (data) => {
    setUserData(data)
    setSubsModal(true)
  }

  console.log("userData", userData);

  const columns = [
    
    {
      name: "fullName",
      options: {
        filter: true, 
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Name</span>
        }
      },
    },
    {
      name: "email",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600',
            // border:"1px solid red"
          }}>Email</span>
        },
        customBodyRender:(email)=>{
          return <div style={{
            letterSpacing:'0',
            // fontWeight:'600',
            // border:"1px solid red"
          }}>
            {email}
          </div>
        }
      },
    },
    {
      name: "UserSubscription",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Subs. Duration</span>
        },
        customBodyRender:(UserSubscription)=>{
          // console.log(`${day}/${month}/${year}`);
          return<div >
           {`${UserSubscription?UserSubscription?.duration==="null"? "No SubCription":UserSubscription?.duration:"No SubsCription"}`}
           {/* {`${UserSubscription? UserSubscription?.duration : UserSubscription.duration==="null"? "No Subscription":"UserSubscription.duration"}`} */}
          </div>
        }
      },
    },
    {
      name: "userSubscriptionCount",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Re-subscription </span>
        },
        customBodyRender:(userSubscriptionCount)=>{
          return <div style={{
            letterSpacing:'0',
            // fontWeight:'600',
            width:"50px"
            // border:"1px solid red"
          }}>
            {userSubscriptionCount}
          </div>
        }
      },
    },
    {
      name: "phone",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Phone No</span>
        }
      },
    },
    {
      name: "gender",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Gender</span>
        }
      },
    },
    {
      name: "loggedInWith",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>logged In With</span>
        }
      },
    },
    {
      name: "createdAt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Created Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(Date.parse(createdAt))
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            // console.log(`${day}/${month}/${year}`);
            return<div >
             {`${day}/${month}/${year}`}
            </div>
          }
        },
      },
    {
      name: "status",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Status</span>
          },
          customBodyRender:(status,tableMeta)=>{
            var userID = tableMeta?.rowData[6]
            return(
                <div>
                  <Toggle
                      checked={status}
                      onChange={()=>onStatusChange(status,userID)}
                  />
                </div>
            )
        }
        },
      },
    {
      name: "subscribed",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>User subscribed</span>
          },
          customBodyRender:(subscribed,tableMeta)=>{
            // var userID = tableMeta?.rowData[6]
            return(
                <div>
                  <Toggle
                    checked={subscribed}
                      // onChange={()=>onStatusChange(status,userID)}
                  />
                </div>
            )
        }
        },
      },
    {
      name: "phoneRegistered",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Phone Registered</span>
          },
          customBodyRender:(phoneRegistered,tableMeta)=>{
            // var userID = tableMeta?.rowData[6]
            return(
                <div>
                  <Toggle
                      checked={phoneRegistered}
                      // onChange={()=>onStatusChange(status,userID)}
                  />
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Add Subscription</span>
          },
          customBodyRender:(id, row)=>{
            // console.log("ada5da454", id, row)
            return(
                <div>
                  {/* <Link className='editBTN' to={'/user/'+id}> */}
                    <button className='primary-btn' onClick={() => subscriptionHandler(USER?.filter(dt => dt?.id === id))}>Manage Subscription</button>
                  {/* </Link> */}
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Action</span>
          },
          customBodyRender:(id)=>{
            return(
                <div>
                  <Link className='editBTN' to={'/user/'+id}>
                    <button>Edit</button>
                  </Link>
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>User Info</span>
          },
          customBodyRender:(id)=>{
            return(
                <div>
                  <Link className='userDetailBtn' to={'/user/details/'+id}>
                    <button>User Details</button>
                  </Link>
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          // display:false,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Action</span>
          },
          customBodyRender:(id)=>{
            return(
                <div className='deleteBTN'>
                    <button onClick={()=>handleDelete(id)}>Delete</button>
                </div>
            )
        }
        },
      },
];

const options = {
  filterType: "dropdown",
  search:true,
  filter:false,
  viewColumns: false,
  print: false,
  pagination:true,
  download: false,
  selectableRows: "none",
  responsive: 'scroll',
  download:true,
  tableBodyMaxHeight:'100%',
  viewColumns	: true,
  customToolbar: AddButton
};

  return (
    <div className='MainContainer'>
      {subsModal && <SubscriptionModal userData={userData} setSubsModal={setSubsModal}/> }
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><HiUserCircle style={{color:"#CE7777"}}/></span>Users</Link>/
              <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><HiUserCircle style={{color:"#CE7777"}}/></span>Users</h3>
            <div>
              <Link to='/users/add'>
                  ADD
              </Link>
          </div>
      </div>

      <div className='userTbl'>
        <MUIDataTable
            className="table-responsive tablsUSER "
            data={USER?USER:[]}
            columns={columns}
            options={options}
        />
      </div>
      {showPopup1 && (
        <div className="popup" >
              <div className="popup-content">
                <label>Date from</label>
                <input
                  type="date"
                  value={dateFrom}
                  onChange={handleDateFromChange}
                />
                <label className='lable'>Date To</label>
                <input type="date" value={dateTo} onChange={handleDateToChange} />
              </div>
              <button className='fillBtn' onClick={handleFiltr}>Submit</button>
        </div>
      )}
    </div>
  )
}

export default User