import MUIDataTable from 'mui-datatables'
import { MdGroupAdd } from "react-icons/md";
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { getWebOrders , getUser } from "../../redux/MainActions"
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import Toggle from 'react-toggle';

const WebOrders = () => {
    const  dispatch = useDispatch()
    const webOrderList = useSelector(state=>state?.HitFitAdmin?.weborders)
    const UserList = useSelector(state=>state?.HitFitAdmin?.User)

    useEffect(()=>{
        getUserList()
        getUserList2()
    },[])
    const getUserList = () =>{
        // var data = {
        //    "status": true
        // }
        dispatch(getWebOrders())
    }
    const getUserList2 = () =>{
        var data = {
           "status": true
        }
        dispatch(getUser(data))
    } 
    console.log("DATA", webOrderList);

    const columns =[
        {
            name: "UserId",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>User</span>
              },customBodyRender:(UserId)=>{
                var Filtered = UserList?.filter(cate=>cate.id===UserId)
                // console.log( "Filtered",Filtered);
                return <>
                  {Filtered[0]?Filtered[0]?.fullName:"No Defined"}
                </>
              }
            },
        },
        {
            name: "createdAt",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Order Date</span>
              },
              customBodyRender:(createdAt)=>{
                // var Filtered = UserList?.filter(cate=>cate.id===UserId)
                // console.log( "Filtered",Filtered);
                return <>
                  {moment(createdAt).format('L')}
                </>
              }
            },
        }, 
        {
            name:"tapPaymentMethod",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Payment Method</span>
                }
            },
        },
        {
            name:"tapPaymentType",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Payment Type</span>
                }
            },
        },
        {
            name:"recurred",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Recurred Payment</span>
                },
                customBodyRender:(recurred)=>{
                  return(
                      <div>
                      <span className={recurred?'succ':"fail"}>{recurred?'Yes':"No"}</span>
                      </div>
                  )
              }
            },
        },
        {
          name: "UserSubscription",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Reccured Amount</span>
            },
            customBodyRender:(UserSubscription)=>{
              var Filtered = UserSubscription
              // console.log( "Filtered",Filtered);
              return <>
                {UserSubscription?.price}
              </>
            }
          },
        }, 
        {
          name:"status",
          options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Payment Status</span>
              },
              customBodyRender:(status)=>{
                return(
                    <div>
                    <span className={status==="success"?'succ':"fail"}>{status}</span>
                    </div>
                )
            }
          },
        },
        {
          name:"message",
          options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Payment Message</span>
              },
              customBodyRender:(message)=>{
                return(
                    <div>
                    <span>{message}</span>
                    </div>
                )
            }
          },
        },
        {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>View Info</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div>
                      <Link className='userDetailBtn' to={'/web-orders/'+id}>
                        <button>View Details</button>
                      </Link>
                    </div>
                )
            }
            },
          },
    ]
    const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
      };
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/'><span><MdGroupAdd style={{color:"#CE7777"}}/></span>WebSite Subscription Orders</Link>/
              <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><MdGroupAdd style={{color:"#CE7777"}}/></span>WebSite Subscription Orders </h3>
          {/* <div>
              <Link to='/subscription/admin/history'>
                  ADD
              </Link>
          </div> */}
      </div>

      <MUIDataTable
          className="table-responsive"
          data={webOrderList?webOrderList:[]}
          columns={columns}
          options={options}
      />
    </div>
  )
}

export default WebOrders