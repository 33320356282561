import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import { FaBlogger } from 'react-icons/fa'
import Toggle from 'react-toggle'
import {getWebBlog ,editBlog} from "../../redux/MainActions"

const BlogList = () => {
    const dispatch = useDispatch()
    const userBlog = useSelector(state=> state?.HitFitAdmin?.blogs)


    const [ Blog , setBlog ] = useState([])

    useEffect(()=>{
        getRoleList()
    },[])

    const getRoleList = () =>{
        var data = {
            // "status":true
        }
        dispatch(getWebBlog(data))
    }

    useEffect(()=>{
      if(userBlog){
        setBlog(userBlog)
      }
    },[userBlog])

    const onStatusChange = (isActive, ID)=> {
      console.log("error", ID);
      var data= {
        "id": ID,
        "isActive": !isActive
      }
      dispatch(editBlog(data))
    }

    const columns = [
      {
        name:"imageEn",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: ()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Image En</span>
          },
          customBodyRender:(imageEn)=>{
            return<div className='tableIMG'>
              <img style={{height:"80px"}} src={imageEn?.url}  alt='No Image here!'/>
            </div>
          }
        }
      },
      {
        name:"imageAr",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: ()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Image Ar</span>
          },
          customBodyRender:(imageAr)=>{
            return<div className='tableIMG'>
              <img style={{height:"80px"}} src={imageAr?.url}  alt='No Image here!'/>
            </div>
          }
        }
      },
        {
          name: "titleEn",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Title (En)</span>
            }
          },
        },
        {
          name: "titleAr",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Title (Ar)</span>
            }
          },
        },
        {
          name: "isActive",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Status</span>
              },
              customBodyRender:(isActive , tableMeta)=>{
                var ID = tableMeta?.rowData[5]
                return(
                    <div>
                    <Toggle
                        checked={isActive}
                        onChange={()=>onStatusChange(isActive, ID)}
                         />
                    </div>
                )
            }
            },
          },
          {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Action</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div>
                      <Link className='editBTN' to={'/blog/add/'+ id}>
                        <button>Edit</button>
                      </Link>
                    </div>
                )
            }
            },
          },
    ];
    const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };
  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
      <span>
          <Link to='/health'><span><FaBlogger style={{color:"#CE7777"}}/></span>Blog</Link>/
          <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
      </span>
    </div>
  
  <div className="Header">
        <h3><span className="material-icons-outlined"><FaBlogger style={{color:"#CE7777"}}/></span>Blog</h3>
      <div>
          <Link to='/blog/add'>
              ADD
          </Link>
      </div>
  </div>

  <MUIDataTable
      className="table-responsive"
      data={Blog?Blog:[]}
      columns={columns}
      options={options}
  />
</div>
  )
}

export default BlogList