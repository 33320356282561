import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { MdPolicy } from "react-icons/md";
import { Link } from 'react-router-dom'
import { addPrivacyPolicy, getPrivacypolicy } from '../../redux/MainActions';

const PrivacyPolicyAddEdit = () => {
    const dispatch = useDispatch()
    const privacytData = useSelector(state=> state?.HitFitAdmin?.PrivacyPolicy)

    const [privacyPolicyEn , setPrivacyPolicy ] = useState({
        privacyPolicyEn:null
    })
    const [privacyPolicyAr , setPrivacyPolicyAr ] = useState({
        privacyPolicyAr:null
    })

    const handleChangeEn = (value) => {
         setPrivacyPolicy({privacyPolicyEn:value});
    };

    const handleChangeAr = (value) => {
         setPrivacyPolicyAr({privacyPolicyAr:value});
    };

    useEffect(()=>{
        getPrivacyList()
    },[])

    const getPrivacyList = () => {
        var data ={
        }
        dispatch(getPrivacypolicy(data))
    }

    useEffect(()=>{
        if(privacytData){
            var spr = privacytData;
            setPrivacyPolicy({
                privacyPolicyEn: spr?.privacyPolicies?.privacyPolicyEn
            })
            setPrivacyPolicyAr({
                privacyPolicyAr: spr?.privacyPolicies?.privacyPolicyAr
            })
        }
    },[privacytData])

    const onSubmit =(e) => {
        e.preventDefault()
        var data = {
          privacyPolicies: {
              privacyPolicyEn:  privacyPolicyEn.privacyPolicyEn,
              privacyPolicyAr:  privacyPolicyAr.privacyPolicyAr
            }
        }
        dispatch(addPrivacyPolicy(data))
        console.log("data",data);
    }
 
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}><MdPolicy/></span>Privacy Policy</Link>/
              <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}><MdPolicy/></span>Privacy Policy</h3>
          <div>
              <Link to='/live-session/create'>
                  Back
              </Link>
          </div>
      </div>

      <div className="Editoe_form">
              <form onSubmit={onSubmit}>
                    <div className='terms'>
                      <label htmlFor="terms">Privacy Policy (En)</label>
                      <div className='terms_data'  >
                        <ReactQuill
                          name = "tandc"
                          value={privacyPolicyEn.privacyPolicyEn}
                          onChange={handleChangeEn}
                          className='quill'
                        />
                      </div>
                    </div>
              </form>
             
              <hr style={{width:"96%" , border: "2px solid #DDDDDD ", borderRadius: "5px", marginLeft:"2%", marginRight:"2%", marginTop:"30px"}}/>
             
              <form onSubmit={onSubmit}>
                    <div className='terms'>
                      <label htmlFor="terms">Privacy Policy (Ar)</label>
                      <div className='terms_data'  >
                        <ReactQuill
                          name = "tandc"
                          value={privacyPolicyAr.privacyPolicyAr}
                          onChange={handleChangeAr}
                          className='quill'
                        />
                      </div>
                    </div>
                    <div className="btn">
                        <button type='submit'><span className="material-icons-outlined">save</span>Save</button>
                        <Link to='/nutrition/category'>Cancel</Link>
                    </div>
              </form>
      </div>
    </div>
  )
}

export default PrivacyPolicyAddEdit