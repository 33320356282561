import "./App.css";
import Dashbord from "./common/Dashboard";
import Login from "./common/Login";
import "./styles/styles.sass";
function App() {
  return (
    <div className="App">
    {/* <Dashbord/> */}
      {localStorage.getItem("AuthToken") !== undefined &&
      localStorage.getItem("isloggedIn") === "true" ? (
        <Dashbord />
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
