/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MdDeleteOutline } from "react-icons/md";
import { useSelector, useDispatch } from 'react-redux'
import { getNutritionCategoryList, UploadMedia , addNutritionDietplan, getNutritionDietPlan , editNutritionDietPlan,getLanguage } from '../../redux/MainActions'
import swal from 'sweetalert';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const NutritionDietPlanAddEdit = () => {
  const param = useParams()
  console.log('param', param);

  const dispatch =useDispatch()

  const NutritionCategory = useSelector(state=>state?.HitFitAdmin?.NutritionCategory)
  const ImageData = useSelector(state=>state?.HitFitAdmin?.ImageData)
  // const Language = useSelector(state=>state?.HitFitAdmin?.Langauge)
  const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)

  const nutritionDietPlan = useSelector(state=>state.HitFitAdmin.NutirtionDietPlan) 

  console.log("nutritionDietPlan" , nutritionDietPlan);

  const [ dietDetailEn , setDietDetalEn ] = useState([{ title:'' , description:'' }])
  const [ dietDetailAr , setDietDetalAr ] = useState([{ title:'' , description:'' }])

  const [ CateOptions , setCateOptions ] = useState([])
  const [ CateValue , setCateValue ] = useState([])

  console.log("CateValue",CateValue);

  const [imgURL, setImgURL] = useState('')
  const [imgID, setImgID] = useState('')

  const [imgURLAr, setImgURLAr] = useState('')
  const [imgIDAr, setImgIDAr] = useState('')

  const [bannerURL, setBannerURL] = useState('')
  const [bannerID, setBannerID] = useState('')
  
  const [bannerURLAr, setBannerURLAr] = useState('')
  const [bannerIDAr, setBannerIDAr] = useState('')

  const [mediaName, setMediaName] = useState('')

  const [dietData, setDietData] = useState({
    titleEn: "",
    titleAr: "",
    isActive: "true",
    descriptionEn: ""  ,
    descriptionAr: ""  ,
    priority: '',
    categoryId: null,
    type: "standard"
  })

  const onHandleChange =(e)=>{
    const {name, value} =e.target
    setDietData({...dietData,[name]:value})
    if(e.target.name === 'categoryId'){
      setDietData({...dietData,[name]:parseInt(value)})
    }
  }
  
  useEffect(()=>{
    getLanguageData()
  },[])

    const getLanguageData = () => {
        dispatch(getLanguage())
    }

  useEffect(()=>{
    if(NutritionCategory?.length>0){
      var data = NutritionCategory?.map(opt=>{
        return {
          label: opt.titleEn,
          value:opt.id
        }
      })
      setCateOptions(data)
    }
  },[NutritionCategory])
  
  const  onCateChange = (value) => {
    console.log("VALUE", value);
    setCateValue(value)
  }

  const addDietDetailEn = () =>{
    setDietDetalEn([...dietDetailEn, { title:'' , description:'' }])
  }
  const addDietDetailAr = () =>{
    setDietDetalAr([...dietDetailAr, { title:'' , description:'' }])
  }

  useEffect(() => {
    if(param.dietplanId){
      getDietplan(param.dietplanId)
    }
  },[param.dietplanId])

  useEffect(() => {
   if(LanguageID){
    getCetegory(LanguageID)
   }
 }, [])

 useEffect(()=>{
  if(nutritionDietPlan!==undefined && param.dietplanId){
    var spr = nutritionDietPlan.filter(data=>data.id==param.dietplanId)
    var spr2 = NutritionCategory.filter(cate=> cate.id==spr[0]?.categoryId)
    console.log("spr2",spr);
    setDietData({
      titleEn: spr[0]?.titleEn,
      titleAr: spr[0]?.titleAr,
      isActive:spr[0]?.isActive?"true":"false",
      // categoryId: spr[0]?.categoryId,
      priority: spr[0]?.priority,
      descriptionEn: spr[0]?.descriptionEn,
      descriptionAr: spr[0]?.descriptionAr,
      type: spr[0]?.type
    })
    setDietDetalEn(spr[0]?.dietDetailsEn?.data)
    setDietDetalAr(spr[0]?.dietDetailsAr!==null? spr[0]?.dietDetailsAr?.data: [])
    setBannerID( spr[0]?.BannerEnId)
    setBannerIDAr( spr[0]?.BannerArId)
    setBannerURL(spr[0]?.BannerImageEn?.url)
    setBannerURLAr(spr[0]?.BannerImageAr?.url)
    setImgID(spr[0]?.ImageEnId)
    setImgIDAr(spr[0]?.ImageArId)
    setImgURL(spr[0]?.ImageEn?.url)
    setImgURLAr(spr[0]?.ImageAr?.url)
    setCateValue({label:spr2[0]?.titleEn,value:spr2[0]?.id})
    
  }
},[nutritionDietPlan])

const getDietplan = (id) =>{
  var data = {
    "dietplanId":id,
  }
  dispatch(getNutritionDietPlan(data))
 } 

 const getCetegory = (id)=>{
   var data = {
       "offset":0,
      //  "languageId":id,
      //  "limit":20,
       // "categoryId":2,
       // "categoryName":"Faltloss",
      //  "isActive":true
   }
   dispatch(getNutritionCategoryList(data))
 }

 console.log("id" , param.dietplanId);

  const onChangeAddEn = (e, index) => {
    const { name, value } = e.target;
    const list = [...dietDetailEn];
    list[index][name] = value;
    setDietDetalEn(list);
  }
  const onChangeAddAr = (e, index) => {
    const { name, value } = e.target;
    const list = [...dietDetailAr];
    list[index][name] = value;
    setDietDetalAr(list);
  }

  const removeDietDEn = (index) => {
    const filterDiet = [...dietDetailEn];
    filterDiet.splice(index, 1);
    setDietDetalEn(filterDiet);
  }
  const removeDietDAr = (index) => {
    const filterDiet = [...dietDetailAr];
    filterDiet.splice(index, 1);
    setDietDetalAr(filterDiet);
  }

  useEffect(() => {
    if(ImageData!==null){
      if(mediaName==='Image'){
        setImgID(ImageData?.id)
      }
      if(mediaName==='imageAr'){
        setImgIDAr(ImageData?.id)
      }
      if(mediaName==='Banner'){
        setBannerID(ImageData?.id)
      }
      if(mediaName==='BannerAr'){
        setBannerIDAr(ImageData?.id)
      }
    }
  }, [ImageData, mediaName])


  const onImageChange = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setImgURL(url)

    const formData = new FormData()

    formData.append('medias',file)


    dispatch(UploadMedia(formData))
  }

  const onImageChangeAr = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setImgURLAr(url)

    const formData = new FormData()

    formData.append('medias',file)


    dispatch(UploadMedia(formData))
  }

  const onBannerChange1 = (e) =>{
    console.log("BB",e);
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setBannerURL(url)

    const formData = new FormData()

    formData.append('medias',file)

    dispatch(UploadMedia(formData))
  }

  const onBannerChangeAr = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setBannerURLAr(url)

    const formData = new FormData()

    formData.append('medias',file)

    dispatch(UploadMedia(formData))
  }

 
 const onSubmit = (e) => {
  e.preventDefault()

  if(dietData.titleEn===''){
    swal({
      title: 'Error',
      text: "Please Add TitleEn!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(dietData.titleAr==='') {
    swal({
      title: 'Error',
      text: "Please Add TitleAr!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(dietData.categoryId===''){
    swal({
      title: 'Error',
      text: "Please Add Category!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if( dietData.descriptionEn===''){
    swal({
      title: 'Error',
      text: "Please Add DescriptionEn!",
      icon: "warning",
      button: "Ok",
    });
    return false
  } else if( dietData.descriptionAr===''){
    swal({
      title: 'Error',
      text: "Please Add DescriptionAr",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(dietData.isActive===''){
    swal({
      title: 'Error',
      text: "Please select status!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if (imgURL===''){
    swal({
      title: 'Error!',
      text: "Please select Image!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if (bannerURL===''){
    swal({
      title: 'Error!',
      text: "Please select Banner!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }

  if(param?.dietplanId){
      var data = {
        dietPlanId: parseInt(param?.dietplanId),
        titleEn: dietData.titleEn,
        titleAr: dietData.titleAr,
        isActive:dietData.isActive=='true'?true:false,
        categoryId: CateValue.value,
        BannerEnId:bannerID,
        BannerArId:bannerIDAr,
        languageId:LanguageID,
        ImageEnId:imgID,
        ImageArId:imgIDAr,
        descriptionEn: dietData.descriptionEn,
        descriptionAr: dietData.descriptionAr,
        priority: parseInt(dietData.priority),
        dietDetailsEn:{ data : dietDetailEn},
        dietDetailsAr:{ data : dietDetailAr},
        type: dietData?.type
      }    
      dispatch(editNutritionDietPlan(data))
      console.log("data" , data);
  }else{
    var data = {
      titleEn: dietData.titleEn,
      titleAr: dietData.titleAr,
      isActive:dietData.isActive == 'true'?true:false,
      categoryId:CateValue.value,
      BannerEnId:bannerID,
      BannerArId:bannerIDAr,
      languageId: LanguageID,
      ImageEnId:imgID,
      ImageArId:imgIDAr,
      descriptionEn: dietData.descriptionEn,
      descriptionAr: dietData.descriptionAr,
      priority: parseInt(dietData.priority),
      dietDetailsEn:{ data : dietDetailEn},
      dietDetailsAr:{ data : dietDetailAr},
      type: dietData?.type
    }
    dispatch(addNutritionDietplan(data))
    console.log("data" , data);
  }
}
  
 
  


console.log('imgIDAr',imgIDAr);
console.log('bannerID',bannerID);
  return (
     <div className='MainContainer'>
         <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>note_alt</span>Nutrition Diet Plan</Link>/
                <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>note_alt</span>Nutrition Diet plan {param?.dietplanId?'Edit':"Add"}</h3>
            <div>
                <Link to='/nutrition/dietplan'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
          <form onSubmit={onSubmit}>
            <div className="InputF">
              <div className="inputField_type_one">
                <label htmlFor="titleData">Title (En)</label>
                <input id='titleData' value={dietData.titleEn} onChange={onHandleChange} type="text" name='titleEn' placeholder='e.g: High protien Diet' />
                
                <label>Type</label>
                <select name="type" value={dietData.type} onChange={onHandleChange}>
                  <option value='standard'>Standard</option>
                  <option value='premium'>Premium</option>
                </select>

                <label htmlFor="description">Description (En)</label>
                <textarea value={dietData.descriptionEn} onChange={onHandleChange} id="description" type="text" name='descriptionEn' placeholder='e.g: Ketogenic diet is very low carb high-fat diet' />

                <label>Category</label>
                {/* <select name="categoryId" value={dietData.categoryId} onChange={onHandleChange}>
                  <option>- Select Category -</option>
                  {NutritionCategory.length>0?NutritionCategory.map((cate,index)=>(
                    <option value={cate.id} key={index}>{cate.titleEn}</option>
                  )):null}
                </select>  */}

                <Select
                  className='selctField'
                  onChange={onCateChange}
                  options={CateOptions}
                  value={CateValue}
                />
              </div>

              <div className="isActive">
                <label htmlFor="titleData1">Title(Ar)</label>
                <input id='titleData1' value={dietData.titleAr} onChange={onHandleChange} type="text" name='titleAr' placeholder='e.g: High protien Diet' />
                
                <label>Status</label>
                <select name="isActive" value={dietData.isActive} onChange={onHandleChange}>
                  <option>-Select status-</option>
                  <option value='true'>Active</option>
                  <option value='false'>In-Active</option>
                </select>

                <label htmlFor="description1">Description (Ar)</label>
                <textarea value={dietData.descriptionAr} onChange={onHandleChange}  id="description1" type="text" name='descriptionAr' placeholder='e.g: Ketogenic diet is very low carb high-fat diet' />
                
                <label htmlFor="priority">Priority</label>
                <input id='priority' value={dietData.priority} onChange={onHandleChange} type="number" name='priority' placeholder='e.g: 1,2.3' />
              </div>
            </div>

            <div className='MediaUploads'>
              <div className="imgUpload">
                  <div className='imgContainer'>
                      <img src={imgURL} alt=""   />
                      {!imgURL&&<p>Please Select Image!</p>}
                  </div>
                  <label htmlFor="imgButton">Image En</label>
                  <input onChange={onImageChange} type="file" name="Image" id="imgButton"  accept="image/png, image/gif, image/jpeg"/>
              </div>

              <div className="imgUpload">
                  <div className='imgContainer'>
                      <img src={imgURLAr} alt=""   />
                      {!imgURLAr&&<p>Please Select Image Ar!</p>}
                  </div>
                  <label htmlFor="imButton">Image Ar</label>
                  <input onChange={onImageChangeAr} type="file" name="imageAr" id="imButton"  accept="image/png, image/gif, image/jpeg"/>
              </div>
            </div>

            <div className='MediaUploads'>
              <div className="imgUpload">
                  <div className='imgContainer'>
                      <img src={bannerURL} alt=""   />
                      {!bannerURL&&<p>Please Select Banner En!</p>}
                  </div>
                  <label htmlFor="BButton">Banner En</label>
                  <input onChange={onBannerChange1} type="file" name="Banner" id="BButton"  accept="image/png, image/gif, image/jpeg"/>
              </div>

              <div className="imgUpload">
                  <div className='imgContainer'>
                      <img src={bannerURLAr} alt=""   />
                      {!bannerURLAr&&<p>Please Select Banner Ar!</p>}
                  </div>
                  <label htmlFor="bannerButton">Banner Ar</label>
                  <input onChange={onBannerChangeAr} type="file" name="BannerAr" id="bannerButton"  accept="image/png, image/gif, image/jpeg"/>
              </div>
            </div>

            <div className="subInputF">
              <span>Diet Details (En)</span>
              {dietDetailEn?.map((diet , index )=>(
                  <div className="DietInPutF" key={index}>
                  <div className="dietType">
                    <div className="inputField_type_one">
                          <label htmlFor='title'>Title</label>
                          <input type="text" name='title' id='title' placeholder='e.g: Protien Diet' onChange={(e)=> onChangeAddEn( e, index )} value={diet.title} />
                      </div>

                      <div className="mealTitles2">
                        <label id='description'>Description</label>
                        <textarea type="text" name='description' cols="30" rows="3" id='description' onChange={(e)=> onChangeAddEn( e, index )} value={diet.description} placeholder='e.g: Eating a protein-rich diet can help people lose weight because it can help them avoid overeating  '/>
                    </div>
                  </div>
                    {dietDetailEn.length>1&&<MdDeleteOutline className='delete' onClick={()=> removeDietDEn(index)} />}
                  </div>
              ))}
              <p  onClick={ addDietDetailEn } >Add More</p>
            </div>
            <hr style={{width:"45vw" , border: "2px solid #DDDDDD ", borderRadius: "5px"}}/>
            <div className="subInputF">
              <span>Diet Details (Ar) </span>
              {dietDetailAr?.map((diet , index )=>(
                  <div className="DietInPutF" key={index}>
                  <div className="dietType">
                    <div className="inputField_type_one">
                          <label htmlFor='title'>Title</label>
                          <input type="text" name='title' id='title' placeholder='e.g: Protien Diet' onChange={(e)=> onChangeAddAr( e, index )} value={diet.title} />
                      </div>

                      <div className="mealTitles2">
                        <label id='description'>Description</label>
                        <textarea type="text" name='description' cols="30" rows="3" id='description' onChange={(e)=> onChangeAddAr( e, index )} value={diet.description} placeholder='e.g: Eating a protein-rich diet can help people lose weight because it can help them avoid overeating  '/>
                    </div>
                  </div>
                    {dietDetailAr.length>1&&<MdDeleteOutline className='delete' onClick={()=> removeDietDAr(index)} />}
                  </div>
              ))}
              <p  onClick={ addDietDetailAr } >Add More</p>
            </div>

            <div className="btn">
                <button type='submit'><span className="material-icons-outlined">save</span>{param.dietplanId? "Update": "Save"}</button>
                <Link to='/nutrition/dietplan'>Cancel</Link>
            </div>
          </form>
        </div>
    </div>
  )
}

export default NutritionDietPlanAddEdit