import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { BiPackage } from "react-icons/bi";
import { editAds ,getAds } from "../../redux/MainActions";
import ReactQuill from 'react-quill';

const WebSiteAdsAdd = () => {
    const param = useParams()
    const dispatch = useDispatch()
    const userAds = useSelector(state=> state?.HitFitAdmin?.ads)

    const [ adsData , setAdsData ] = useState({
        text:""
    })

    const [ textEn , setTextEn ] = useState(null)
    const [ textAr , setTextAr ] = useState(null)

    const onChangeHandle = (e) => {
        const { value , name } = e.target
        setAdsData({...adsData,[name]: value})
    }
    useEffect(()=>{
        getRoleList()
    },[])
 
    const getRoleList = () =>{
        var data = {
            // "status":true
        }
        dispatch(getAds(data))
    }
    const handleChangeEn = (value) => {
        setTextEn(value)
    };
    const handleChangeAr = (value) => {
        setTextAr(value)
    };

    useEffect(()=>{
        if(userAds!==undefined && param.id){
            var spr = userAds?.filter(data=>data?.id==param?.id)
            // setAdsData({
            //     text:spr[0]?.textEn
            // })
            setTextEn(spr[0]?.textEn)
            setTextAr(spr[0]?.textAr)
        }
    },[userAds])
    const submitHandle= (e)=>{
        e.preventDefault()
        if(param.id){
            var data = {
                // id: param.id,
                textEn:textEn,
                textAr:textAr
            }
            dispatch(editAds(data))
        }else{
            var data = {
                textEn:textEn,
                textAr:textAr
            }
            dispatch(editAds(data))
        }
    }
  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
        <span>
            <Link to='/health'><span><BiPackage style={{color:"#CE7777"}}/></span>WebSite Ads </Link>/
            <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
        </span>
     </div>
    
    <div className="Header">
         <h3><span className="material-icons-outlined"><BiPackage style={{color:"#CE7777"}}/></span>{"param".id?"Edit":"Add"} WebSite Ads</h3>
        <div>
            <Link to='/package'>
                Back
            </Link>
        </div>
    </div>

    <div className="Box_Container">
        <form onSubmit={submitHandle} >
            <div className="InputF">
                <div className="isActive">
                <label htmlFor='text'>Text En</label>
                    <ReactQuill
                          name = "textEn"
                          value={textEn}
                          onChange={handleChangeEn}
                          className='mealDesc'
                    />

                    <label htmlFor='isActive' style={{marginTop:'3rem'}}>Status</label>
                    <select name="isActive" id='isActive' value={"packageData".isActive} onChange={"onChangeHandle"} >
                        <option value='true'>Active</option>
                        <option value='false'>InActive</option>
                    </select>
                </div>
                <div className="isActive">
                <label htmlFor='text'>Text En</label>
                    <ReactQuill
                          name = "textAr"
                          value={textAr}
                          onChange={handleChangeAr}
                          className='mealDesc'
                    />
                </div>
            </div>
            <div className="btn">
                <button type='submit'><span className="material-icons-outlined">save</span>{"param".id? "Update": "Save"}</button>
                <Link to='/website-ads'>Cancel</Link>
            </div>
        </form>
    </div>
</div>
  )
}

export default WebSiteAdsAdd