import React, { useEffect, useState } from 'react'
import { Link, useParams, useLocation,useNavigate } from 'react-router-dom'
import { HiUserCircle } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { editUser, getUser, getUserRole, UploadMedia,onUserSignup } from '../../redux/MainActions'
import swal from 'sweetalert'

const UserEdit = () => {
    const param = useParams()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userRoleList = useSelector(state=> state?.HitFitAdmin?.Role)
    const ImageData = useSelector(state=>state?.HitFitAdmin?.ImageData)
    const SignupData = useSelector(state=>state?.HitFitAdmin?.SignupData)
    const UserList = useSelector(state=>state?.HitFitAdmin?.User)

    const [imgURL, setImgURL] = useState("")
    const [imgID, setImgID] = useState('')
    const [mediaName, setMediaName] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [ userData , setUserData ] = useState({
        userName: "",
        fullName:"",
        email: "",
        roleId: null,
        password: "",
        confirmPassword: "",
        phone: "",
        gender: "male"  
    })


    const onHandleChange =(e)=>{
        const {name, value} =e.target
        setUserData({...userData,[name]:value})
        if(e.target.name === 'roleId'){
            setUserData({...userData,[name]:parseInt(value)})
        }
    }


    useEffect(()=>{
        if(param.id){
            findUser(param.id)
        }
        const currentPath = pathname?.split('/')?.[2]
        if(currentPath !== "add"){
            setIsEdit(true)
        } else setIsEdit(false)
    },[])

    const findUser = (id) =>{
        var data = {
            "id":id,
        }
        dispatch(getUser(data))
    }

    useEffect(()=>{
        getRoleList()
    },[])

    const getRoleList = () =>{
        var data = {
            "status":true
        }
        dispatch(getUserRole(data))
    }

    useEffect(()=>{
        if(UserList&&param.id){
            var spr = UserList.filter(data=>data.id==param.id)
            console.log("spr",spr);

            setUserData({
                userName: spr[0]?.userName,
                fullName: spr[0]?.fullName,
                email: spr[0]?.email,
                roleId: spr[0]?.roleId,
                phone: parseInt(spr[0]?.phone),
                gender: spr[0]?.gender  
            })
            setImgURL(spr[0]?.imageUrl?spr[0]?.imageUrl:'')
        }
    },[UserList])

    
    useEffect(() => {
        if(ImageData!==null){
        if(mediaName==='Image'){
            setImgID(ImageData?.id)
        }
        }
    }, [ImageData, mediaName])
    
    const onImageChange = (e) =>{
        setMediaName(e.target.name)
        var file = e.target.files[0]
        var url = URL.createObjectURL(e.target.files[0]) 
        setImgURL(url)

        const formData = new FormData()

        formData.append('medias',file)


        dispatch(UploadMedia(formData))
    }

    console.log("param",param, param.id, pathname?.split('/'),isEdit, SignupData);

    useEffect(() => {
        if(SignupData && Object.keys(SignupData)?.length>0){
            if(SignupData?.status){
                 swal({
                    title: "Success",
                    text: "User created successfully",
                    icon: "success",
                    button: "ok",
                    });
                    navigate('/users')
            } else {
                swal({
                    title: "Error",
                    text: "Unable to create user",
                    icon: "error",
                    button: "cancel",
                    });
            }
        }
    },[SignupData])

    const onSubmit = (e) => {
    e.preventDefault()
    
    // if( imgURL=== ''){
    //     swal({
    //       title: 'Error',
    //       text: "Please Add Image !",
    //       icon: "warning",
    //       button: "Ok",
    //     });
    //     return false
    // }

    if(param.id && isEdit){
      if(userData.password === userData.confirmPassword){
        var data = {
        userId: parseInt(param.id) ,
        userName: userData.userName,
        fullName: userData.fullName,
        email: userData.email,
        roleId: userData.roleId,
        password: userData.password,
        confirmPassword: userData.confirmPassword,
        phone: userData.phone,
        gender: userData.gender,
        imageUrl: imgURL 
      }    
      dispatch(editUser(data))
        console.log("data" , data);
      }
    } else {
        const payload = {
            userName: userData.userName,
            fullName: userData.fullName,
            email: userData.email,
            roleId: userData.roleId,
            password: userData.password,
            confirmPassword: userData.confirmPassword,
            phone: userData.phone,
            gender: userData.gender,
            imageUrl: imgURL 
        }
        dispatch(onUserSignup(payload))
    }
}


  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><HiUserCircle style={{color:"#CE7777"}}/></span>{isEdit ? 'Edit' : 'Add'} User Profile</Link>/
              <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><HiUserCircle style={{color:"#CE7777"}}/></span>{isEdit ? 'Edit' : 'Add'} User Profile</h3>
            <div>
                <Link to='/users'>
                    Back
                </Link>
            </div>
      </div>

      <div className="Box_Container">
            <form onSubmit={onSubmit} >
                <div className="InputF">
                    <div className="inputField_type_one">
                        <label htmlFor='userName'>User Name</label>
                        <input type="text" id='userName' name='userName' placeholder='E.g: Trainer 1' value={userData.userName} onChange={onHandleChange} />
                        
                        <label htmlFor="email">E-mail</label>
                        <input type="email" id='email' name='email' placeholder='E.g: trainer1@gmail.com' value={userData.email} onChange={onHandleChange} />
                        
                        <label htmlFor="phone">Phone No</label>
                        <input type="number" id='phone' name='phone' placeholder='E.g: 9154875444' value={userData.phone} onChange={onHandleChange} />
                        
                        <label htmlFor='gender'>Gender</label>
                        <select name="gender" id='gender' value={userData.gender} onChange={onHandleChange} >
                            <option value=''>- Select Gender -</option>
                            <option value='male'>Male</option>
                            <option value='female'>Female</option>
                            <option value='other'>Other</option>
                        </select>
                    </div>

                    <div className="inputField_type_one">
                        <label htmlFor='fullName'>FullName</label>
                        <input type="text" id='fullName' name='fullName' placeholder='E.g: Md Awaiz' value={userData.fullName} onChange={onHandleChange} />
                        
                        <label htmlFor="password">Password</label>
                        <input type="password" id='password' name='password' placeholder='E.g: xyz0012' value={userData.password} onChange={onHandleChange} />
                        
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input type="password" id='confirmPassword' name='confirmPassword' placeholder='E.g: xyz0012' value={userData.confirmPassword} onChange={onHandleChange} />

                        <label>Role</label>
                        <select name="roleId" value={userData.roleId} onChange={onHandleChange} >
                        <option>- Select Package -</option>
                            {userRoleList?.length>0?userRoleList?.map((cate,index)=>(
                                <option value={cate.id} key={index}>{cate.type}</option>
                            )):null}
                        </select>
                    </div>
                </div>

                    <div className='MediaUploads'>
                        <div className="imgUpload">
                            <div className='imgContainer'>
                                <img src={imgURL} alt=""   />
                                {!imgURL&&<p>Please Select Image!</p>}
                            </div>
                            <label htmlFor="imgButton">Select Image</label>
                            <input onChange={onImageChange} type="file" name="Image" id="imgButton"  accept="image/png, image/gif, image/jpeg"/>
                        </div>

                    </div>
                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span>Save</button>
                    <Link to='/users'>Cancel</Link>
                </div>
            </form>
        </div>
 
    </div>
  )
}

export default UserEdit