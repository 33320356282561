/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { FaBlogger } from 'react-icons/fa'
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {addBlog , UploadMedia, getWebBlog , editBlog} from '../../redux/MainActions'
import swal from 'sweetalert';

const BlogAddEdit = () => {
    const param = useParams()
    const dispatch = useDispatch()
    const ImageData = useSelector(state=>state?.HitFitAdmin?.ImageData)
    const userBlog = useSelector(state=> state?.HitFitAdmin?.blogs)

    const [blogData , setBlogData ] = useState({
        titleEn:"",
        titleAr:"",
        descriptionEn:"",
        descriptionAr:"",
        priority:"",
        isActive:"true",
    })
    useEffect(()=>{
        getRoleList()
    },[])

    const getRoleList = () =>{
        var data = {
            // "status":true
        }
        dispatch(getWebBlog(data))
    }

    useEffect(()=>{
        if(userBlog!==undefined && param.id){
            var spr = userBlog?.filter(data=>data?.id==param?.id)
            console.log("spr2",spr);

            setBlogData({
                titleEn:spr[0]?.titleEn,
                titleAr:spr[0]?.titleAr,
                priority:spr[0]?.priority,
                isActive:spr[0]?.isActive,
            })
            setImgURL(spr[0]?.imageEn?.url)
            setImgID(spr[0]?.ImageEnId)
            setImgURLAr(spr[0]?.imageAr?.url)
            setImgIDAr(spr[0]?.ImageArId)
            setDescEn(spr[0]?.descriptionEn)
            setDescAr(spr[0]?.descriptionAr)
            setVideoID(spr[0]?.VideoEnId)
            setVideoURL(spr[0]?.videoEn?.url)
        }
    },[userBlog])

    const [imgURL, setImgURL] = useState('')
    const [imgID, setImgID] = useState(null)

    const [imgURLAr, setImgURLAr] = useState('')
    const [imgIDAr, setImgIDAr] = useState(null)

    const [videoURL, setVideoURL] = useState('')
    const [videoID, setVideoID] = useState(null)

    const [mediaName, setMediaName] = useState('')

    const [ descEn , setDescEn ] = useState(null)
    const [ descAr , setDescAr ] = useState(null)

    useEffect(() => {
        if(ImageData!==null){
          if(mediaName==='Image'){
            setImgID(ImageData?.id)
          }
          if(mediaName==='imageAr'){
            setImgIDAr(ImageData?.id)
          }
          if(mediaName==='Video'){
            setVideoID(ImageData?.id)
          }
        }
      }, [ImageData, mediaName])

  const onImageChange = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setImgURL(url)

    const formData = new FormData()

    formData.append('medias',file)


    dispatch(UploadMedia(formData))
  }
  const onImageChangeAr = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setImgURLAr(url)

    const formData = new FormData()

    formData.append('medias',file)


    dispatch(UploadMedia(formData))
  }

    const onVideoUrlUpload = (e) =>{
        setMediaName('Video')
        var file = [videoURL]
        // var url = URL.createObjectURL(e.target.files[0]) 
        // setVideoURL(url)

        //  console.log('Videofile',file);

        const formData = new FormData()

        formData.append('videoUrls',JSON.stringify(file))

        dispatch(UploadMedia(formData))
    }

    const handleChangeEn = (value) => {
        setDescEn(value)
    };
    const handleChangeAr = (value) => {
        setDescAr(value)
    };

    const onHandleChange = (e) => {
        const { value , name } = e.target
        setBlogData({ ...blogData,[name]:value }) 
      }
    const onSubmit= (e)=>{
        e.preventDefault()

        if(blogData.titleEn===''){
            swal({
              title: 'Error!',
              text: "Please Add TitleEn !",
              icon: "warning",
              button: "Ok",
            });
            return false
        }else if(blogData.titleAr===''){
            swal({
                title: 'Error!',
                text: "Please Add TitleAr !",
                icon: "warning",
                button: "Ok",
              });
              return false
        }

        if(param.id){
            var data = {
                id:param?.id,
                titleEn:blogData?.titleEn,
                titleAr:blogData?.titleAr,
                descriptionEn:descEn,
                descriptionAr:descAr,
                priority:parseInt(blogData.priority),
                isActive:blogData?.isActive==="true"?true:false,
                ImageEnId:imgID,
                ImageArId:imgIDAr,
                VideoEnId:videoID,
                VideoArId:videoID
            }
            dispatch(editBlog(data))
        }else{
            var data = {
                titleEn:blogData?.titleEn,
                titleAr:blogData?.titleAr,
                descriptionEn:descEn,
                descriptionAr:descAr,
                priority:parseInt(blogData.priority),
                // isActive:blogData.isActive==="true"?true:false,
                ImageEnId:imgID,
                ImageArId:imgIDAr,
                VideoEnId:videoID,
                VideoArId:videoID
            }
            dispatch(addBlog(data))
            console.log("SAVE",data);
        }       
    }
    console.log("VIDEOID", videoID);
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}><FaBlogger/></span>Blog {param?.categoryId?'Edit':'Add'}</Link>/
                <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}><FaBlogger/></span>Blog {param?.categoryId?'Edit':'Add'}</h3>
            <div>
                <Link to='/blog'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
            <form onSubmit={onSubmit}>
                <div className="InputF">
                    <div className="inputField_type_one">
                        <label htmlFor='titleEn'>title (En)</label>
                        <input type="text" name='titleEn' placeholder='ex: fasting Diet...' value={blogData.titleEn} onChange={onHandleChange} />

                        <label htmlFor='priority'>Priority</label>
                        <input type="number" name='priority' placeholder='ex: 1,2' value={blogData.priority} onChange={onHandleChange} />

                        <label htmlFor="descriptionEn">Description (En)</label>
                         {/* <textarea value={blogData.descriptionEn} onChange={onHandleChange} id="descriptionEn" type="text" name='descriptionEn' placeholder='e.g: Blog ' /> */}
                         <ReactQuill
                          name = "descriptionEn"
                          value={descEn}
                          onChange={handleChangeEn}
                          className='mealDesc'
                        />
                        
                       
                      
                    </div>
                    <div className="isActive">
                        <label htmlFor='titleAr'>title (Ar)</label>
                        <input type="text" name='titleAr' placeholder='ex: Blog..' value={blogData.titleAr} onChange={onHandleChange} />

                        <label>Status</label>
                        <select name="isActive" value={blogData.isActive} onChange={onHandleChange} >
                            <option value='true'>Active</option>
                            <option value='false'>InActive</option>
                        </select>
                        
                         <label htmlFor="descriptionAr">Description (Ar)</label>
                         {/* <textarea value={blogData.descriptionAr} onChange={onHandleChange} id="descriptionAr" type="text" name='descriptionAr' placeholder='e.g: Blog' /> */}
                         <ReactQuill
                          name = "descriptionAr"
                          value={descAr}
                          onChange={handleChangeAr}
                          className='quill'
                        />
                        
                        

                       
                    </div>
                    
                </div>
                <div className='MediaUploads' style={{marginTop:'2rem'}}>
                    <div className="imgUpload">
                        <div className='imgContainer'>
                            <img src={imgURL} alt=""   />
                            {!imgURL&&<p>Please Select Image!</p>}
                        </div>
                        <label htmlFor="imgButton">Select Image</label>
                        <input onChange={onImageChange} type="file" name="Image" id="imgButton"  accept="image/png, image/gif, image/jpeg "/>
                    </div>
                    <div className="imgUpload">
                        <div className='imgContainer'>
                            <img src={imgURLAr} alt=""   />
                            {!imgURLAr&&<p>Please Select ImageAr!</p>}
                        </div>
                        <label htmlFor="imgButtons">Select ImageAr</label>
                        <input onChange={onImageChangeAr} type="file" name="imageAr" id="imgButtons"  accept="image/png, image/gif, image/jpeg"/>
                    </div>
                </div>
                <div className="MediaUploads">
                    <div className="videoURL">
                        <div className="addVideo">
                        <input type="text" placeholder='submit video urls' value={videoURL} onChange={(e)=> setVideoURL(e.target.value)}/>
                        <span name='Video' onClick={onVideoUrlUpload}>Save Url</span>
                        </div>
                    </div>
                </div>
                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span>{param.categoryId? "Update": "Save"}</button>
                    <Link to='/blog'>Cancel</Link>
                </div>
            </form>
        </div>
    </div>
  )
}

export default BlogAddEdit