import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onAdminLogin } from '../redux/MainActions'

const Login = () => {
    const dispatch = useDispatch()
    const UserData = useSelector(state=>state?.HitFitAdmin?.LoginData)

    const [login, setLogin] = useState({
        email:'',
        password:'',
    })

    const onHandleChange = (e) =>{
        const {value , name} = e.target
        setLogin({...login,[name]:value})
    }

    const onSubmit =(e)=>{
        e.preventDefault()
        var data = {
            email:login.email,
            password:login.password,
            loggedInWith:'normal'
        }
        dispatch(onAdminLogin(data))
    }

    console.log('UserData',UserData);


  return (
    <div className='login_container'>
        <form className='Form'>
            <div className="login_head">
                <h1>HIITFIT ADMIN PANEL</h1>
                <span>Sign In</span>
            </div>
            <div className="inputF">
                <label htmlFor="email">E-mail</label>
                <input type="email" name='email' placeholder='Enter Your Email' value={login.email} onChange={onHandleChange} />
            </div>
            <div className="inputF">
                <label htmlFor="password">Password</label>
                <input type="password" name='password' placeholder='Enter Your Password' value={login.password} onChange={onHandleChange} />
            </div>
            <button onClick={onSubmit}>Log!n</button>
        </form>
    </div>
  )
}

export default Login