import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BsFillBriefcaseFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { addUserRole, editUserRole, getUserRole } from '../../redux/MainActions'
import swal from 'sweetalert'

const RoleAddEdit = () => {
    const param = useParams()

    
    const dispatch = useDispatch()
    const userRole = useSelector(state=> state?.HitFitAdmin?.Role)

    console.log("userRole",userRole);

    const [ role , setRole ] = useState({
        type:'',
       status: "true"
    })

    useEffect(()=>{
        getCetegory()
    },[])

    const getCetegory =(id)=>{
        var data = {
            "roleId":id,
        }
        dispatch(getUserRole(data))
    }

    useEffect(()=>{
        if(userRole&&param.roleId){
          var spr = userRole.filter(data=>data.id==param.roleId)
          console.log('spr',spr);
          setRole({
            type: spr[0]?.type,
            status: spr[0]?.status? "true": "false"
          })
        }
    },[userRole])

    const onChangeHandle = (e) => {
        const { value , name } = e.target
        setRole({...role,[name]: value})
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if(role.type===''){
            swal({
              title: 'Please Add Role type!',
              // text: "Please Add Title!",
              icon: "warning",
              button: "Ok",
            });
            return false
          }

        if(param.roleId){
            var data = {
                roleId: parseInt(param.roleId),
                type: role.type,
                status: role.status == "true"? true : false
            }
            dispatch(editUserRole(data))
            console.log("data",data);
        }else{
            var data = {
                type: role.type,
                status: role.status == "true"? true : false
            }
            dispatch(addUserRole(data))
            console.log("data",data);
        }
    }

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
        <span>
            <Link to='/health'><span><BsFillBriefcaseFill style={{color:"#CE7777"}}/></span>Roles</Link>/
            <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
        </span>
        </div>
    
        <div className="Header">
                <h3><span className="material-icons-outlined"><BsFillBriefcaseFill style={{color:"#CE7777"}}/></span>Add Roles</h3>
            <div>
                <Link to='/user/role'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
            <form onSubmit={onSubmit} >
                <div className="InputF">
                    <div className="inputField_type_one">
                        <label htmlFor='type'>Type</label>
                        <input type="text" id='type' name='type' placeholder='E.g: user' value={role.type} onChange={onChangeHandle} />
                    </div>
                    <div className="isActive">
                        <label>Status</label>
                        <select name="status" value={role.status} onChange={onChangeHandle} >
                            <option value='true'>Active</option>
                            <option value='false'>InActive</option>
                        </select>
                    </div>
                </div>
                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span>Save</button>
                    <Link to='/user/role'>Cancel</Link>
                </div>
            </form>
        </div>
 
    </div>
  )
}

export default RoleAddEdit