/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdDeleteOutline } from "react-icons/md";
import { Link, useParams } from 'react-router-dom'
import { UploadMedia ,getPackage, addPackagePrograme , editPackageProgram, getPackageProgram , getPackageProgramWorkout} from '../../redux/MainActions'
import 'video-react/dist/video-react.css';
import Select from 'react-select';
import swal from 'sweetalert';


const ProgramAddEdit = () => {
  const param = useParams()
  const dispatch =useDispatch()

  console.log("param",param);
  
  const PackageID = useSelector(state=> state?.HitFitAdmin?.Package)
  const ImageData = useSelector(state=>state?.HitFitAdmin?.ImageData)
  const PackageProgram = useSelector(state=> state?.HitFitAdmin?.PackageProgram)
  const workoutData = useSelector(state=> state?.HitFitAdmin?.ProgramWorkout)
  const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)

  const [workouts, setWorkouts] = useState([{day:'' , workoutId:''}])

  const [ packageOptions , setPackageOptions ] = useState([])
  const [ packageValue , setPackageValue ] = useState([])
  const [ WorkoutOptions , setWorkoutOptions ] = useState([])
  const [ workoutValue , setWorkoutValue ] = useState([])

  // console.log("WorkoutOptions",WorkoutOptions);

  const [programData, setProgramData] = useState({
    "titleEn": "",
    "titleAr": "",
    "descriptionEn": "",
    "descriptionAr": "",
    "totalDurationEn": "",
    "totalDurationAr": "",
    "perDayDurationEn": "",
    "perDayDurationAr": "",
    "level": "Beginner",
    "isActive": 'true',
    "priority":'',
    type : 'standard'
  })

  const onChangeAdd = ( e, index) => {
    const {name , value } = e.target;
    const list = [...workouts];
    list[index][name] = parseInt(value);
    setWorkouts(list)
  }

  const removeDietD = (index) => {
    const filterDiet = [...workouts];
    filterDiet.splice(index, 1);
    setWorkouts(filterDiet);
  }

  const addWorkoutD = () => {
    setWorkouts([...workouts, {day:'', workoutId:''}])
  }
  
  useEffect(()=> {
    if(PackageID?.length>0) {
      var data = PackageID?.map(opt=> {
        return {
          label:opt.titleEn,
          value:opt.id
        }
      })
      setPackageOptions(data)
    }
  },[PackageID])

  // useEffect(()=> {
  //   if(workoutData?.length>0) {
  //     var data = workoutData?.map(opt=>{
  //       return {
  //         label: opt.titleEn,
  //         value: opt.id
  //       }
  //     })
  //     setWorkoutOptions(data)
  //   }
  // },[workoutData])

  const onPackageChange = (value) => {
    if(value){
      setPackageValue(value)
    }
  }

  // const onWorkoutChange = (value) => {
  //   console.log("VALUE",value);
  //   if(value){
  //     setWorkoutValue(value)
  //   }
  // }

  const onHandleChange =(e)=>{
    const {name, value} =e.target
    setProgramData({...programData,[name]:value})
    if(e.target.name === 'packageId'){
        setProgramData({...programData,[name]:parseInt(value)})
    }
  }

  useEffect(() => {
    if(param.programId){
      program(param.programId)
    }
  },[param.programId])


  const program = (id) =>{
  var data = {
    // "id":id,
    // "isActive":true,
    // "languageId": LanguageID,
    includeWorkouts : true,
    includePackages:true
  }
  dispatch(getPackageProgram(data))
 } 

 useEffect(() => {
  if(LanguageID){
    getWorkout(LanguageID)
  }
 }, [LanguageID])
 

 const getWorkout = (id) => {
  var data = {
      // "isActive":true,
      // languageId: LanguageID,
  }
  dispatch(getPackageProgramWorkout(data))
}


 useEffect(()=>{
  if(PackageProgram.length>0 && param.programId){
    var spr = PackageProgram.filter(data=>data.id==param.programId)
    console.log("spr",spr);
    setProgramData({
        titleEn: spr[0]?.titleEn,
        titleAr:spr[0]?.titleAr,
        descriptionEn: spr[0]?.descriptionEn,
        descriptionAr: spr[0]?.descriptionAr,
        totalDurationEn: spr[0]?.totalDurationEn,
        totalDurationAr: spr[0]?.totalDurationAr,
        perDayDurationEn: spr[0]?.perDayDurationEn,
        perDayDurationAr: spr[0]?.perDayDurationAr,
        level: spr[0]?.level,
        isActive: spr[0]?.isActive? "true" : "false",
        packageId: spr[0]?.PackageId,
        type: spr[0]?.type,
        priority: spr[0]?.priority
    })
    setBannerID(spr[0]?.BannerImageId)
    setBannerURL(spr[0]?.BannerImage?.url)
    setImgID(spr[0]?.MainImageId)
    setImgURL(spr[0]?.MainImage?.url)
    setVideoID(spr[0]?.PreviewVideoId)
    setVideoURL(spr[0]?.PreviewVideo?spr[0]?.PreviewVideo?.url: null)
    setWorkouts(spr[0]?.Workouts?.map((item, idx)=> {
     return { day:item.day , workoutId:item.id} 
    }))
    setPackageValue(spr[0]?.Packages?.map((pack, idx)=> {
      return {  
        label:pack.titleEn,
        value:pack.id }
    }))
    setPdfURL(spr[0]?.ProgramPdfEn?.url)
    setPdfId(spr[0]?.ProgramPdfEn?.id)
    setPdfURLAr(spr[0]?.ProgramPdfAr?.url)
    setPdfIdAr(spr[0]?.ProgramPdfAr?.id)
    // var da = 
    // console.log("Da",da);
  }
},[PackageProgram,param])

// const workoutOptionsGet = (workoutIDS) =>{
//   console.log("workoutIDS",workoutIDS)
// }


 //   console.log("PackageID",PackageID);

  const [imgURL, setImgURL] = useState('')
  const [imgID, setImgID] = useState('')

  const [ pdfURL , setPdfURL ] = useState('')
  const [ pdfId , setPdfId ] = useState('')
 
  const [ pdfURLAr , setPdfURLAr ] = useState('')
  const [ pdfIdAr , setPdfIdAr ] = useState('')

  const [bannerURL, setBannerURL] = useState('')
  const [bannerID, setBannerID] = useState('')

  const [videoURL, setVideoURL] = useState('')
  const [videoID, setVideoID] = useState('')

  const [mediaName, setMediaName] = useState('')

  useEffect(() => {
    if(ImageData!==null){
      if(mediaName==='Image'){
        setImgID(ImageData?.id)
      }else if(mediaName==='Banner'){
        setBannerID(ImageData?.id)
      }else if(mediaName==='Video'){
         setVideoID(ImageData? ImageData.id : null)
      }else if(mediaName==="pdf"){
        setPdfId(ImageData?ImageData.id : null)
      }else if(mediaName==="pdfAr"){
        setPdfIdAr(ImageData?ImageData.id : null)
      }
    }
  }, [ImageData, mediaName])

  useEffect(() => {
       if(LanguageID){
        getPackageId(LanguageID)
       }
    }, [LanguageID])

  const getPackageId = (id) => {
    var data = {
        // "isActive":true,
        // languageId: id

    }
    dispatch(getPackage(data))
  }

  const onImageChange = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setImgURL(url)

    const formData = new FormData()

    formData.append('medias',file)


    dispatch(UploadMedia(formData))
  }

  const onPdfChange = (e) => {
    console.log("D" , e.target.files[0]);
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(file)
    setPdfURL(url)
    setPdfId(url.id)

    const formData = new FormData()
    formData.append("medias" , file)

    dispatch(UploadMedia(formData))
  }
  
  const onPdfChangeAr = (e) => {
    console.log("D" , e.target.files[0]);
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(file)
    setPdfURLAr(url)
    setPdfIdAr(url.id)

    const formData = new FormData()
    formData.append("medias" , file)

    dispatch(UploadMedia(formData))
  }

  const onBannerChange = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setBannerURL(url)

    const formData = new FormData()

    formData.append('medias',file)

    dispatch(UploadMedia(formData))
  }

  const onVideoUrlUpload = (e) =>{
    setMediaName('Video')
    var file = [videoURL]

    const formData = new FormData()

    formData.append('videoUrls',JSON.stringify(file))

    dispatch(UploadMedia(formData))
  }
  
  console.log('pdfId', pdfId);
  console.log('mediaName',mediaName);


  const onSubmit = (e) => {
  e.preventDefault()
  if(programData.titleEn===''){
    swal({
      title: 'Error!',
      text: "Please Add TitleEn!",
      icon: "warning",
      button: "Ok",
    });
    return false
  } else if(programData.titleAr===''){
    swal({
      title: 'Error!',
      text: "Please Add TitleAr!",
      icon: "warning",
      button: "Ok",
    });
    return false
  } else if(programData.packageId===''){
    swal({
      title: 'Error!',
      text: "Please Add Package!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if( programData.descriptionEn===''){
    swal({
      title: 'Error!',
      text: "Please Add Description En!",
      icon: "warning",
      button: "Ok",
    });
    return false
  } else if(programData.descriptionAr===''){
    swal({
      title: 'Error!',
      text: "Please Add Description Ar!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(programData.packageId===''){
    swal({
      title: 'Error!',
      text: "Please select Package!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(programData.level===''){
    swal({
      title: 'Error!',
      text: "Please select level!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(programData.perDayDurationEn===''){
    swal({
      title: 'Error!',
      text: "Please Add PerDayDuration En",
      icon: "warning",
      button: "Ok",
    });
    return false
  } else if(programData.perDayDurationAr===''){
    swal({
      title: 'Error!',
      text: "Please Add PerDayDuration Ar",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(programData.totalDurationEn===''){
    swal({
      title: 'Error!',
      text: "Please Add Total Duration En!",
      icon: "warning",
      button: "Ok",
    });
    return false
  } else if(programData.totalDurationAr===''){
    swal({
      title: 'Error!',
      text: "Please Add Total Duration Ar!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(videoURL===''){
    swal({
      title: 'Error',
      text: "Please Add Vimeo Video URL Link!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(bannerURL===''){
    swal({
      title: 'Error',
      text: "Please Add Banner !",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(imgURL===''){
    swal({
      title: 'Error',
      text: "Please Add Image !",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if ( workouts[0]?.day=== ''){
    swal({
      title: 'Error',
      text: "Please Add Workout Schedule Day !",
      icon: "warning",
      button: "Ok",
    });
    return false
  }


  // var workout = workoutValue.map(opt=>opt.value)
  var packageD = packageValue.map(opt=>opt.value)
  if(param.programId){
      var data = {
        id: parseInt(param.programId),
        titleEn: programData.titleEn,
        titleAr: programData.titleAr,
        isActive:programData.isActive == 'true'?true:false,
        packageIds: packageD,
        descriptionEn: programData.descriptionEn,
        descriptionAr: programData.descriptionAr,
        totalDurationEn: programData.totalDurationEn,
        totalDurationAr: programData.totalDurationAr,
        perDayDurationEn: programData.perDayDurationEn,
        perDayDurationAr: programData.perDayDurationAr,
        level: programData.level,
        mainImageId: imgID,
        workouts:workouts,
        type: programData.type,
        bannerImageId: bannerID,
        previewVideoId: videoID,
        priority: parseInt(programData.priority),
        programPdfIdEn:pdfId,
        programPdfIdAr:pdfIdAr
      }    
      dispatch(editPackageProgram(data))
      console.log("data" , data);
  }else{
    var data = {
      titleEn: programData.titleEn,
      titleAr: programData.titleAr,
      // isActive:programData.isActive == 'true'?true:false,
      packageIds: packageD,
      descriptionEn: programData.descriptionEn,
      descriptionAr: programData.descriptionAr,
      totalDurationEn: programData.totalDurationEn,
      totalDurationAr: programData.totalDurationAr,
      perDayDurationEn: programData.perDayDurationEn,
      perDayDurationAr: programData.perDayDurationAr,
      level: programData.level,
      mainImageId: imgID,
      type: programData.type,
      workouts:workouts,
      bannerImageId: bannerID,
      previewVideoId: videoID,
      priority: parseInt(programData.priority),
      programPdfIdEn:pdfId,
      programPdfIdAr:pdfIdAr
    }
    dispatch(addPackagePrograme(data))
    console.log("data" , data);
  }
}
  


  return (
    <div className='MainContainer'>
         <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>note_alt</span>Package Program</Link>/
                <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>note_alt</span>{param.programId? "Edit": "Add"} Program</h3>
            <div>
                <Link to='/package/program'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
          <form onSubmit={onSubmit}>
            <div className="InputF">
              <div className="inputField_type_one">
                <label htmlFor="titleData">Title (En)</label>
                <input id='titleData'  type="text" name='titleEn' value={programData.titleEn} onChange={onHandleChange} placeholder='e.g: High protien Diet' />
                
                <label htmlFor="perDayDuration">Per-Day Duration (En)</label>
                <input type="text" id='perDayDuration' name='perDayDurationEn'  value={programData.perDayDurationEn} onChange={onHandleChange} placeholder='E.g: Per Day Duration' />
                
                <label htmlFor="totalDuration">Total Duration (En)</label>
                <input type="text" id='totalDuration' name='totalDurationEn'  value={programData.totalDurationEn} onChange={onHandleChange} placeholder='E.g: Total Duration' />
                
                <label htmlFor="description">Description (En)</label>
                <textarea rows={3}  type="text" name='descriptionEn' value={programData.descriptionEn} onChange={onHandleChange} placeholder='e.g: Ketogenic diet is very low carb high-fat diet' />
                
                <label>Type</label>
                <select name="type" value={programData.type} onChange={onHandleChange}>
                  <option value='standard'>Standard</option>
                  <option value='premium'>Premium</option>
                </select>
                
                <label htmlFor="level">Level</label>
                <select name="level" id="level" value={programData.level} onChange={onHandleChange}>
                    <option value="">-Select Level-</option>
                    <option value="Advanced">Advanced</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Beginner">Beginner</option>
                </select>

                <label htmlFor="priority">Priority</label>
                <input id='priority'  type="number" name='priority' value={programData.priority} onChange={onHandleChange} placeholder='e.g: 123  ' />

              </div>

              <div className="isActive">
                <label htmlFor="titleDataAr">Title (Ar)</label>
                <input id='titleDataAr'  type="text" name='titleAr' value={programData.titleAr} onChange={onHandleChange} placeholder='e.g: High protien Diet' />

                <label htmlFor="perDayDurationAr">Per-Day Duration (Ar)</label>
                <input type="text" id='perDayDurationAr' name='perDayDurationAr'  value={programData.perDayDurationAr} onChange={onHandleChange} placeholder='E.g: Per Day Duration' />

                <label htmlFor="totalDurationAr">Total Duration (Ar)</label>
                <input type="text" id='totalDurationAr' name='totalDurationAr'  value={programData.totalDurationAr} onChange={onHandleChange} placeholder='E.g: Total Duration' />
                
                <label htmlFor="descriptionAr">Description (Ar)</label>
                <textarea rows={3}  type="text" name='descriptionAr' value={programData.descriptionAr} onChange={onHandleChange} placeholder='e.g: Ketogenic diet is very low carb high-fat diet' />

                <label>Status</label>
                <select name="isActive" value={programData.isActive} onChange={onHandleChange}>
                  <option>-Select status-</option>
                  <option value='true'>Active</option>
                  <option value='false'>In-Active</option>
                </select>

                <label>Package</label>
                <Select
                  className='selctField'
                  onChange={onPackageChange}
                  options={packageOptions}
                  value={packageValue}
                  isMulti
                />
              </div>
            </div>



            <div className="subInputF">
              <span>Add Workout schedule</span>
              {workouts?.map((diet , index )=>(
                  <div className="DietInPutF" key={index}>
                  <div className="dietType">
                    <div className="inputField_type_one">
                          <label htmlFor='day'>Day</label>
                          <input type="number" name='day' id='day' placeholder='e.g: 1' onChange={(e)=> onChangeAdd( e, index )} value={diet.day} />
                      </div>

                      <div className="isActive">
                        <label id='workoutId'>Workout</label>
                        <select name="workoutId" value={diet.workoutId} onChange={(e)=> onChangeAdd( e, index )}>
                          <option>- Select Category -</option>
                          {workoutData.length>0?workoutData.map((cate,index)=>(
                            <option value={cate.id} key={index}>{cate.titleEn} / {cate.titleAr}</option>
                          )):null}
                        </select>
                        {/* <label>Workout</label>
                        <Select
                          className='select'
                          name='workoutId'
                          onChange={onWorkoutChange}
                          options={WorkoutOptions}
                          value={workoutValue}
                        /> */}
                    </div>
                      
                  </div>
                    {workouts.length>1&&<MdDeleteOutline className='delete' onClick={()=> removeDietD(index)} />}
                  </div>
              ))}
              <p  onClick={ addWorkoutD } >Add More</p>
            </div>

            <div className='MediaUploads'>
              <div className="imgUpload">
                  <div className='imgContainer'>
                      <img src={imgURL} alt=""   />
                      {!imgURL&&<p>Please Select Image!</p>}
                  </div>
                  <label htmlFor="imgButton">Select Image</label>
                  <input onChange={onImageChange} type="file" name="Image" id="imgButton"  accept="image/png, image/gif, image/jpeg"/>
              </div>

              <div className="imgUpload">
                  <div className='imgContainer'>
                      <img src={bannerURL} alt=""   />
                      {!bannerURL&&<p>Please Select Banner!</p>}
                  </div>
                  <label htmlFor="bannerButton">Select Banner</label>
                  <input onChange={onBannerChange} type="file" name="Banner" id="bannerButton"  accept="image/png, image/gif, image/jpeg"/>
              </div>
            </div>
            <div className='MediaUploads'>
              <div className="imgUpload">
                  <div className='imgContainer'>
                      <img src={pdfURL} alt=""   />
                      {!pdfURL&&<p>Please Select Image/PDF En!</p>}
                  </div>
                  <label className='pdfBtn' style={{width:"160px"}} htmlFor="imgButton2">Select Image/Pdf En</label>
                  <input onChange={onPdfChange} type="file" name="pdf" id="imgButton2"  accept="image/png, application/pdf , .doc, .docx image/jpeg"/>
              </div>


              <div className="imgUpload">
                  <div className='imgContainer'>
                      <img src={pdfURLAr} alt=""   />
                      {!pdfURLAr&&<p>Please Select Image/PDF Ar!</p>}
                  </div>
                  <label className='pdfBtn' style={{width:"150px"}} htmlFor="imgButton3">Select Image/Pdf Ar</label>
                  <input onChange={onPdfChangeAr} type="file" name="pdfAr" id="imgButton3"  accept="image/png, application/pdf , .doc, .docx image/jpeg"/>
              </div>
            </div>
            <div className="videoURL">
                    <div className="addVideo">
                     <input type="text" placeholder='submit video urls' value={videoURL} onChange={(e)=> setVideoURL(e.target.value)}  />
                     <span name='Video' onClick={onVideoUrlUpload}>Save Url</span>
                    </div>
            </div>
            <div className="btn">
                <button type='submit'><span className="material-icons-outlined">save</span>{param.programId? "Update": "Save"}</button>
                <Link to='/package/program'>Cancel</Link>
            </div>
          </form>
        </div>
    </div>
  )
}

export default ProgramAddEdit


