import React, { useEffect, useState } from 'react'
import Toggle from 'react-toggle'
import MUIDataTable from 'mui-datatables'
import { BiPackage } from "react-icons/bi";
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProgram } from '../../redux/MainActions'

const UserProgram = () => {
    const dispatch = useDispatch()

    const userList = useSelector(state=> state?.HitFitAdmin?.UserProgram)
     console.log("userList" ,userList);


     const [ user , setUser ] = useState([])

     useEffect(()=>{
        getUserList()
     },[])

     const getUserList = () => {
        var data = {
            "isActive":true
        }
        dispatch(getUserProgram(data))
     }

     useEffect(()=>{
        if(userList){
            setUser(userList)
        }
     },[])

    const columns = [
        {
            name: "title",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Title</span>
              }
            },
          },
          {
            name: "isActive",
              options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Status</span>
                },
                customBodyRender:(isActive)=>{
                  return(
                      <div>
                      <Toggle
                          checked={isActive}
                          // onChange={onStatusChange}
                           />
                      </div>
                  )
              }
              },
            },
            {
                name: "id",
                options: {
                  filter: true,
                  sort: true,
                  customHeadLabelRender:()=>{
                    return<span style={{
                      letterSpacing:'0',
                      fontWeight:'600'
                    }}>Action</span>
                  },
                  customBodyRender:(id)=>{
                    return(
                        <div>
                          <Link className='editBTN' to={'/package/program/'+id}>
                            <button>Edit</button>
                          </Link>
                        </div>
                    )
                }
                },
              },
    ];

    const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };

  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
       <span>
           <Link to='/health'><span><BiPackage style={{color:"#CE7777"}}/></span>User Program</Link>/
           <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
       </span>
    </div>
   
   <div className="Header">
        <h3><span className="material-icons-outlined"><BiPackage style={{color:"#CE7777"}}/></span>User Program</h3>
       <div>
           <Link to='/package/program/my-workout'>
               ADD
           </Link>
       </div>
   </div>

   <MUIDataTable
       className="table-responsive"
       data={user?user:[]}
       columns={columns}
       options={options}
   />
</div>
  )
}

export default UserProgram