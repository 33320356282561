import axios from "axios";
import Constant from "../Constant";
import { 
    USER_LOGIN_DETAILS,
    GET_NUTRITION_CATEGORY,
    GET_NUTRITION_DIETPLAN,
    GET_NUTRITION_MEAL,
    GET_IMAGE_DATA,
    GET_PACKAGE,
    GET_PACKAGE_PROGRAM,
    GET_PROGRAM_WORKOUT,
    GET_USER_PROGRAM,
    GET_SUBSCRIPTION,
    GET_HASHTAG,
    GET_SESSIONS,
    GET_MULTIPLE_MEDIA_DATA,
    GET_USER_ROLE,
    GET_USERS_LIST,
    GET_ADMIN_HISTORY,
    GET_CONTACT_US,
    GET_TERMS,
    GET_PRIVACY_POLICY,
    GET_REFUND_POLICY,
    GET_LANGUAGE,
    SET_LANGUAGE_ID,
    GET_USER_DETAIL,
    SEND_NOTIFICATION,
    GET_USER_SUBSCRIPTION,
    GET_ENQUIRY_LIST,
    GET_WEB_ORDERS,
    GET_BLOG,
    GET_ADS,
    GET_WEB_IMAGE,
    USER_SIGNUP,
    GET_SUBSCRIPTION_FOR_USER,
    ASSIGN_SUBSCRIPTION_TO_USER
} from "./types";
import swal from "sweetalert";


const config = {
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language": "both"
    }
};

const configFormData = {
    headers: {
        "No" :'Access-Control-Allow-Origin',
        "Content-Type": 'multipart/form-data',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
    }
};

export const onAdminLogin = (data) => (dispatch) => {
    axios
      .post(Constant.getAPI() + `/user/signin`, data)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.success) {
          dispatch({
            type: USER_LOGIN_DETAILS,
            payload: res.data.data,
          });
  
          localStorage.setItem("isloggedIn", true);
          localStorage.setItem("email", res.data.data.email);
          localStorage.setItem("fullName", res.data.data.fullName);
          localStorage.setItem("phone", res.data.data.phone);
          localStorage.setItem("userName", res.data.data.userName);
          localStorage.setItem("AuthToken", res.data.data.token);
          localStorage.setItem("roleId", res.data.data.roleId);
          localStorage.setItem("languageId", 1);
          
          window.location.href = '#/user/role'
          window.location.reload();
        } else {
          dispatch({
            type: USER_LOGIN_DETAILS,
            payload: {},
          });
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        dispatch({
          type: USER_LOGIN_DETAILS,
          payload: {},
        });
        swal({
          title: err.response.data.message,
          text: "email or password is wrong!",
          icon: "error",
          button: "Cancel",
        });
      });
  };
  

export const onUserSignup = (data) => (dispatch) => {
    axios
      .post(Constant.getAPI() + `/user/signup`, data)
      .then((res) => {
        console.log("as78as8as",res.data.data);
        if (res.data.success) {
          dispatch({
            type: USER_SIGNUP,
            payload: res.data.data,
          });
  
        } else {
          dispatch({
            type: USER_SIGNUP,
            payload: { status: false},
          });
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
        dispatch({
          type: USER_SIGNUP,
          payload: {status: false},
        });
        // swal({
        //   title: err.response.data.message,
        //   text: "email or password is wrong!",
        //   icon: "error",
        //   button: "Cancel",
        // });
      });
  };
  

  export const getNutritionCategoryList = (data) => (dispatch) => {
    axios
      .post(Constant.getAPI() + `/nutrition/category/list`, data, config)
      .then((res) => {
          if(res.data){
              dispatch({
                  type:GET_NUTRITION_CATEGORY,
                  payload:res.data.data
              })
          }else{
              dispatch({
                  type:GET_NUTRITION_CATEGORY,
                  payload:[]
              })
          }
      //   console.log('Res Nutri',res.data);
      })
      .catch((err) => {
          dispatch({
              type:GET_NUTRITION_CATEGORY,
              payload:[]
          })
      });
  };


  export const addNutritionCategory = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/nutrition/category/add` , data , config )
    .then((res) => {
        if(res.data.success){
            dispatch(getNutritionCategoryList())
            swal({
              title: 'Added',
              text: `${res.data.message}`,
              icon: "success",
              button: "ok",
            });
            window.location.href='#/nutrition/category'
        }else{
            dispatch(getNutritionCategoryList())
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}


export const editNutritionCatrgory = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+`/nutrition/category/update` , data , config )
    .then((res) => {
        if(res.data.success){
            dispatch(getNutritionCategoryList())
            swal({
                title: 'Updated',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/nutrition/category'
        }
    })
    .catch((err) => {
        console.log(err.message);
    })
}

export const deleteNutritionCategory = (data) => (dispatch) => {
    axios
    .delete(Constant.getAPI()+ `/nutrition/category/delete`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
          },
          data: data
        })
    .then(res=> {
        if(res.data.success){
            dispatch(getNutritionCategoryList())
            swal({
                title: 'Deleted',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
        }
    })
        .catch((err) => {
            // console.log('err.response',err.response);
            swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                button: "Cancel",
              });
        });
}
  
export const getNutritionDietPlan = (data) => (dispatch) => {
      axios
      .post(Constant.getAPI()+`/nutrition/dietplan/list` , data , config)
      .then((res) => {
          if(res.data.success){
              dispatch({
                  type:GET_NUTRITION_DIETPLAN,
                  payload:res.data.data
              })
          }else{
              dispatch({
                  type:GET_NUTRITION_DIETPLAN,
                  payload:[]
              })
          }
          //    console.log('Res Diet',res.data);
      })
      .catch((err) => {
          dispatch({
              type:GET_NUTRITION_DIETPLAN,
              payload:[]
          })
      });
}

  // DIET ADD  
  export const addNutritionDietplan = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/nutrition/dietplan/add` ,data , config)
    .then((res) => {
        if(res.data.success){
            dispatch(getNutritionDietPlan())
            swal({
                title: 'Added',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/nutrition/dietplan'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
  } 
  
  //EDIT NUTRITION DIET PLAN
  export const editNutritionDietPlan = (data)=> (dispatch)=>{
    axios
    .put(Constant.getAPI()+`/nutrition/dietplan/update`, data , config)
    .then((res) =>  {
        if(res.data.success){
            dispatch(getNutritionDietPlan())
            swal({
                title: 'Updated',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/nutrition/dietplan'
              window.location.reload()
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
  }

  export const deleteNutritionDietPlan = (data) => (dispatch)=>{
    // console.log( "DATA", data);
    axios
    .delete(Constant.getAPI()+`/nutrition/dietplan/delete`  ,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
        },
        data: data
      })
    .then(res=> {
        if(res.data.success){
            dispatch(getNutritionDietPlan())
            swal({
                title: 'Deleted',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
        }
    })
    .catch((err) => {
        console.log('err.response',err.response);
        swal({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            button: "Cancel",
          });
    });
  }

  export const getNutritionMeal = (data) => (dispatch) => {
      axios
      .post(Constant.getAPI()+`/nutrition/meal/list` , data , config)
      .then((res) => {
          if(res.data.success){
              dispatch({
                  type:GET_NUTRITION_MEAL,
                  payload:res.data.data
              })
          }else{
              dispatch({
                  type:GET_NUTRITION_MEAL,
                  payload:[]
              })
          }
          console.log("data" , res.data);
      })
      .catch((err) => {
          dispatch({
              type:GET_NUTRITION_MEAL,
              payload:[]
          })
      });

  }

  //ADD NUTRITION MEAL
  export const addNutritionMeal = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/nutrition/meal/add`, data , config)
    .then(res => {
        if(res.data.success){
            dispatch(getNutritionMeal())
            swal({
                title: 'Added',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/nutrition/meal'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
  }

  // EDIT NUTRITION MEAL
  export const editNutritionMeal = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+`/nutrition/meal/update` , data , config)
    .then((res)=> {
        if(res.data.success){
            dispatch(getNutritionMeal())
            swal({
                title: 'Updated',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/nutrition/meal'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
  }

  export const deleteNutritionMeal = (data) => (dispatch) =>{
    axios
    .delete(Constant.getAPI()+`/nutrition/meal/delete` ,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
          },
          data: data
    })
    .then((res)=> {
        if(res.data.success){
            dispatch(getNutritionMeal())
            swal({
                title: 'Deleted',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/nutrition/meal'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            text: "something went wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
  }

  export const UploadMedia = (data) => (dispatch) => {
      axios
      .post(Constant.getAPI()+`/media/add` , data , configFormData)
      .then((res) => {
        console.log('Image upload', res.data);
          if(res.data.success){
              dispatch({
                  type:GET_IMAGE_DATA,
                  payload:res.data.data[0]
              })
              swal({
                title: 'Uploaded',
                text: 'Your file has been successfuly Uploaded',
                icon: "success",
                button: "Cancel",
              })
        
          }else{
              dispatch({
                  type:GET_IMAGE_DATA,
                  payload:{}
              })
          }
      })
      .catch((err) => {
        // console.log("err", err.response.data);
        swal({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            button: "Cancel",
          });
      });
  }

  export const UploadMediaMulti = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/media/add` , data , configFormData)
    .then((res) => {
      console.log('Image upload', res.data);
        if(res.data.success){
            dispatch({
                type:GET_MULTIPLE_MEDIA_DATA,
                payload:res.data.data
            })
        }else{
            dispatch({
                type:GET_IMAGE_DATA,
                payload:{}
            })
        }
    })
    .catch((err) => {
        dispatch({
            type:GET_IMAGE_DATA,
            payload:{}
        })
    });
}

 // GET PACKAGE LIST
 export const getPackage = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/package/list` , config )
    .then((res) =>{
        if(res.data){
            dispatch({
                type:GET_PACKAGE,
                payload:res.data.data
            })
        }else{
            dispatch({
                type:GET_PACKAGE,
                payload:[]
            })
        }
        // console.log('RESPAC',res.data);
    })
 } 

 // ADD PACKAGE LIST ITEM
 export const addPackage = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/package/add` , data , config)
    .then(res=> {
        if(res.data.success){
            dispatch(getPackage())
            swal({
                title: 'Added',
                text: `Package Added Successfully`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/package'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
 }

 // EDIT PACKAGE
 export const editPackage = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+`/package/edit`, data, config)
    .then(res=>{
        if(res.data.success){
            dispatch(getPackage())
            console.log("message", res.data.message);
            swal({
                title: 'Updated',
                text: `Package Updated Succesfully`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/package'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
 }

 export const deletePackage = (data) => (dispatch) =>{
    axios
    .delete(Constant.getAPI()+`/package/delete` ,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
          },
          data: data
    })
    .then((res)=> {
        if(res.data.success){
            dispatch(getPackage())
            swal({
                title: 'Deleted',
                text: `Package Deleted SuccessFully`,
                icon: "success",
                button: "ok",
              });
            //   window.location.href='#/nutrition/meal'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            text: "something went wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
  }
// GET PACKAGE PROGRAM
export const getPackageProgram = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/package/program/list`  , {
        params: data,
        headers: {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
            "Accept-Language": "both"
        }
    }) 
    .then(res=> {
        if(res.data.success){
            dispatch({
                type: GET_PACKAGE_PROGRAM,
                payload:res.data.data
            })
        }else{
            dispatch({
                type: GET_PACKAGE_PROGRAM,
                payload: res.data.data
            })
        }
        
    })
    .catch((err) => {
        console.log('ERROR', err.response.data);
        // swal({
        //     title:"Error",
        //     text: err.response.data.error,
        //     icon: "error",
        //     button: "Cancel",
        //   });
    });
}


//ADD PACKGE PROGRAM LIST
export const addPackagePrograme = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/package/program/add` , data, config)
    .then(res=>{
        if(res.data.success){
            dispatch(getPackageProgram())
            swal({
                title: 'Added',
                text: `Added Successfully`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/package/program'
        }
    })
    .catch((err) => {
        swal({
            title: "Error",
            text: err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}

// EDIT PACKAGE PROGRAM 
export const editPackageProgram = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+`/package/program/edit` , data , config)
    .then(res=> {
        if(res.data.success){
            dispatch(getPackageProgram())
            swal({
                title: 'Updated',
                text: `Package Updated Successfully`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/package/program'
        }
    })
    .catch((err) => {
        swal({
            title: "Error",
            text: err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}


// DELETE PROGRAM 
export const deletePackageProgram = (data) => (dispatch) =>{
    axios
    .delete(Constant.getAPI()+`/package/program/delete` ,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
          },
          data: data
    })
    .then((res)=> {
        if(res.data.success){
            dispatch(getPackageProgram())
            swal({
                title: 'Deleted',
                text: `Program Deleted SuccessFully`,
                icon: "success",
                button: "ok",
              });
            //   window.location.href='#/nutrition/meal'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            text: "something went wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
  }
// GET PACKAGE PRGORAM WORKOUT LIST
export const getPackageProgramWorkout = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/package/program/workout/list`  , {
        params: { data },
        headers: {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
            "Accept-Language": "both"
        }
    })
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_PROGRAM_WORKOUT,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_PROGRAM_WORKOUT,
                payload: res.data.data
            })
        }
        // console.log('RESPAC',res.data);
    })
    .catch((err) => {
        dispatch({
            type:GET_PROGRAM_WORKOUT,
            payload:[]
        })
    });

}

// ADD WORKOUT LIST
export const addPackageProgramWorkout = (data) => (dispatch)=> {
    axios
    .post(Constant.getAPI()+`/package/program/workout/add` , data , config)
    .then(res=>{
        if(res.data.success){
            dispatch(getPackageProgramWorkout())
            swal({
                title: 'Added',
                text:`Workout Addedd Successfully`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/package/program/workout'
        }
    })
    .catch((err) => {
        // console.log("err", err.response);
        swal({
            title: "Error" ,
            text: err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}

//EDIT WORKOUT LIST 
export const editPackageProgramWorkout = (data) => (dispatch)=> {
    axios
    .put(Constant.getAPI()+`/package/program/workout/edit` , data , config)
    .then(res => {
        if(res.data.success){
            dispatch(getPackageProgramWorkout())
            swal({
                title: 'Updated',
                text: `Workout Updated Successfully`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/package/program/workout'
        }
    })
    .catch((err) => {
        swal({
            title: "Error" ,
            text: err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}

// DELETE WORKOUT
export const deletePackageProgramWorkout = (data) => (dispatch) =>{
    axios
    .delete(Constant.getAPI()+`/package/program/workout/delete` ,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
          },
          data: data
    })
    .then((res)=> {
        if(res.data.success){
            dispatch(getPackageProgramWorkout())
            swal({
                title: 'Deleted',
                text: `Workout Deleted SuccessFully`,
                icon: "success",
                button: "ok",
              });
            //   window.location.href='#/nutrition/meal'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            text: "something went wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
  }
// GET USER PROGRAM
export const getUserProgram = () => (dispatch)=> {
    axios
    .get(Constant.getAPI()+`/package/program/my-workout`, config)
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_USER_PROGRAM,
                payload:res.data.data
            })
        }else{
            dispatch({
                type: GET_USER_PROGRAM,
                payload:res.data.data
            })
        }
    })
    .catch((err) => {
        // console.log("err",err.response.data.error);
        swal({
            title:err.response.data.success,
            text: err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}

// GET SUBSCRIPTION LIST 
export const getSubsCription = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/subscription/list` , {
        params: { data },
        headers: {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
            "Accept-Language": "both"
        }
    })
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_SUBSCRIPTION,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_SUBSCRIPTION,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// ADD SUBSCRIPTION
export const addSubscription = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/subscription/add` , data , { headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language": " en,ar"
    }})
    .then(res=> {
        if(res.data.success){
            dispatch(getSubsCription())
            swal({
                title: 'Added',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
               window.location.href='#/subscription'
        }
    })
    .catch((err) => {
        console.log("err",err.response);
        swal({
            title: err.response.data?.error,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const getSubscriptionForUser = () => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/subscription/list?isActive=true&startRange=0&rangeLimit=10&platform=website`, config )
    .then(res=>{
        if(res.data.success){
            console.log("ads5a4sd5", res?.data?.data);
            dispatch({
                type:GET_SUBSCRIPTION_FOR_USER,
                payload: res?.data?.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const assignSubscriptionToUser = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/subscription/joinWebForAdmin` , data , config )
    .then(res=>{
        if(res.data.success){
            console.log("ads5a4sd5", res?.data?.data);
            dispatch({
                type:ASSIGN_SUBSCRIPTION_TO_USER,
                payload: res?.data
            })
            swal({
                title: "Success",
                 text: "Subscription assigned successfully !",
                icon: "success",
                button: "Cancel",
              });
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// EDIT SUBSCRIPTION 
export const editSubscription = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+`/subscription/edit`, data , config )
    .then(res=>{
        if(res.data.success){
            dispatch(getSubsCription())
            swal({
                title: 'Updated',
                text: `Subscription Updated Successfully`,
                icon: "success",
                button: "ok",
              });
               window.location.href='#/subscription'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const userSubscriptions = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/subscription/join/list` , config )
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_USER_SUBSCRIPTION,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_USER_SUBSCRIPTION,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// GET HASHTAG
export const getHashtag = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/hashtag/list` , {
        params: data,
        headers: {
            "Content-Type": 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
            "Accept-Language": "both"
        }
    })
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_HASHTAG,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_HASHTAG,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// ADD  HASHTAG
export const AddHashtag = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/hashtag/add` , data , config)
    .then(res=> {
        if(res.data){
            dispatch(getHashtag())
            swal({
                title: 'Added',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
               window.location.href='#/hashtag'
        }
    })
    .catch((err) => {
        console.log('err.response',err.response);
        swal({
            title: err.response.data.error,
            //  text: "",
            icon: "error",
            button: "Cancel",
          });
    });
}

// EDIT HASHTAG
export const editHashtag = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+`/hashtag/edit` , data , config)
    .then(res=> {
        if(res.data){
            dispatch(getHashtag())
            swal({
                title: 'Updated',
                text: `Hashtag updated successfully`,
                icon: "success",
                button: "ok",
              });
               window.location.href='#/hashtag'
        }
    })
    .catch((err) => {
        console.log('err.response',err.response);
        swal({
            title: err.response.data.error,
            //  text: "",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const deleteHashtag = (data) => (dispatch) => {
    axios
    .delete(Constant.getAPI()+ `/hashtag/delete`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
          },
          data: data
        })
    .then(res=> {
        if(res.data.success){
            dispatch(getUser())
            swal({
                title: 'Deleted',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
        }
    })
        .catch((err) => {
            // console.log('err.response',err.response);
            swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                button: "Cancel",
              });
        });
}

//GET SESSION 
export const getSession = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/live-session/list` , {} , config)
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_SESSIONS,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_SESSIONS,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: "Error",
            text: err.response.data.message,
            icon: "error",
            button: "Cancel",
          });
    });
}

// ADD SESSION 
export const addSession = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/live-session/create`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getSession())
            swal({
                title: 'Added',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/live-session'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}
export const editSession = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+ `/live-session/update`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getSession())
            swal({
                title: 'Updated',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/live-session'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const deleteSession = (data) => (dispatch) => {
    axios
    .delete(Constant.getAPI()+ `/live-session/delete`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
          },
          data: data
        })
    .then(res=> {
        if(res.data.success){
            dispatch(getUser())
            swal({
                title: 'Deleted',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
        }
    })
        .catch((err) => {
            // console.log('err.response',err.response);
            swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                button: "Cancel",
              });
        });
}

// GET USER ROLE
export const getUserRole = () => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/user/role/list`,config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_USER_ROLE,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_USER_ROLE,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// ADD ROLE
export const addUserRole = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/user/role/create`, data, config) 
    .then(res=> {
        if(res.data.success){
            dispatch(getUserRole())
            swal({
                title: 'Added',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/user/role'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}
// Edit ROLE
export const editUserRole = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+`/user/role/update`, data, config) 
    .then(res=> {
        if(res.data.success){
            dispatch(getUserRole())
            swal({
                title: 'Updated',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/user/role'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}


// GET USER LIST
export const getUser = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/user/list` , data , config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_USERS_LIST,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_USERS_LIST,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// EDIT USER
export const editUser = (data) => (dispatch) => {
    axios
    .put(Constant.getAPI()+`/user/edit-profile` , data , config ) 
    .then(res => {
        if(res.data.success){
            dispatch(getUser())
            swal({
                title: 'Updated',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/users'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// Edit Users Status
export const  editUserStatus = (data)=> (dispatch)=>{
    axios
    .post(Constant.getAPI()+`/user/edit-users-status`,data, config)
    .then(res=>{
        if(res.data.success){
            dispatch(getUser())
            swal({
                title: 'Updated',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
        });
    })
}

export const getUserDetail = (data) => (dispatch)=> {
    console.log("data",data);
    axios
    .get(Constant.getAPI()+`/user/details?userId=${data}` , config)
    .then(res=>{
        if(res.data){
            dispatch({
                type: GET_USER_DETAIL,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_USER_DETAIL,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const deleteUserAccount = (data) => (dispatch) => {
    axios
    .delete(Constant.getAPI()+ `/user/admin/delete`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`
          },
          data: data
        })
    .then(res=> {
        if(res.data.success){
            dispatch(getUser())
            swal({
                title: 'Deleted',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
        }
    })
        .catch((err) => {
            // console.log('err.response',err.response);
            swal({
                title: "Error",
                text: err.response.data.message,
                icon: "error",
                button: "Cancel",
              });
        });
}

// Send NOTIFICTIONS 
export const sendNotification = (data) => (dispatch) =>{
    axios
    .post(Constant.getAPI()+`/notification/send-notification`, data , config)
    .then(res=>{
        if(res.data.success){
            dispatch({
                type: SEND_NOTIFICATION,
                payload: res.data.data
            })
            swal({
                title: 'Updated',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
            })
            window.location.href='#/push-notification'
        }else {
            dispatch({
                type: SEND_NOTIFICATION,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        console.log("error" , err.response.data?.details?.message);
        swal({
            title: "Error",
             text: err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}
//GET ADMIN HISTORY SUBSCRIPTION
export const getAdminHistory = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/subscription/join/list` , config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_ADMIN_HISTORY,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_ADMIN_HISTORY,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: "Error" ,
             text:err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}

//GET CONTACT US
export const getContactUs = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/contactus/list` , data , config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_CONTACT_US,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_CONTACT_US,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

//ADD CONTACT US
export const addContactUs = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/contactus/add`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getContactUs())
            swal({
                title: 'Updated',
                text: `Contact-US Updated Successfully`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/nutrition/category'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

 // GET TERMS & CONDITION
export const getTerms = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/terms-conditions/list` , data , config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_TERMS,
                payload: res.data.data
                })
        }else{
            dispatch({
                type: GET_TERMS,
                payload: res.data.data
                })
            }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
        });
    });
 }

 // ADD TERMS & CONDITION
 export const addTerms = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/terms-conditions/add`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getTerms())
            swal({
                title: 'Updated',
                text: `Terms&Condition Updated`,
                icon: "success",
                button: "ok",
              });
            //   window.location.href='#/nutrition/category'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// GET PRIVACY POLICY
export const getPrivacypolicy = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/privacy-policy/list` , data , config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_PRIVACY_POLICY,
                payload: res.data.data
                })
        }else{
            dispatch({
                type: GET_PRIVACY_POLICY,
                payload: res.data.data
                })
            }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
        });
    });
 }

 // ADD PRIVACY POLICY
 export const addPrivacyPolicy = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/privacy-policy/add`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getPrivacypolicy())
            swal({
                title: 'Updated',
                text: "Privacy policy Updated Successfully!",
                icon: "success",
                button: "ok",
              });
            //   window.location.href='#/nutrition/category'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

// GET REFUND POLICY
export const getRefundPolicy = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/refund-policy/list` , data , config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_REFUND_POLICY,
                payload: res.data.data
                })
        }else{
            dispatch({
                type: GET_REFUND_POLICY,
                payload: res.data.data
                })
            }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
        });
    });
 }

 // ADD Refund Policy
 export const addRefundPolicy = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/refund-policy/add`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getRefundPolicy())
            swal({
                title: 'Added',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
            //   window.location.href='#/nutrition/category'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

//   GET LANGUAGE LISTS
export const getLanguage = () => (dispatch) => {
    axios
    .get(Constant.getAPI()+`/language/list` , config)
    .then((res) => {
        if(res.data){
            dispatch({
                type: GET_LANGUAGE,
                payload: res.data.data
                })
        }else{
            dispatch({
                type: GET_LANGUAGE,
                payload: res.data.data
                })
            }
    })
    
} 

export const setLanguageID = (id) => (dispatch) => {
    dispatch({
        type: SET_LANGUAGE_ID,
        payload: id
    })
}

// GET ENQUIRY
export const getEnquiry = (data) => (dispatch)=> {
    axios
    .post(Constant.getAPI()+ `/enquiry/list`, data, config)
    .then((res)=>{
        if(res.data){
            dispatch({
                type: GET_ENQUIRY_LIST,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_ENQUIRY_LIST,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
        });
    });
}

// GET WEB ORDERS
export const getWebOrders = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/subscription/purchase-list/web` ,data, config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_WEB_ORDERS,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_WEB_ORDERS,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: "Error" ,
             text:err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}

// GET WEB BLOGS
export const getWebBlog = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/blog/list` ,data, config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_BLOG,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_BLOG,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: "Error" ,
             text:err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}

export const addBlog = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/blog/add`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getWebBlog())
            swal({
                title: 'Added',
                text: `${res.data.message}`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/blog'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const editBlog = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/blog/edit`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getWebBlog())
            swal({
                title: 'Updated',
                text: `Blog Updated.`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/blog'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const getAds = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+`/ad/list` ,data, config) 
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_ADS,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_ADS,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: "Error" ,
             text:err.response.data.error,
            icon: "error",
            button: "Cancel",
          });
    });
}

export const editAds = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/ad/add`, data , config)
    .then((res)=>{
        if(res.data.success){
            dispatch(getWebBlog())
            swal({
                title: 'Added',
                text: `Add Updated.`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/website-ads'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}


export const getWebImg = (data) => (dispatch)=> {
    axios
    .post(Constant.getAPI()+ `/website-image/list`, data, config)
    .then((res)=>{
        if(res.data){
            dispatch({
                type: GET_WEB_IMAGE,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_WEB_IMAGE,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
        });
    });
}

export const addWebImg = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/website-image/add`, data , config)
    .then((res)=>{
        if(res.data.success){
            // dispatch(getWebBlog())
            swal({
                title: 'Added',
                text: `Added Successfully.`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/website-image'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const editWebImg = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI()+ `/website-image/edit`, data , config)
    .then((res)=>{
        if(res.data.success){
            // dispatch(getWebBlog())
            swal({
                title: 'Updated',
                text: `Updated Successfully.`,
                icon: "success",
                button: "ok",
              });
              window.location.href='#/website-image'
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data.message,
            // text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}