import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MdGroupAdd } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { getAdminHistory } from '../../redux/MainActions';
import MUIDataTable from 'mui-datatables'
import Toggle from 'react-toggle';

const ViewSubscription = () => {
    const params = useParams()
    const dispatch = useDispatch()
    console.log("param", params.id);
    const OrderList = useSelector(state=>state?.HitFitAdmin?.AdminHistory)

    const [ order , setOrder ] = useState()
    const [ user , setUser ] = useState()
    
    console.log("user",user);
    useEffect(()=>{
        getAminList()
      },[])
    
      const getAminList = () => {
        var  data = {
        }
        dispatch(getAdminHistory(data))
    }


    useEffect(()=>{
        if(params.id){
            var spr = OrderList?.filter(data=> data.id==params.id)
            console.log("spr",spr);
            setOrder(spr)
            setUser(spr[0]?.user)
        }
    },[params.id])
    const columns = [
        {
            name: "productNameEn",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Product Name (En)</span>
              }
            },
          },
        {
            name: "productNameAr",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Product Name (Ar)</span>
              }
            },
          },
        {
          name: "productId",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Product ID</span>
            }
          },
        },
        {
          name: `duration`,
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Duration</span>
            }
          },
        },
        {
          name: "durationTypeEn",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Duration-Type (En)</span>
            }
          },
        },
        {
          name: "durationTypeAr",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Duration-Type (Ar)</span>
            }
          },
        },
        {
          name: "joinedDate",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Joining Date</span>
              },
              customBodyRender:(createdAt)=>{
                let date = new Date(Date.parse(createdAt))
                let year = date.getFullYear()
                let month = date.getMonth()+1
                let day = date.getDate()
                // console.log(`${day}/${month}/${year}`);
                return<div >
                 {`${day}/${month}/${year}`}
                </div>
              }
            },
          },
        {
          name: "expiryDate",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Expiry Date</span>
              },
              customBodyRender:(expiryDate)=>{
                let date = new Date(Date.parse(expiryDate))
                let year = date.getFullYear()
                let month = date.getMonth()+1
                let day = date.getDate()
                // console.log(`${day}/${month}/${year}`);
                return<div >
                 {`${day}/${month}/${year}`}
                </div>
              }
            },
          },
        {
          name: "price",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Price</span>
            }
          },
        },
        {
            name: "isExpired",
              options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Status</span>
                },
                customBodyRender:(isExpired)=>{
                  return(
                      <div>
                      <Toggle
                          checked={!isExpired}
                          // onChange={onStatusChange}
                           />
                      </div>
                  )
              }
              },
            },
      ]
      const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };
    // let user = order[0]?.user
    // console.log("user",user);
  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
      <span>
          <Link to='/health'><span><MdGroupAdd style={{color:"#CE7777"}}/></span>Subscription Order Details</Link>/
          <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
      </span>
    </div>
  
  <div className="Header">
        <h3><span className="material-icons-outlined"><MdGroupAdd style={{color:"#CE7777"}}/></span>Subscription Order Details</h3>
      <div>
          <Link to='/subscription/orders'>
              Back
          </Link>
      </div>
  </div>
  <div className="userInfo">
            <div className="userSubscripton">
              <span className='head'>User Subscriptions Details</span>
                <div className="table">
                  <MUIDataTable
                    sx={{marginTop: "10px"}}
                    className="table-responsive"
                    data={order?order:[]}
                    columns={columns}
                    options={options}
                  />
                </div>
            </div>

            <hr />
            
            <div className="user_container1">
            <h1 className='head' >User Details</h1>
                <div className='user_container'>
                    <img src={ user?.imageUrl!==null  ? user?.imageUrl :"./new1.png"} alt="img.com" style={{height: "25vh", width:"12vw"}} />
                    <div className="basicInfo">
                        <div className="userDetail">
                            <span>Full Name</span>
                            <p>{user?.fullName}</p>
                        </div>

                        <div className="userDetail">
                            <span>User Name</span>
                            <p>{user?.userName}</p>
                        </div>

                        <div className="userDetail">
                            <span>Email</span>
                            <p>{user?.email}</p>
                        </div>

                        <div className="userDetail">
                            <span>Role</span>
                            <p>{user?.roleId===1? "Admin":user?.roleId===2? "Trainer": "User"  }</p>
                        </div>

                        <div className="userDetail">
                            <span>Phone No</span>
                            <p>{user?.phone}</p>
                        </div>

                        <div className="userDetail">
                            <span className='gender'>Gender</span>
                            <p>{user?.gender}</p>
                        </div>

                    </div>    
                </div>
            </div>
    </div>
</div>
  )
}

export default ViewSubscription