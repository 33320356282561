/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { addSession, editSession, getSession, getUser, UploadMedia  } from '../../redux/MainActions'
import swal from 'sweetalert'
import Select from 'react-select';



const SessionAddEdit = () => {
  const param = useParams()
  console.log("param",param.liveSessionId);

  const sessionData = useSelector(state=>state?.HitFitAdmin?.Session)
  const UserList = useSelector(state=>state?.HitFitAdmin?.User)

 

  const [bannerURL, setBannerURL] = useState('')
  const [bannerID, setBannerID] = useState('')
  const [mediaName, setMediaName] = useState('')
  const [ TRAINER , setTRAINER ] = useState([])

  const [ trainerOptions , setTrainerOptions ] = useState([])
  const [ trainerValue , setTrainerValue ] = useState([])

  const dispatch =useDispatch()
  const ImageData = useSelector(state=>state?.HitFitAdmin?.ImageData)
  // console.log("trainerOptions",trainerOptions);

  const [ session , setSession ] = useState({
    workoutName:'',
    description:'',
    startFrom:"",
    endsOn:"",
    status:'notify',
    isActive: "true",
    TrainerId: null ,
    type:'standard',
    priority: "",
    // channelName:""
  })

  useEffect(()=>{
    if(TRAINER?.length>0){
      var data = TRAINER?.map(opt=>{
        return {
          label: opt.userName,
          value : opt.id
        }
      })
      setTrainerOptions(data)
    }
  },[TRAINER])

  const onTrainerChange = (value) => {
    setTrainerValue(value)
  }

  useEffect(()=>{
    if(param.liveSessionId!==undefined){
      findSession(param.liveSessionId)
    }
  },[param.liveSessionId])

  const findSession = (id) =>{
    var data = {
      'liveSessionId':id
    }
    dispatch(getSession(data))
  }

  useEffect(()=>{
    getUserList()
  },[])

const getUserList = () =>{
    var data = {
       "status": true
    }
    dispatch(getUser(data))
} 

useEffect(()=>{
  if(UserList){
    var spr = UserList?.filter(data=>data.roleId == 2)
    // console.log("spr",spr);
    setTRAINER(spr)
  }
},[UserList])

  useEffect(()=>{
    if(sessionData&&param.liveSessionId!==undefined){
      var spr = sessionData.filter(data=>data.id==param.liveSessionId)
      var spr2 = TRAINER?.filter(cate=> cate.id==spr[0]?.TrainerId)
      console.log("spr",spr);
      var endDate =  new Date((spr[0]?.endsOn))
      var startDate =  new Date((spr[0]?.startFrom))

      // var times = spr[0]?.startFrom
      // var start  = new Date(spr[0]?.startFrom * 1000) 
      
      const lpad2 = (number) => String(number).padStart(2, "0")
      console.log("startDate", spr[0]?.endsOn);
      setSession({
        workoutName: spr[0]?.workoutName,
        description: spr[0]?.description,
        startFrom: startDate.getFullYear() + '-' + lpad2(startDate.getMonth() + 1) + '-' + lpad2(startDate.getDate()),
        endsOn: endDate.getFullYear() + '-' + lpad2(endDate.getMonth() + 1) + '-' + lpad2(endDate.getDate()),
        isActive:spr[0]?.isActive?"true":"false",
        status: spr[0]?.status,
        userType: spr[0]?.userType,
        // channelName: spr[0]?.liveDetails?.channel,
        type: spr[0]?.type,
        priority: spr[0]?.priority

      })
      setTrainerValue({label: spr2[0]?.userName, value: spr2[0]?.id})
      // console.log("DATE " , new Date(spr[0]?.endsOn).toLocaleDateString("en-US"));
      setBannerID(spr[0]?.BannerId)
      setBannerURL(spr[0]?.Media?.url)
    }
  },[sessionData])

  const onHandleChange =(e)=>{
    const {name, value} =e.target
    setSession({...session,[name]:value})
  }

  useEffect(() => {
     if(mediaName==='Banner'){
        setBannerID(ImageData?.id)
      }
  }, [ImageData, mediaName])

  const onBannerChange = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    var url = URL.createObjectURL(e.target.files[0]) 
    setBannerURL(url)

    const formData = new FormData()

    formData.append('medias',file)

    dispatch(UploadMedia(formData))
  }

  const onSubmit = (e) => {
  e.preventDefault()
  if(session.workoutName===''){
    swal({
      title: 'Error',
      text: "Please Add Workout Name!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(session.status===''){
    swal({
      title: 'Error',
      text: "Please select status!",
      icon: "warning",
      button: "Ok",
    });
    return false
  }
  else if(session.description===''){
    swal({
      title: 'Error!',
      text: "Please Add description ",
      icon: "warning",
      button: "Ok",
    });
    return false
  }else if(bannerURL===''){
    swal({
      title: 'Error',
      text: "Please Add Banner ",
      icon: "warning",
      button: "Ok",
    });
    return false
  }

  // let date = new Date(session.startFrom)
  // let year = date.getFullYear()
  // let month = date.getMonth()
  // let now = date.getDate()

  // let startFrom = `${year}/${month}/${now}`
  let startFrom = new Date(session.startFrom).getTime()/1000
  let endsOn = new Date(session.endsOn).getTime()/1000
  console.log("startFrom" , startFrom);

  if(param.liveSessionId!==undefined){
    var data = {
      liveSessionId:parseInt(param.liveSessionId),
      workoutName: session.workoutName,
      isActive:session.isActive =='true'?true:false,
      dateAndTime:startFrom.toString().toString(),
      BannerId: bannerID,
      endsOn: endsOn.toString(),
      description: session.description,
      status: session.status,
      userType : session.userType,
      // channelName: session.channelName,
      TrainerId: trainerValue.value,
      type: session.type,
      priority: parseInt(session.priority)
    }
    dispatch(editSession(data))
    console.log("data", data);
   }else{
    var data = {
      workoutName: session.workoutName,
      isActive:session.isActive == 'true'? true : false,
      startFrom:startFrom.toString(),
      BannerId: bannerID,
      endsOn:endsOn.toString(),
      description: session.description,
      status: session.status,
      // channelName: session.channelName,
      TrainerId: trainerValue.value,
      type: session.type,
      priority: parseInt(session.priority)
      // uid: 0,
      // role: 1,
      // tokentype: "uid",
      // expiry: 1,
      // userType : session.userType
    }
      dispatch(addSession(data))
      console.log("data" , data );
   }
}
 
  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
       <span>
           <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>sensors</span>Session</Link>/
           <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
       </span>
    </div>
   
   <div className="Header">
        <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>sensors</span>Add Live-Session</h3>
       <div>
           <Link to='/live-session'>
               Back
           </Link>
       </div>
   </div>

   <div className="Box_Container">
            <form onSubmit={onSubmit} >
                <div className="InputF">
                    <div className="inputField_type_one">
                        <label htmlFor='workoutName'>Workout Name</label>
                        <input type="text" id='workoutName' name='workoutName' placeholder='E.g: Fat Loss Workout' value={session.workoutName} onChange={onHandleChange} />
                        
                        <label htmlFor='startFrom'>Start From</label>
                        <input type="date" id='startFrom'  name='startFrom' value={session.startFrom} onChange={onHandleChange}/>
                        
                        <label htmlFor='isActive'>Status</label>
                        <select name="isActive" id='isActive'  value={session.isActive} onChange={onHandleChange} >
                            <option value='true'>Active</option>
                            <option value='false'>InActive</option>
                        </select>

                        <label htmlFor='priority'>Priority</label>
                        <input type="number" id='priority' name='priority' placeholder='E.g: 123' value={session.priority} onChange={onHandleChange} />
                    </div>


                    <div className="inputField_type_one">
                        <label htmlFor='status'>Workout Status</label>
                        <select name="status" id='status'  value={session.status} onChange={onHandleChange} >
                            <option value='notify'>Notify</option>
                            <option value='live'>Live</option>
                        </select>
                        <label htmlFor='endsOn'>End Date</label>
                        <input type="date" id='endsOn'  name='endsOn' value={session.endsOn} onChange={onHandleChange} />

                        {/* <label htmlFor='channelName'>Channel Name</label>
                        <input type="channelName" id='channelName'  name='channelName' placeholder='E.g: live14' value={session.channelName} onChange={onHandleChange} /> */}

                        <label htmlFor='type'>Type</label>
                        <select name="type" id='type'  value={session.type} onChange={onHandleChange} >
                            <option value='premium'>Premium</option>
                            <option value='standard'>Standard</option>
                        </select>

                        <label>Trainer</label>
                        {/* <select name="TrainerId" value={session.TrainerId} onChange={onHandleChange}>
                            <option>- Select Trainer -</option>
                            {TRAINER.length>0?TRAINER.map((cate,index)=>(
                          <option value={cate.id} key={index}>{cate.userName}</option>
                        )):null}
                        </select> */}
                        <Select
                          className='selctField'
                          onChange={onTrainerChange}
                          options={trainerOptions}
                          value={trainerValue}
                        />
                    </div>
                </div>

                <div className='decription'>
                        <label htmlFor='description'>Description</label>
                        <textarea type="text" id='description' rows="5" name='description' placeholder='E.g: We will discuss fat loss wortouts in this session' value={session.description} onChange={onHandleChange} />
                </div>

                <div className='MediaUploads'>
                        <div className="imgUpload">
                            <div className='imgContainer'>
                                <img src={bannerURL} alt=""   />
                                {!bannerURL&&<p>Please Select Banner!</p>}
                            </div>
                            <label htmlFor="bannerButton">Select Banner</label>
                            <input onChange={onBannerChange} type="file" name="Banner" id="bannerButton"  accept="image/png, image/gif, image/jpeg"/>
                        </div>
                </div>
                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span>Save</button>
                    <Link to='/live-session'>Cancel</Link>
                </div>
            </form>
        </div>
   
</div>
  )
}

export default SessionAddEdit