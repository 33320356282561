import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import { deleteNutritionCategory, editNutritionCatrgory, getNutritionCategoryList } from '../../redux/MainActions'
import Toggle from 'react-toggle'
import swal from "sweetalert";


const NutritionCategory = () => {

    const dispatch =useDispatch()
    const NutritionCategory = useSelector(state=>state?.HitFitAdmin?.NutritionCategory)
    const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
    
    const [CATEGORY, setCATEGORY] = useState([])

    useEffect(() => {
      getCetegory()
    }, [])  
    
    const getCetegory =(id)=>{
      var data = {
          "offset":0,
          "limit":50,
      }
      dispatch(getNutritionCategoryList(data))
    }
 
    useEffect(() => {
     if(NutritionCategory){
        setCATEGORY(NutritionCategory)
     }
    }, [NutritionCategory])
    
    const onStatusChange = (isActive, cateID)=> {
      // console.log("error", cateID);
      var data= {
        "categoryId": cateID,
        "isActive": !isActive
      }
      dispatch(editNutritionCatrgory(data))
    }
    console.log('NutritionCategory',NutritionCategory);

    const handleDelete = (id)=>{
      if(id){
        swal({
          title: "Are you sure?",
          text: "Once deleted,you will not be able to recover this Data!",
          icon: "warning",
          dangerMode: true,
          buttons: true,
        })
        .then(willDelete => {
          if (willDelete) {
            var data = {
                "categoryId": id
               }
               dispatch(deleteNutritionCategory(data))
              //  console.log("deleteID", data);
          }
        });
      }
    }

    const columns = [
        {
          name: "titleEn",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Title (En)</span>
            }
          },
        },
        {
          name: "titleAr",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Title (Ar)</span>
            }
          },
        },
        {
          name: "createdAt",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Created Date</span>
              },
              customBodyRender:(createdAt)=>{
                let date = new Date(Date.parse(createdAt))
                let year = date.getFullYear()
                let month = date.getMonth()+1
                let day = date.getDate()
                // console.log(`${day}/${month}/${year}`);
                return<div >
                 {`${day}/${month}/${year}`}
                </div>
              }
            },
          },
        {
            name: "isActive",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Status</span>
              },
              customBodyRender:(isActive,tableMeta)=>{
                // console.log("tableMeta",tableMeta);
                var cateID = tableMeta?.rowData[5]
                return(
                    <div>
                    <Toggle
                        checked={isActive}
                        onChange={() => onStatusChange(isActive, cateID)}
                         />
                    </div>
                )
            }
            },
          },
          {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Action</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div>
                      <Link className='editBTN' to={'/nutrition/category/'+id}>
                        <button>Edit</button>
                      </Link>
                    </div>
                )
            }
            },
          },
          {
            name: "id",
            options: {
              filter: true,
              sort: true,
              // display:false,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Action</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div className='deleteBTN'>
                        <button onClick={()=>handleDelete(id)}>Delete</button>
                    </div>
                )
            }
            },
          },
    ];
    

      const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };

  return (
    <div className='MainContainer'>
         <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>category</span>Nutrition Category</Link>/
                <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>category</span>Nutrition Category</h3>
            <div>
                <Link to='/nutrition/category/add'>
                    ADD
                </Link>
            </div>
        </div>

        <MUIDataTable
            className="table-responsive"
            data={CATEGORY?CATEGORY:[]}
            columns={columns}
            options={options}
        />
    </div>
  )
}

export default NutritionCategory