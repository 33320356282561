/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { MdGroupAdd } from "react-icons/md";
import { getAdminHistory, getUser, userSubscriptions } from '../../redux/MainActions'
import MUIDataTable from 'mui-datatables'
import Toggle from 'react-toggle'

const SubscriptAdminHistory = () => {

  const  dispatch = useDispatch()
  const AdminHistoryList = useSelector(state=>state?.HitFitAdmin?.AdminHistory)
  const UserList = useSelector(state=>state?.HitFitAdmin?.User)
  // const AdminHistoryList = useSelector(state=>state?.HitFitAdmin?.UserSubsCription)

  const [ HISTORY , setHISTORY ] = useState([])

  
  // console.log("UserList" ,UserList);

  useEffect(()=>{
    getAminList()
    getUserList()
  },[])

  const getUserList = () =>{
    var data = {
       "status": true
    }
    dispatch(getUser(data))
  } 

  const getAminList = () => {
    var  data = {
      // startRange: 0,
      // rangeLimit: 10 // list
    }
    // dispatch(userSubscriptions(data))
    dispatch(getAdminHistory(data))
  } 

  useEffect(()=>{
    if(AdminHistoryList){
      setHISTORY(AdminHistoryList)
    }
  },[AdminHistoryList])
 

  const columns = [
    {
      name: "productNameEn",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Product Name (En)</span>
        }
      },
    },
    {
      name: "productNameAr",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Product Name (Ar)</span>
        }
      },
    },
    {
      name: "productId",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Product ID</span>
        }
      },
    },
    {
      name: "UserId",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>User</span>
        },customBodyRender:(UserId)=>{
          var Filtered = UserList?.filter(cate=>cate.id===UserId)
          // console.log( "Filtered",Filtered);
          return <>
            {Filtered[0]?Filtered[0]?.fullName:"No Defined"}
          </>
        }
      },
    },
    {
      name: "joinedDate",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Joining Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(createdAt);
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            // console.log(`${day}/${month}/${year}`);
            return<div >
             {`${day}/${month}/${year}`}
            </div>
          }
        },
      },
    {
      name: "expiryDate",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Expiry Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(createdAt);
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            // console.log(`${day}/${month}/${year}`);
            return<div >
             {`${day}/${month}/${year}`}
            </div>
          }
        },
      },
    {
      name: "isExpired",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Status</span>
          },
          customBodyRender:(isExpired)=>{
            return(
                <div>
                <Toggle
                    checked={!isExpired}
                    // onChange={onStatusChange}
                     />
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>View Info</span>
          },
          customBodyRender:(id)=>{
            return(
                <div>
                  <Link className='userDetailBtn' to={'/subscription/orders/'+id}>
                    <button>View Details</button>
                  </Link>
                </div>
            )
        }
        },
      },
   
  ];

  const options = {
    filterType: "dropdown",
    search:false,
    filter:false,
    viewColumns: false,
    print: false,
    pagination:true,
    download: false,
    selectableRows: "none",
    // responsive: 'scrollMaxHeight',
  };
  
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><MdGroupAdd style={{color:"#CE7777"}}/></span>Subscription Orders</Link>/
              <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><MdGroupAdd style={{color:"#CE7777"}}/></span>Subscription Orders </h3>
          {/* <div>
              <Link to='/subscription/admin/history'>
                  ADD
              </Link>
          </div> */}
      </div>

      <MUIDataTable
          className="table-responsive"
          data={HISTORY?HISTORY:[]}
          columns={columns}
          options={options}
      />
    </div>
  )
}

export default SubscriptAdminHistory