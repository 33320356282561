import React, { useEffect, useState } from "react";
import MUIDataTable from 'mui-datatables'
import {BsFillImageFill} from "react-icons/bs"
import { Link } from 'react-router-dom'
import { getWebImg , editWebImg } from '../../redux/MainActions'
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";

const WebsiteImg = () => {
  const dispatch = useDispatch()
  const webI = useSelector(state=> state?.HitFitAdmin?.webImg)

    useEffect(()=>{
        getRoleList()
    },[])

    const getRoleList = () =>{
        var data = {
            // "status":true
        }
        dispatch(getWebImg(data))
    }
    const onStatusChange = (isActive, ID)=> {
      // console.log("error", ID);
      var data= {
        "id":ID,
        "isActive": !isActive
      }
      dispatch(editWebImg(data))
    }

    const columns =[
        {
          name:"MediaEn",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: ()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Image (En)</span>
              },
              customBodyRender:(MediaEn)=>{
                return<div className='tableIMG'>
                  <img src={MediaEn?.url}  alt='No Image here!'/>
                </div>
              }
            }  
        },
        {
          name:"MediaAr",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: ()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Image (Ar)</span>
              },
              customBodyRender:(MediaAr)=>{
                return<div className='tableIMG'>
                  <img src={MediaAr?.url}  alt='No Image here!'/>
                </div>
              }
            }  
        },
        {
          name:"titleEn",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: ()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Name</span>
              }
            }  
        },
        {
          name:"priority",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: ()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Priority</span>
              }
            }  
        },
        {
          name: "isActive",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Status</span>
              },
              customBodyRender:(isActive,tableMeta)=>{
                var ID = tableMeta?.rowData[5]
                return(
                    <div>
                    <Toggle
                        checked={isActive}
                        onChange={ ()=> onStatusChange(isActive, ID)}
                         />
                    </div>
                )
            }
            },
          },
          {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Action</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div>
                      <Link className='editBTN' to={'/website-image/add/'+id}>
                        <button>Edit</button>
                      </Link>
                    </div>
                )
            }
            },
          },
    ]
    const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };
  return (
    <div className="MainContainer">
      <div className="breadcrumb">
        <span>
          <Link to="/health">
            <span>
              <BsFillImageFill style={{ color: "#CE7777" }} />
            </span>
            Website Images
          </Link>
          /
          <Link to="/">
            <span className="material-icons-outlined">home</span>Home
          </Link>
        </span>
      </div>

      <div className="Header">
        <h3>
          <span className="material-icons-outlined">
            <BsFillImageFill style={{ color: "#CE7777" }} />
          </span>
          Website Images
        </h3>
        <div>
          <Link to="/website-image/add">ADD</Link>
        </div>
      </div>

      <MUIDataTable
        className="table-responsive"
        data={webI ? webI : []}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default WebsiteImg;
