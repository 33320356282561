/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import { MdGroupAdd } from "react-icons/md";
import Toggle from 'react-toggle'
import { useDispatch, useSelector } from 'react-redux'
import { editSubscription, getSubsCription } from '../../redux/MainActions'

const Subscription = () => {

  const dispatch = useDispatch()
  const getSubsCiptionList = useSelector(state=> state?.HitFitAdmin?.SubsCription) 


  // console.log("getSubsCiptionList",getSubsCiptionList);

  const [SUBSCRIPTION , setSUBSCRIPTION ] = useState([])

  useEffect(()=>{
    Subscription()
  },[])

  const Subscription = () => {
    var data = {
      "platform": "google"
    }
    dispatch(getSubsCription(data))
  }

  useEffect(()=>{
    if(getSubsCiptionList){
      setSUBSCRIPTION(getSubsCiptionList)
    }
  },[getSubsCiptionList])

  const onStatusChange = (isActive, ID)=> {
    console.log("error", ID , isActive);
    var data= {
      "id": ID,
      "isActive": !isActive
    }
    dispatch(editSubscription(data))
  }
  
  const columns = [
    {
      name: "billedTypeEn",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Bil Type (En)</span>
        }
      },
    },
    {
      name: "billedTypeAr",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Bil Type (Ar)</span>
        }
      },
    },
    {
      name: `titleEn`,
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Duration (EN)</span>
        }
      },
    },
    {
      name: `titleAr`,
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Duration (AR)</span>
        }
      },
    },
    {
      name: "productId",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Product Id</span>
        }
      },
    },
    {
      name: "platform",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Platform</span>
        }
      },
    },
    {
      name: "createdAt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Created Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(createdAt);
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            // console.log(`${day}/${month}/${year}`);
            return<div >
             {`${day}/${month}/${year}`}
            </div>
          }
        },
      },
    {
      name: "isActive",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Status</span>
          },
          customBodyRender:(isActive,tableMeta)=>{
            var ID = tableMeta?.rowData[8]
            return(
                <div>
                <Toggle
                    checked={isActive}
                    onChange={() => onStatusChange(isActive, ID)}
                     />
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Action</span>
          },
          customBodyRender:(id)=>{
            return(
                <div>
                  <Link className='editBTN' to={'/subscription/'+id}>
                    <button>Edit</button>
                  </Link>
                </div>
            )
        }
        },
      },
   
  ];

  const options = {
    filterType: "dropdown",
    search:false,
    filter:false,
    viewColumns: false,
    print: false,
    pagination:true,
    download: false,
    selectableRows: "none",
    // responsive: 'scrollMaxHeight',
  };

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><MdGroupAdd style={{color:"#CE7777"}}/></span>Subscription</Link>/
              <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><MdGroupAdd style={{color:"#CE7777"}}/></span>Subscription</h3>
          <div>
              <Link to='/subscription/add'>
                  ADD
              </Link>
          </div>
      </div>

      <MUIDataTable
          className="table-responsive"
          data={SUBSCRIPTION?SUBSCRIPTION:[]}
          columns={columns}
          options={options}
      />
    </div>
  )
}

export default Subscription