/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getNutritionDietPlan, getNutritionCategoryList ,deleteNutritionDietPlan, editNutritionDietPlan} from '../../redux/MainActions'
import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import Toggle from 'react-toggle'
import swal from "sweetalert";



const NutritionDietPlan = () => {
  const dispatch = useDispatch()
  const [ DIETPLAN , setDIETPLAN ] = useState([])
  const nutritionDietPlan = useSelector(state => state.HitFitAdmin.NutirtionDietPlan)
  const NutritionCategory = useSelector(state=>state?.HitFitAdmin?.NutritionCategory)
  const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)

  console.log("NutritionCategory", LanguageID);
  console.log("nutritionDietPlan",nutritionDietPlan);

  useEffect(() => {
      getCetegory()
      getDietPlan()
  },[]) 

  // useEffect(() => {
  //   getDietPlan()
  //   getCetegory()
  // },[])

  const getDietPlan = (id) => {
    var data = {
      // "categoryId":2,
      // "title":"High protien Diet",
      // "offset":0,
      "limit":1000,
      // "languageId":id
      // "dietPlanId":2,
      // "isActive":true
    }
    dispatch(getNutritionDietPlan(data))
  }

  const getCetegory =(id)=>{
    var data = {
        "offset":0,
        "limit":1000,
        // "languageId":id
        // "categoryId":2,
        // "categoryName":"Faltloss",
        // "isActive":true
    }
    dispatch(getNutritionCategoryList(data))
  }

  useEffect(() => {
    if(nutritionDietPlan){
      setDIETPLAN(nutritionDietPlan)
    }
  },[nutritionDietPlan])

  // console.log('DIETPLAN',DIETPLAN);

  const handleDelete = (id)=>{
   if(id){
    swal({
      title: "Are you sure?",
      text: "Once deleted,you will not be able to recover this Data!",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    })
    .then(willDelete => {
      if (willDelete) {
        var data = {
            "dietPlanId" :id
           }
           dispatch(deleteNutritionDietPlan(data))
           console.log("deleteID", data);
      }
    });
   } 
  }

  const onStatusChange = (isActive, ID)=> {
    // console.log("error", ID);
    var data= {
      "dietPlanId":ID,
      "isActive": !isActive
    }
    dispatch(editNutritionDietPlan(data))
  }


  const columns = [
    {
      name:"ImageEn",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Image En</span>
        },
        customBodyRender:(ImageEn)=>{
          return<div className='tableIMG'>
            <img src={ImageEn?.url} alt="No Image here!" />
          </div>
        }
      },
    },
    {
      name:"BannerImageEn",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Banner Image En</span>
        },
        customBodyRender:(BannerImageEn)=>{
          return<div className='tableIMG'>
            <img src={BannerImageEn?.url} alt="No Image here!" />
          </div>
        }
      },
    },
    {
      name:"titleEn",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Title (En)</span>
        }
      },
    },
    {
      name:"titleAr",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Title (Ar)</span>
        }
      },
    },
    {
      name:"categoryId",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Category (En)</span>
        },customBodyRender:(categoryId)=>{
          var Filtered = NutritionCategory?.filter(cate=>cate.id===categoryId)
          // console.log( "Filtered",Filtered);
          return <>
            {Filtered[0]?Filtered[0]?.titleEn:"No Defined"}
          </>
        }
      },
    },
    {
      name:"categoryId",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Category (Ar)</span>
        },customBodyRender:(categoryId)=>{
          var Filtered = NutritionCategory?.filter(cate=>cate.id===categoryId)
          // console.log( "Filtered",Filtered);
          return <>
            {Filtered[0]?Filtered[0]?.titleAr:"No Defined"}
          </>
        }
      },
    },
    {
      name: "createdAt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Created Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(Date.parse(createdAt))
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            return<div >
             {`${day}/${month}/${year}`}
            </div>
          }
        },
      },
    {
      name: "isActive",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:() =>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Status</span>
        },
        customBodyRender:(isActive,tableMeta)=>{
          // console.log("isActive",typeof(isActive));
          var ID = tableMeta?.rowData[8]
          return(
              <div>
              <Toggle
                  checked={isActive}
                  onChange={()=>onStatusChange(isActive,ID)}
                   />
              </div>
          )
      }
      },
    },
    {
      name: "id",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Action</span>
        },
        customBodyRender:(id)=>{
          return(
              <div>
                <Link className='editBTN' to={'/nutrition/dietplan/'+id}>
                  <button>Edit</button>
                </Link>
              </div>
          )
      }
      },
    },
    {
      name: "id",
      options: {
        filter: true,
        sort: true,
        // display:false,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Action</span>
        },
        customBodyRender:(id)=>{
          return(
              <div className='deleteBTN'>
                  <button onClick={()=>handleDelete(id)}>Delete</button>
              </div>
          )
      }
      },
    },
  ];
   
  const options = {
    filterType: "dropdown",
    search:false,
    filter:false,
    viewColumns: false,
    print: false,
    pagination:true,
    download: false,
    selectableRows: "none",
    // responsive: 'scrollMaxHeight'
  }

  return (
    <div className='MainContainer'>
       <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>note_alt</span>Nutrition Diet Plan</Link>/
                <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
            </span>
        </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>note_alt</span>Nutrition Diet Plan</h3>
            <div>
                <Link to='/nutrition/dietplan/add'>
                    ADD
                </Link>
            </div>
        </div>

        <MUIDataTable
            className="table-responsive"
            data={DIETPLAN?DIETPLAN:[]}
            columns={columns}
            options={options}
        />
    </div>
  )
}

export default NutritionDietPlan