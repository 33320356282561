import React from 'react'
import Sidebar from '../common/sidebar/Sidebar'
import Topbar from '../common/topbar/Topbar'
import { HashRouter, Route, Routes } from 'react-router-dom'
import NutritionCategory from '../components/Nutrition/NutritionCategory'
import NutritionCateAddEdit from '../components/Nutrition/NutritionCateAddEdit'
import NutritionDietPlan from '../components/Nutrition/NutritionDietPlan'
import NutritionDietPlanAddEdit from '../components/Nutrition/NutritionDietPlanAddEdit'
import NutritionMeal from '../components/Nutrition/NutritionMeal'
import SessionList from '../components/session/SessionList'
import NutritionMealAddEdit from '../components/Nutrition/NutritionMealAddEdit'
import Package from '../components/Package/Package'
import PackageAddEdit from '../components/Package/PackageAddEdit'
import Program from '../components/Package/Program'
import ProgramAddEdit from '../components/Package/ProgramAddEdit'
import Workout from '../components/Package/Workout'
import WorkoutAddEdit from '../components/Package/WorkoutAddEdit'
import UserProgram from '../components/Package/UserProgram'
import Subscription from '../components/Subscription/Subscription'
import SubscriptionAddEdit from '../components/Subscription/SubscriptionAddEdit'
import Hashtag from '../components/Hashtag/Hashtag'
import HashtagAddEdit from '../components/Hashtag/HashtagAddEdit'
import Role from '../components/roles/Role'
import User from '../components/Users/User'
import SubscriptAdminHistory from '../components/Subscription/SubscriptAdminHistory'
import SessionAddEdit from '../components/session/SessionAddEdit'
import ContactUsAdd from '../components/Contactus/ContactUsAdd'
import TermsAddEdit from '../components/Terms/TermsAddEdit'
import PrivacyPolicyAddEdit from '../components/Privacy/PrivacyPolicyAddEdit'
import RefundPolicyAddEdit from '../components/RefundPolicy/RefundPolicyAddEdit'
import UserEdit from '../components/Users/UserEdit'
import RoleAddEdit from '../components/roles/RoleAddEdit'
import UsersDetails from '../components/Users/UsersDetails'
import PushNotification from '../components/Users/PushNotification'
import Trainers from '../components/Users/Trainers'
import ViewSubscription from '../components/Subscription/ViewSubscription'
import Enquiry from '../components/Enquiry/Enquiry'
import EnquiryDetails from '../components/Enquiry/EnquiryDetails'
import WebOrders from '../components/Subscription/WebOrders'
import WebDetails from '../components/Subscription/WebDetails'
import BlogList from '../components/Blog/BlogList'
import BlogAddEdit from '../components/Blog/BlogAddEdit'
import WebSiteAds from '../components/Ads/WebSiteAds'
import WebSiteAdsAdd from '../components/Ads/WebSiteAdsAdd'
import WebsiteImg from '../components/Subscription/WebsiteImg'
import WebImgAdd from '../components/Subscription/WebImgAdd'

const Dashbord = () => {
  return (
    <HashRouter>
      <Topbar/>
      <div className='container'>
        <Sidebar/>
        <Routes>
          <Route exact path='/nutrition/category' element={<NutritionCategory/>}/>
          <Route exact path='/nutrition/category/add' element={<NutritionCateAddEdit/>}/>
          <Route exact path='/nutrition/category/:categoryId' element={<NutritionCateAddEdit/>}/>
          <Route exact path='/nutrition/dietplan' element={<NutritionDietPlan/>}/>
          <Route exact path='/nutrition/dietplan/add' element={<NutritionDietPlanAddEdit/>}/>
          <Route exact path='/nutrition/dietplan/:dietplanId' element={<NutritionDietPlanAddEdit/>}/>
          <Route exact path='/nutrition/meal' element={<NutritionMeal/>}/>
          <Route exact path='/nutrition/meal/add' element={<NutritionMealAddEdit/>}/>
          <Route exact path='/nutrition/meal/:mealId' element={<NutritionMealAddEdit/>}/>
          <Route exact path='/live-session' element={<SessionList/>}/>
          <Route exact path='/live-session/create' element={<SessionAddEdit/>}/>
          <Route exact path='/live-session/:liveSessionId' element={<SessionAddEdit/>}/>
          <Route exact path='/package' element={<Package/>}/>
          <Route exact path='/package/add' element={<PackageAddEdit/>}/>
          <Route exact path='/package/:id' element={<PackageAddEdit/>}/>
          <Route exact path='/package/program' element={<Program/>}/>
          <Route exact path='/package/program/add' element={<ProgramAddEdit/>}/>
          <Route exact path='/package/program/:programId' element={<ProgramAddEdit/>}/>
          <Route exact path='/package/program/workout' element={<Workout/>}/>
          <Route exact path='/package/program/workout/add' element={<WorkoutAddEdit/>}/>
          <Route exact path='/package/program/workout/:workoutId' element={<WorkoutAddEdit/>}/>
          <Route exact path='/package/program/my-workout' element={<UserProgram/>}/>
          <Route exact path='/subscription' element={<Subscription/>}/>
          <Route exact path='/subscription/add' element={<SubscriptionAddEdit/>}/>
          <Route exact path='/subscription/:subsId' element={<SubscriptionAddEdit/>}/>
          <Route exact path='/hashtag' element={<Hashtag/>}/>
          <Route exact path='/hashtag/add' element={<HashtagAddEdit/>}/>
          <Route exact path='/hashtag/:hashtagId' element={<HashtagAddEdit/>}/>
          <Route exact path='/user/role' element={<Role/>}/>
          <Route exact path='/user/role/add' element={<RoleAddEdit />}/>
          <Route exact path='/user/role/:roleId' element={<RoleAddEdit />}/>
          <Route exact path='/users' element={<User/>}/>
          <Route exact path='/users/add' element={<UserEdit/>}/>
          <Route exact path='/trainers' element={<Trainers/>}/>
          <Route exact path='/push-notification' element={<PushNotification/>}/>
          <Route exact path='/user/:id' element={<UserEdit/>}/>
          <Route exact path='/user/details/:id' element={<UsersDetails/>}/>
          <Route exact path='/privacy-policy' element={<PrivacyPolicyAddEdit/>}/>
          <Route exact path='/refund-policy' element={<RefundPolicyAddEdit/>}/>
          <Route exact path='/terms' element={<TermsAddEdit/>}/>
          <Route exact path='/enquiry' element={<Enquiry/>}/>
          <Route exact path='/website-ads' element={<WebSiteAds/>}/>
          <Route exact path='/website-ads/add' element={<WebSiteAdsAdd/>}/>
          <Route exact path='/website-ads/add/:id' element={<WebSiteAdsAdd/>}/>
          <Route exact path='/blog' element={<BlogList/>}/>
          <Route exact path='/blog/add' element={<BlogAddEdit/>}/>
          <Route exact path='/blog/add/:id' element={<BlogAddEdit/>}/>
          <Route exact path='/web-orders' element={<WebOrders/>}/>
          <Route exact path='/enquiry/:id' element={<EnquiryDetails/>}/>
          <Route exact path='/contact-us' element={<ContactUsAdd/>}/>
          <Route exact path='/subscription/orders' element={<SubscriptAdminHistory/>}/>
          <Route exact path='/subscription/orders/:id' element={<ViewSubscription/>}/>
          <Route exact path='/web-orders/:id' element={<WebDetails/>}/>
          <Route exact path='/website-image' element={<WebsiteImg/>}/>
          <Route exact path='/website-image/add' element={<WebImgAdd/>}/>
          <Route exact path='/website-image/add/:id' element={<WebImgAdd/>}/>
        </Routes>
      </div>
    </HashRouter>
  )
}

export default Dashbord