/* eslint-disable no-redeclare */
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MdGroupAdd } from "react-icons/md";
import { addSubscription, editSubscription, getNutritionDietPlan, getPackageProgram, getSubsCription} from '../../redux/MainActions'
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';

const SubscriptionAddEdit = () => {
    const param = useParams()
    // console.log( "param" ,param.subsId);

    const subsList = useSelector(state=> state?.HitFitAdmin?.SubsCription)
    const nutritionDietPlan = useSelector(state=>state.HitFitAdmin.NutirtionDietPlan)
    const packageProgram = useSelector(state=>state?.HitFitAdmin?.PackageProgram)
    const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
    console.log("packageProgram" ,packageProgram);

    const dispatch = useDispatch()
    const [ subscribeData , setSubscribeData ] = useState({
        billedTypeEn:'',
        billedTypeAr:'',
        titleEn:'',
        titleAr:'',
        messageEn:'',
        messageAr:'',
        isActive:"true",
        durationTypeEn:'',
        durationTypeAr:'أيام',
        duration:'',
        price:'',
        typeEn: "standard",
        typeAr: "معيار",
        platform: "google",
        productId: "",
        // DietplanId:null,
        // ProgramId:null
    })

    const onChangeHandle = (e) => {
        const { value , name } = e.target
        setSubscribeData({...subscribeData,[name]: value})
    }

    useEffect(() => {
         getCetegory()
         getProgram()   
    }, [])

    const getProgram = (id) => {
        var data = {
            // "isActive":true,
            // "languageId":id,
        }
        dispatch(getPackageProgram(data))
    }

    useEffect(()=>{
        if(param.subsId){
            getSubsList(param.subsId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getSubsList = (id) => {
        var data = {
            "id" : id,
        }
        dispatch(getSubsCription(data))
    }

    const getCetegory = (id)=>{
        var data = {
            "offset":0,
           //  "limit":20,
            // "categoryId":2,
            // "languageId":id,
            // "categoryName":"Faltloss",
            // "isActive":true
        }
        dispatch(getNutritionDietPlan(data))
      }

    useEffect(()=>{
        if(subsList&&param.subsId){
            var spr = subsList.filter(data=>data.id==param.subsId)
            console.log("spr" , spr);
            setSubscribeData({
                billedTypeEn:spr[0]?.billedTypeEn,
                billedTypeAr:spr[0]?.billedTypeAr,
                messageAr:spr[0]?.messageAr,
                messageEn:spr[0]?.messageEn,
                isActive:spr[0]?.isActive?"true":"false",
                durationTypeEn:spr[0]?.durationTypeEn,
                durationTypeAr:spr[0]?.durationTypeAr,
                duration:spr[0]?.duration,
                price:spr[0]?.price,
                productId:spr[0]?.productId,
                titleEn: spr[0]?.titleEn,
                titleAr: spr[0]?.titleAr,
                platform: spr[0]?.platform,
                // DietplanId: spr[0]?.DietplanId,
                typeEn: spr[0]?.typeEn,
                typeAr: spr[0]?.typeAr,
                // ProgramId: spr[0]?.ProgramId
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[subsList])

    const submitHandle = (e) => {
        e.preventDefault()

        if(subscribeData.titleEn==='' && subscribeData.titleAr===''){
            swal({
              title: 'Error!',
              text: "Please Add Title En or  Title Ar!",
              icon: "warning",
              button: "Ok",
            });
            return false
          }else if(subscribeData.billedTypeEn==='' && subscribeData.billedTypeAr===''){
            swal({
              title: 'Error!',
              text: "Please select billedType-En Or  billedType-Ar!",
              icon: "warning",
              button: "Ok",
            });
            return false
          }
          else if(subscribeData.messageEn==='' && subscribeData.messageAr===''){
            swal({
              title: 'Error !',
              text: "Please Add Message-En or Message-Ar !",
              icon: "warning",
              button: "Ok",
            });
            return false
          }
          else if(subscribeData.typeEn==='' && subscribeData.titleAr===''){
            swal({
              title: 'Please Add Type !',
              text: "Please select Type-En Or Type-Ar!",
              icon: "warning",
              button: "Ok",
            });
            return false
          }

        if(param.subsId){
            var data ={
                id: parseInt(param.subsId),
                billedTypeEn: subscribeData.billedTypeEn,
                billedTypeAr: subscribeData.billedTypeAr,
                durationTypeEn: subscribeData.durationTypeEn,
                durationTypeAr: subscribeData.durationTypeAr,
                isActive: subscribeData.isActive == 'true'? true : false,
                messageEn: subscribeData.messageEn,
                messageAr: subscribeData.messageAr,
                duration: subscribeData.duration,
                price: subscribeData.price,
                titleEn: subscribeData.titleEn,
                titleAr: subscribeData.titleAr,
                typeEn: subscribeData.typeEn,
                typeAr: subscribeData.typeAr,
                platform: subscribeData.platform,
                productId: subscribeData.productId,
                // DietplanId: parseInt(subscribeData.DietplanId),
                // ProgramId: parseInt(subscribeData.ProgramId)

            }
            console.log("data",data);
            dispatch(editSubscription(data))
        }else {
            var data = {
                billedTypeEn: subscribeData.billedTypeEn,
                billedTypeAr: subscribeData.billedTypeAr,
                durationTypeEn: subscribeData.durationTypeEn,
                durationTypeAr: subscribeData.durationTypeAr,
                isActive: subscribeData.isActive == 'true'? true : false,
                messageEn: subscribeData.messageEn,
                messageAr: subscribeData.messageAr,
                duration: subscribeData.duration,
                price: subscribeData.price,
                titleEn: subscribeData.titleEn,
                titleAr: subscribeData.titleAr,
                typeEn: subscribeData.typeEn,
                typeAr: subscribeData.typeAr,
                platform: subscribeData.platform,
                productId: subscribeData.productId,
                // DietplanId: parseInt(subscribeData.DietplanId),

            }
            console.log(data);
            dispatch(addSubscription(data))
        }
    }

  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
        <span>
            <Link to='/health'><span><MdGroupAdd style={{color:"#CE7777"}}/></span>Subscription </Link>/
            <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
        </span>
     </div>
    
    <div className="Header">
         <h3><span className="material-icons-outlined"><MdGroupAdd style={{color:"#CE7777"}}/></span>{param.subsId? "Edit" : "Add"} Subscription</h3>
        <div>
            <Link to='/subscription'>
                Back
            </Link>
        </div>
    </div>

    <div className="Box_Container">
        <form onSubmit={submitHandle} >
            <div className="InputF">
                <div className="inputField_type_one">

                    <label htmlFor='titleEn'>title (En)</label>
                    <input type="text" id='titleEn' name='titleEn' placeholder='E.g: test2'  value={subscribeData.titleEn} onChange={onChangeHandle}/>

                    <label htmlFor='messageEn'>Message (En)</label>
                    <input type="text" id='messageEn' name='messageEn' placeholder='E.g: save 20%'  value={subscribeData.messageEn} onChange={onChangeHandle}/>

                    <label htmlFor='billedTypeEn'>Bill Type (En)</label>
                    <select name="billedTypeEn" value={subscribeData.billedTypeEn} onChange={onChangeHandle} >
                        <option value='Weekly'>Weekly</option>
                        <option value='Monthly'>Monthly</option>
                        <option value='Annually'>Annually</option>
                    </select>

                    <label htmlFor='durationTypeEn'>Duration-Type (En)</label>
                    <select name="durationTypeEn" id='durationTypeEn' value={subscribeData.durationTypeEn} onChange={onChangeHandle} >
                        <option value=''> - Select duration Type - </option>
                        <option value='Days'>Days</option>
                        <option value='Weeks'>Weeks</option>
                        <option value='Months'>Months</option>
                        <option value='Years'>Years</option>
                    </select>

                    <label htmlFor='typeEn'>type (En)</label>
                    <select name="typeEn" id='type' value={subscribeData.typeEn} onChange={onChangeHandle} >
                        <option value=''> - Select Type - </option>
                        <option value='standard'>Standard</option>
                        <option value='premium'>Premium</option>
                    </select>

                    <label htmlFor='isActive'>Status</label>
                    <select name="isActive" id='isActive' value={subscribeData.isActive} onChange={onChangeHandle} >
                        <option value=''> - Select Status - </option>
                        <option value='true'>Active</option>
                        <option value='false'>InActive</option>
                    </select>

                    <label htmlFor='productId'>Product-Id</label>
                    <input type="text" id='productId' name='productId' placeholder='E.g: com.hi'  value={subscribeData.productId} onChange={onChangeHandle}/>

                    <label htmlFor='duration'>Duration</label>
                    <input type="text" id='duration' name='duration' placeholder='E.g: 30'  value={subscribeData.duration} onChange={onChangeHandle}/>

                    {/* <label htmlFor="dietID">{LanguageID==1?"Diet-Plan":"Diet-Plan-Ar"}</label>
                    <select name="DietplanId" value={subscribeData.DietplanId} onChange={onChangeHandle}>
                      <option>- Select Category -</option>
                      {nutritionDietPlan.length>0?nutritionDietPlan.map((cate,index)=>(
                      <option value={cate.id} key={index}>{cate.titleEn}</option>
                      )):null}
                    </select> */}
                </div>
                
                <div className="inputField_type_one">
                    <label htmlFor='titleAr'>Title (Ar)</label>
                    <input type="text" id='tittitleArle' name='titleAr' placeholder='E.g: test2'  value={subscribeData.titleAr} onChange={onChangeHandle}/>

                    <label htmlFor='messageAr'>Message (Ar)</label>
                    <input type="text" id='messageAr' name='messageAr' placeholder='E.g: save 20%'  value={subscribeData.messageAr} onChange={onChangeHandle}/>

                    <label htmlFor='billedTypeAr'>Bill Type (Ar)</label>
                    <select name="billedTypeAr" value={subscribeData.billedTypeAr} onChange={onChangeHandle} >
                        <option value='أسبوعياً'>أسبوعياً</option>
                        <option value='شهرياً'>شهرياً</option>
                        <option value='سنوياً'>سنوياً</option>
                    </select>

                    <label htmlFor='durationTypeAr'>Duration-Type (Ar)</label>
                    <select name="durationTypeAr" id='durationTypeAr' value={subscribeData.durationTypeAr} onChange={onChangeHandle} >
                        <option value=''> - Select duration Type - </option>
                        <option value='أيام'>أيام</option>
                        <option value='أسابيع'>أسابيع</option>
                        <option value='أشهر'>أشهر</option>
                        <option value='سنوات'>سنوات</option>
                    </select>

                    <label htmlFor='typeAr'>type (Ar)</label>
                    <select name="typeAr" id='type' value={subscribeData.typeAr} onChange={onChangeHandle} >
                        <option value=''> - Select Type - </option>
                        <option value='ممتاز'>معيار</option>
                        <option value='قياسيّ'>غالي</option>
                    </select>

                    <label htmlFor='platform'>Plat-form</label>
                    <select name="platform" id='platform' value={subscribeData.platform} onChange={onChangeHandle} >
                        <option value=''> - Select Status - </option>
                        <option value='google'>Google</option>
                        <option value='apple'>Apple</option>
                        <option value='website'>Website</option>
                    </select>
                   
                    
                    <label htmlFor='price'>Price</label>
                    <input type="text" id='price' name='price' placeholder='E.g: 1$'  value={subscribeData.price} onChange={onChangeHandle}/>
                        
                    {/* <label htmlFor="ProgramId">Program</label>
                    <select name="ProgramId" value={subscribeData.ProgramId} onChange={onChangeHandle}>
                      <option>- Select Category -</option>
                      {packageProgram.length>0?packageProgram.map((cate,index)=>(
                      <option value={cate.id} key={index}>{cate.titleEn}</option>
                      )):null}
                    </select> */}

                </div>
            </div>
            <div className="btn">
                <button type='submit'><span className="material-icons-outlined">save</span>{param?.subsId? "Update":"save"}</button>
                <Link to='/subscription'>Cancel</Link>
            </div>
        </form>
    </div>
</div>
  )
}

export default SubscriptionAddEdit