import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import { BiHelpCircle } from "react-icons/bi";
import Toggle from 'react-toggle'
import { useDispatch, useSelector } from 'react-redux';
import { getEnquiry } from '../../redux/MainActions';


const Enquiry = () => {
    const dispatch = useDispatch()
    const ENQUIRY = useSelector(state=>state?.HitFitAdmin?.Enquiry)

    const [ Enquiry , setEnquiry] = useState([])
    console.log("Enquiry", ENQUIRY);

    useEffect(()=> {
        getList()
    },[]) 
  
    const getList = () => {
        var data ={
        }
        dispatch(getEnquiry(data))
    }
    
    useEffect(()=>{
        if(ENQUIRY){
            setEnquiry(ENQUIRY)
        }
    },[ENQUIRY])

    const columns = [
        {
          name: "name",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Name</span>
            }
          },
        },
        {
          name: "email",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>E-mail</span>
            }
          },
        },
        {
          name: "phone",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Phone-No</span>
            }
          },
        },
        {
          name: "createdAt",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Created Date</span>
              },
              customBodyRender:(createdAt)=>{
                let date = new Date(Date.parse(createdAt))
                let year = date.getFullYear()
                let month = date.getMonth()+1
                let day = date.getDate()
                // console.log(`${day}/${month}/${year}`);
                return<div >
                 {`${day}/${month}/${year}`}
                </div>
              }
            },
          },
          {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Enquiry Info</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div>
                      <Link className='userDetailBtn' to={'/enquiry/'+id}>
                        <button>Enquiry Details</button>
                      </Link>
                    </div>
                )
            }
            },
          },
       
    ];
    

      const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };
return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><BiHelpCircle style={{color:"#CE7777"}}/></span>Enquiry</Link>/
              <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><BiHelpCircle style={{color:"#CE7777"}}/></span>Enquiry</h3>
          {/* <div>
              <Link >
                  ADD
              </Link>
          </div> */}
      </div>

      <MUIDataTable
          className="table-responsive"
          data={Enquiry?Enquiry:[]}
          columns={columns}
          options={options}
      />
    </div>
  )
}

export default Enquiry