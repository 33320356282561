import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { MdPolicy } from "react-icons/md";
import { Link } from 'react-router-dom'
import { addRefundPolicy, getRefundPolicy } from '../../redux/MainActions';

const RefundPolicyAddEdit = () => {
    const dispatch = useDispatch()
    const refundtData = useSelector(state=> state?.HitFitAdmin?.RefundPolicy)


    const [refundPolicyEn , setRefundPolicy ] = useState({
      refundPoliciesEn:null
    });

    const [refundPolicyAr , setRefundPolicyAr ] = useState({
      refundPoliciesAr:null
    });

    const handleChangeEn = (value) => {
        setRefundPolicy({refundPoliciesEn:value});
   };
    const handleChangeAr = (value) => {
      setRefundPolicyAr({refundPoliciesAr:value});
   };

   useEffect(()=>{
    getRefundPolicyList()
},[])

const getRefundPolicyList = () => {
    var data ={
    }
    dispatch(getRefundPolicy(data))
}

useEffect(()=>{
    if(refundtData){
        var spr = refundtData;
        console.log("spr",spr);
        setRefundPolicy({
          refundPoliciesEn: spr?.refundPolicies?.refundPoliciesEn
        })
        setRefundPolicyAr({
          refundPoliciesAr: spr?.refundPolicies?.refundPoliciesAr
        })
    }
},[refundtData])


    const onSubmit =(e) => {
        e.preventDefault()
        var data = {
           refundPolicies : {
            refundPoliciesEn: refundPolicyEn.refundPoliciesEn,
            refundPoliciesAr: refundPolicyAr.refundPoliciesAr
           }
        }
        dispatch(addRefundPolicy(data))
        console.log("data",data);
    }

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}><MdPolicy/></span>Refund Policy</Link>/
              <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}><MdPolicy/></span>Refund Policy</h3>
          <div>
              <Link to='/live-session/create'>
                  Back
              </Link>
          </div>
      </div> 
      
      <div className="Editoe_form">
              <form onSubmit={onSubmit}>
                    <div className='terms'>
                      <label htmlFor="terms">Refund Policy (En)</label>
                      <div className='terms_data'  >
                        <ReactQuill
                          name = "tandc"
                          value={refundPolicyEn.refundPoliciesEn}
                          onChange={handleChangeEn}
                          className='quill'
                        />
                      </div>
                    </div>
              </form>
              
              <hr style={{width:"96%" , border: "2px solid #DDDDDD ", borderRadius: "5px", marginLeft:"2%", marginRight:"2%", marginTop:"30px"}}/>

              <form onSubmit={onSubmit}>
                    <div className='terms'>
                      <label htmlFor="terms">Refund Policy (Ar)</label>
                      <div className='terms_data'  >
                        <ReactQuill
                          name = "tandc"
                          value={refundPolicyAr.refundPoliciesAr}
                          onChange={handleChangeAr}
                          className='quill'
                        />
                      </div>
                    </div>
                    <div className="btn">
                        <button type='submit'><span className="material-icons-outlined">save</span>Save</button>
                        <Link to='/nutrition/category'>Cancel</Link>
                    </div>
              </form>
      </div>
    </div>
  )
}

export default RefundPolicyAddEdit