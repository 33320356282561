import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FaBlogger } from 'react-icons/fa'
import {getAds} from "../../redux/MainActions"
import Toggle from 'react-toggle'

const WebSiteAds = () => {
    const dispatch = useDispatch()
    const userAds = useSelector(state=> state?.HitFitAdmin?.ads)

    const [ ads , setAds ] = useState([])

    useEffect(()=>{
        getRoleList()
    },[])

    useEffect(()=>{
        if(userAds){
            setAds(userAds)
        }
      },[userAds])
    const getRoleList = () =>{
        var data = {
            // "status":true
        }
        dispatch(getAds(data))
    }

    const columns = [
          {
            name: "textEn",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Text En</span>
              },
              customBodyRender:(textEn)=>{
                return(
                    <div dangerouslySetInnerHTML={{ __html: textEn }}>
                    </div>
                )
            }
            },
          },
          {
            name: "textAr",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Text Ar</span>
              },
              customBodyRender:(textAr)=>{
                return(
                    <div dangerouslySetInnerHTML={{ __html: textAr }}>
                    </div>
                )
            }
            },
          },
          {
            name: "isActive",
              options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Status</span>
                },
                customBodyRender:(isActive)=>{
                  return(
                      <div>
                      <Toggle
                          checked={isActive}
                          // onChange={onStatusChange}
                           />
                      </div>
                  )
              }
              },
            },
            {
              name: "id",
              options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Action</span>
                },
                customBodyRender:(id)=>{
                  return(
                      <div>
                        <Link className='editBTN' to={'/website-ads/add/'+ id}>
                          <button>Edit</button>
                        </Link>
                      </div>
                  )
              }
              },
            },
      ]; 
    const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };
  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
      <span>
          <Link to='/health'><span><FaBlogger style={{color:"#CE7777"}}/></span>WebSite Ads</Link>/
          <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
      </span>
    </div>
  
  <div className="Header">
        <h3><span className="material-icons-outlined"><FaBlogger style={{color:"#CE7777"}}/></span>WebSite Ads</h3>
      <div>
          <Link to='/website-ads/add'>
              ADD
          </Link>
      </div>
  </div>

  <MUIDataTable
      className="table-responsive"
      data={ads?ads:[]}
      columns={columns}
      options={options}
  />
</div>
  )
}

export default WebSiteAds