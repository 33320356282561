import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { HiUserCircle } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { getPackageProgram, getPackageProgramWorkout, getUserDetail } from '../../redux/MainActions'
import MUIDataTable from 'mui-datatables'
import Toggle from 'react-toggle';
import moment from 'moment'

const UsersDetails = () => {
    const param = useParams()
    const dispatch = useDispatch()

    const data = useSelector(state=> state?.HitFitAdmin?.userDetail)
    const workout = useSelector(state=> state?.HitFitAdmin?.ProgramWorkout)
    const packageProgram = useSelector(state=>state?.HitFitAdmin?.PackageProgram)

    const [ details , setDetails ] = useState()
    const [workoutData, setWorkoutData] = useState()
    const [ programData, setProgramData ] = useState()

    console.log("details" ,details);

    console.log("workout", programData );

    useEffect(()=>{
      getData()
      getWorkoutList()
    },[])

    const getData = () =>{
      dispatch(getUserDetail(parseInt(param.id)))
    }

    const getWorkoutList = ()=> {
        var data = { 
          //  languageId:LanguageID
        }
        dispatch(getPackageProgramWorkout(data))
        dispatch(getPackageProgram())
    }


    useEffect(()=>{
      if(data){
        setDetails(data)
      }
    },[data])

    let id =   details!==undefined?.UserPrograms[0]?.WorkoutId 
    useEffect(()=>{
      if(id!==undefined){
        var spr = workout?.filter(data=>data.id==id)
        // console.log("spr",spr);
        setWorkoutData(spr)
      } 
    },[id])

    let id2 = details!==undefined?.UserPrograms[0]?.ProgramId
    useEffect(()=>{
      if(id2!== undefined){
        var spr = packageProgram.filter(data=>data.id==id2)
        setProgramData(spr)
      }
    },[id2])

    const userProgrms = [
      {
      name:"MainImage",
       options: {
        filter: true,
        sort: true,
        customHeadLabelRender: ()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Image</span>
        },
        customBodyRender:(MainImage)=>{
          return<div className='tableIMG'>
            <img src={MainImage?.url}  alt='No Image here!'/>
          </div>
        }
        }
      },
    {
      name:"BannerImage",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Banner Image</span>
        },
        customBodyRender:(BannerImage)=>{
          return<div className='tableIMG'>
            <img src={BannerImage?.url} alt="No Image here!" />
          </div>
        }
      },
     },
     {
      name: "title",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Title</span>
        }
      },
    },
    {
      name: "createdAt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Created Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(Date.parse(createdAt))
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            return<div >
             {`${day}/${month}/${year}`}
            </div>
          }
        },
      },
    ]
    
    const userWorkout = [
      {
        name: "title",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Title</span>
          }
        },
      },
      {
        name: "day",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>status</span>
          }
        },
      },
      {
        name: "createdAt",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Created Date</span>
            },
            customBodyRender:(createdAt)=>{
              let date = new Date(Date.parse(createdAt))
              let year = date.getFullYear()
              let month = date.getMonth()+1
              let day = date.getDate()
              // console.log(`${day}/${month}/${year}`);
              return<div >
               {`${day}/${month}/${year}`}
              </div>
            }
          },
        },
    ] 

    const columns = [
      {
        name: "productNameEn",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Product Name En</span>
          }
        },
      },
      {
        name: "productNameAr",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Product Name Ar</span>
          }
        },
      },
      {
        name: `productId`,
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Product Id</span>
          }
        },
      },
      {
        name: `duration`,
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Duration</span>
          }
        },
      },
      {
        name: "durationTypeEn",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Duration-Type En</span>
          }
        },
      },
      {
        name: "durationTypeAr",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Duration-Type Ar</span>
          }
        },
      },
      {
        name: "createdAt",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Created Date</span>
            },
            customBodyRender:(createdAt)=>{
              let date = new Date(Date.parse(createdAt))
              let year = date.getFullYear()
              let month = date.getMonth()+1
              let day = date.getDate()
              // console.log(`${day}/${month}/${year}`);
              return<div >
               {`${day}/${month}/${year}`}
              </div>
            }
          },
        },
      {
        name: "price",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Price</span>
          }
        },
      },
      {
        name: "expirationDate",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Expiry Date</span>
          },
          customBodyRender:(expirationDate)=>{
            return(
                <div>
                  {moment(expirationDate).format("L")}
                </div>
            )
        }
        },
      },
      {
        name: "isExpired",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Status</span>
            },
            customBodyRender:(isExpired)=>{
              return(
                  <div>
                  <Toggle
                      checked={!isExpired}
                      // onChange={onStatusChange}
                       />
                  </div>
              )
          }
          },
        },
    ]
    const options = {
      filterType: "dropdown",
      search:false,
      filter:false,
      viewColumns: false,
      print: false,
      pagination:true,
      download: false,
      selectableRows: "none",
      // responsive: 'scrollMaxHeight',
  };
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><HiUserCircle style={{color:"#CE7777"}}/></span>User Details</Link>/
              <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><HiUserCircle style={{color:"#CE7777"}}/></span>User Details</h3>
            <div>
                <Link to='/users'>
                    Back
                </Link>
            </div>
      </div>

      {/* <div className='Box_Container'> */}
        <div className="userInfo">
            <div className="user_container">
                <img src={ details?.imageUrl== null?"./new1.png" : details?.imageUrl} alt="img.com" />
                <div className="basicInfo">
                    <div className="userDetail">
                        <span>Full Name</span>
                        <p>{details?.fullName}</p>
                    </div>

                    <div className="userDetail">
                        <span>User Name</span>
                        <p>{details?.userName}</p>
                    </div>

                    <div className="userDetail">
                        <span>Email</span>
                        <p>{details?.email}</p>
                    </div>

                    <div className="userDetail">
                        <span>Role</span>
                        <p>{details?.roleId===2? "Trainer": "User"}</p>
                    </div>

                    <div className="userDetail">
                        <span>Phone No</span>
                        <p>{details?.phone}</p>
                    </div>

                    <div className="userDetail">
                        <span className='gender'>Gender</span>
                        <p>{details?.gender}</p>
                    </div>

                </div>    
            </div>
            
            <hr />

            <div className="userSubscripton">
              <span className='head'>User Subscriptions Details</span>
                <div className="table">
                  <MUIDataTable
                    sx={{marginTop: "10px"}}
                    className="table-responsive"
                    data={details?.UserSubscriptions?details?.UserSubscriptions:[]}
                    columns={columns}
                    options={options}
                  />
                </div>
            </div>

            <hr />

            <div className="userSubscripton">
             <span className='head'> User Program Details</span>
              <MUIDataTable
                className="table-responsive"
                data={programData?programData:[]}
                columns={userProgrms}
                options={options}
              />
            </div>

           <hr />

           <div className="userSubscripton">
             <span className='head'> User Workout Details</span>
              <MUIDataTable
                className="table-responsive"
                data={workoutData?workoutData:[]}
                columns={userWorkout}
                options={options}
              />
            </div>
        </div>
      </div>
    // </div>
  )
}

export default UsersDetails