import {React, useEffect, useState} from "react";
import './sidebar.css'
import {
  MdOutlineCategory,
  MdOutlineRestaurant,
  MdOutlineSensors,
  MdGroupAdd,
  MdPolicy,
  MdNotifications
} from "react-icons/md";
import { BiPackage,BiHash ,BiHelpCircle } from "react-icons/bi";
import { IoIosCall } from "react-icons/io";
import { ImUser } from "react-icons/im";
import { TfiPencilAlt } from "react-icons/tfi";
import { TfiWrite } from 'react-icons/tfi'
import { BsFillBriefcaseFill,BsFillImageFill } from 'react-icons/bs'
import { HiUserCircle } from 'react-icons/hi'
import { GiWeightLiftingUp } from 'react-icons/gi'
import { FaBlogger } from 'react-icons/fa'

import { Link,useLocation } from "react-router-dom";

const Sidebar = () => {
  const [navActive, setNavActive] = useState(1);
  const location = useLocation()

  useEffect(() => {
    if(location.pathname.includes('/nutrition/category')){
        setNavActive(1)
    }
    if(location.pathname.includes('/nutrition/dietplan')){
      setNavActive(2)
    }
    if(location.pathname.includes('/nutrition/meal')){
        setNavActive(3)
    }
    if(location.pathname.includes('/live-session')){
      setNavActive(4)
    }
    if(location.pathname.includes('/package')){
      setNavActive(5)
    }
    if(location.pathname.includes('/program')){
      setNavActive(6)
    }
    if(location.pathname.includes('/workout')){
      setNavActive(7)
    }
    if(location.pathname.includes('/package/program/my-workout')){
      setNavActive(8)
    } 
    if(location.pathname.includes('/subscription')){
      setNavActive(9)
    } 
    if(location.pathname.includes('/hashtag')){
      setNavActive(10)
    } 
    if(location.pathname.includes('/user/role')){
      setNavActive(11)
    } 
    if(location.pathname.includes('/users')){
      setNavActive(12)
    } 
    if(location.pathname.includes('/subscription/orders')){
      setNavActive(13)
    } 
    if(location.pathname.includes('/terms')){
      setNavActive(14)
    } 
    if(location.pathname.includes('/privacy-policy')){
      setNavActive(16)
    } 
    if(location.pathname.includes('/refund-policy')){
      setNavActive(17)
    } 
    if(location.pathname.includes('/trainers')){
      setNavActive(19)
    } 
    if(location.pathname.includes('/enquiry')){
      setNavActive(20)
    } 
    if(location.pathname.includes('/web-orders')){
      setNavActive(21)
    } 
    if(location.pathname.includes('/blog')){
      setNavActive(22)
    } 
    if(location.pathname.includes('/website-ads')){
      setNavActive(23)
    } 
    if(location.pathname.includes('/website-image')){
      setNavActive(24)
    } 
  }, [navActive,location.pathname]);
  
  return (
    <>
      <div className="sidebar">
        <div className="sidebarWraper ">

        <div className="sidebarMenu">
            <h3 className="sidebarTitle">Roles</h3>
            <ul className="sidebarList">

              <Link to='/user/role'  onClick={()=>setNavActive(11)}>
                <li className={navActive===11?"sidebarItem active":"sidebarItem"}>
                  <BsFillBriefcaseFill className="sidebarIcon" />
                  Roles
                </li>
              </Link>
            </ul>
          </div> 
        <div className="sidebarMenu">
            <h3 className="sidebarTitle">User</h3>
            <ul className="sidebarList">

              <Link to='/users'  onClick={()=>setNavActive(12)}>
                <li className={navActive===12?"sidebarItem active":"sidebarItem"}>
                  <HiUserCircle className="sidebarIcon" />
                  Users
                </li>
              </Link>

              <Link to='/trainers'  onClick={()=>setNavActive(19)}>
                <li className={navActive===19?"sidebarItem active":"sidebarItem"}>
                  <HiUserCircle className="sidebarIcon" />
                  Trainers
                </li>
              </Link>

              <Link to='/push-notification'  onClick={()=>setNavActive(18)}>
                <li className={navActive===18?"sidebarItem active":"sidebarItem"}>
                  <MdNotifications className="sidebarIcon" />
                  Push Notification
                </li>
              </Link>
            </ul>
          </div>

          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Nutrition</h3>
            <ul className="sidebarList">

              <Link to='/nutrition/category'  onClick={()=>setNavActive(1)}>
                <li className={navActive===1?"sidebarItem active":"sidebarItem"}>
                  <MdOutlineCategory className="sidebarIcon" />
                    Category
                </li>
              </Link>

              <Link to='/nutrition/dietplan'  onClick={()=>setNavActive(2)}>
                <li className={navActive===2?"sidebarItem active":"sidebarItem"}>
                <TfiWrite className="sidebarIcon"/>
                  Diet Plan
                </li>
              </Link>

              <Link to='/nutrition/meal'  onClick={()=>setNavActive(3)}>
               <li className={navActive===3?"sidebarItem active":"sidebarItem"}>
                <MdOutlineRestaurant className="sidebarIcon"/>
                  Meal
                </li>
              </Link>
            </ul>
          </div>

          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Session</h3>
            <ul className="sidebarList">

              <Link to='/live-session'  onClick={()=>setNavActive(4)}>
                <li className={navActive===4?"sidebarItem active":"sidebarItem"}>
                  <MdOutlineSensors className="sidebarIcon" />
                    Live-Session
                </li>
              </Link>

              <Link to='/blog'  onClick={()=>setNavActive(22)}>
                <li className={navActive===22?"sidebarItem active":"sidebarItem"}>
                  <FaBlogger className="sidebarIcon" />
                    Blog
                </li>
              </Link>
              <Link to='/website-ads'  onClick={()=>setNavActive(23)}>
                <li className={navActive===23?"sidebarItem active":"sidebarItem"}>
                  <FaBlogger className="sidebarIcon" />
                    Website Ads
                </li>
              </Link>
              <Link to='/website-image'  onClick={()=>setNavActive(24)}>
                <li className={navActive===24?"sidebarItem active":"sidebarItem"}>
                  <BsFillImageFill className="sidebarIcon" />
                    Website Images
                </li>
              </Link>

            </ul>
          </div>

          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Package</h3>
            <ul className="sidebarList">

              <Link to='/package'  onClick={()=>setNavActive(5)}>
                <li className={navActive===5?"sidebarItem active":"sidebarItem"}>
                  <BiPackage className="sidebarIcon" />
                    Package
                </li>
              </Link>

              <Link to='/package/program'  onClick={()=>setNavActive(6)}>
                <li className={navActive===6?"sidebarItem active":"sidebarItem"}>
                  <MdOutlineSensors className="sidebarIcon" />
                  Program
                </li>
              </Link>

              <Link to='/package/program/workout'  onClick={()=>setNavActive(7)}>
                <li className={navActive===7?"sidebarItem active":"sidebarItem"}>
                  <GiWeightLiftingUp className="sidebarIcon" />
                  Workout
                </li>
              </Link>

              {/* <Link to='/package/program/my-workout'  onClick={()=>setNavActive(8)}>
                <li className={navActive===8?"sidebarItem active":"sidebarItem"}>
                  <ImUser className="sidebarIcon" />
                  User Program
                </li>
              </Link> */}
            </ul>
          </div>
          
          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Subscription</h3>
            <ul className="sidebarList">

              <Link to='/subscription'  onClick={()=>setNavActive(9)}>
                <li className={navActive===9?"sidebarItem active":"sidebarItem"}>
                  <MdGroupAdd className="sidebarIcon" />
                    Subscription
                </li>
              </Link>

              <Link to='/subscription/orders'  onClick={()=>setNavActive(13)}>
                <li className={navActive===13?"sidebarItem active":"sidebarItem"}>
                  <MdGroupAdd className="sidebarIcon" />
                    Order
                </li>
              </Link>

              <Link to='/web-orders'  onClick={()=>setNavActive(21)}>
                <li className={navActive===21?"sidebarItem active":"sidebarItem"}>
                  <MdGroupAdd className="sidebarIcon" />
                    Web Orders
                </li>
              </Link>
            </ul>
          </div>

          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Hashtag</h3>
            <ul className="sidebarList">

              <Link to='/hashtag'  onClick={()=>setNavActive(10)}>
                <li className={navActive===10?"sidebarItem active":"sidebarItem"}>
                  <BiHash className="sidebarIcon" />
                  Hashtag
                </li>
              </Link>
            </ul>
          </div>

          <div className="sidebarMenu">
            <h3 className="sidebarTitle">Settings</h3>
            <ul className="sidebarList">
              
              <Link to='/enquiry'  onClick={()=>setNavActive(20)}>
                <li className={navActive===20?"sidebarItem active":"sidebarItem"}>
                  <BiHelpCircle className="sidebarIcon" />
                    Enquiry
                </li>
              </Link>

              <Link to='/terms'  onClick={()=>setNavActive(14)}>
                <li className={navActive===14?"sidebarItem active":"sidebarItem"}>
                  <TfiPencilAlt className="sidebarIcon" />
                    Terms & Conditions
                </li>
              </Link>

              <Link to='/refund-policy'  onClick={()=>setNavActive(17)}>
                <li className={navActive===17?"sidebarItem active":"sidebarItem"}>
                  <MdPolicy className="sidebarIcon" />
                    Refund Policies
                </li>
              </Link>

              <Link to='/privacy-policy'  onClick={()=>setNavActive(16)}>
                <li className={navActive===16?"sidebarItem active":"sidebarItem"}>
                  <MdPolicy className="sidebarIcon" />
                    Privacy Policies
                </li>
              </Link>

              <Link to='/contact-us'  onClick={()=>setNavActive(15)}>
                <li className={navActive===15?"sidebarItem active":"sidebarItem"}>
                  <IoIosCall className="sidebarIcon" />
                    Contact Us
                </li>
              </Link>
            </ul>
          </div>

        </div>
      </div>
    </>
  );
};

export default Sidebar;
