/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect , useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import { GiWeightLiftingUp } from 'react-icons/gi'
import { Player } from 'video-react';
import { useDispatch, useSelector } from 'react-redux';
import { getPackageProgram , UploadMedia , addPackageProgramWorkout, getPackageProgramWorkout, editPackageProgramWorkout } from '../../redux/MainActions';
import swal from 'sweetalert';

const WorkoutAddEdit = () => {
    const param = useParams()
    // console.log("param" , param.workoutId);

    const dispatch = useDispatch()
    // const programID = useSelector(state=> state?.HitFitAdmin?.PackageProgram)
    const data = useSelector(state=> state?.HitFitAdmin?.ProgramWorkout)
    const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
    const ImageData = useSelector(state=>state?.HitFitAdmin?.ImageData)

    // console.log("data" , data);

    const [ workoutData , setWorkoutData ] = useState({
        "titleEn": "",
        "titleAr": "",
        "descriptionEn": "",
        "descriptionAr": "",
        'videoDurationEn':'',
        'videoDurationAr':'',
        "isActive": "true",
        "priority":''
    })  
    const [videoURL, setVideoURL] = useState('')
    const [videoID, setVideoID] = useState('')
    const [mediaName, setMediaName] = useState('')

    const onHandleChange =(e)=>{
        const {name, value} =e.target
        setWorkoutData({...workoutData,[name]:value})
        if(e.target.name === 'programId'){
            setWorkoutData({...workoutData,[name]:parseInt(value)})
        }else if(e.target.name === 'day'){
            setWorkoutData({...workoutData,[name]:parseInt(value)})
        }
    }

     console.log('videoURL',videoURL);

    useEffect(()=>{
        if(param.workoutId){
            getWorkoutList(param.workoutId)
        }
    },[param.workoutId])

    const getWorkoutList = ()=> {
        var data = {
            
        // languageId:LanguageID
        }
        dispatch(getPackageProgramWorkout(data))
    }


    // useEffect(()=> {
    //     getProgramId()
    // },[])

    // const getProgramId = () => {
    //     var data = {
    //         "isActive":true
    //     }
    //     dispatch(getPackageProgram(data))
    // }

    useEffect(() => {
        if(data&&param.workoutId){
            var spr = data.filter(data=>data.id==param.workoutId)
            console.log("spr",spr);
            setWorkoutData({
                titleEn: spr[0]?.titleEn,
                titleAr: spr[0]?.titleAr,
                // titleAr:spr[0]?.titleAr,
                descriptionEn: spr[0]?.descriptionEn,
                descriptionAr: spr[0]?.descriptionAr,
                isActive: spr[0]?.isActive === true ? "true" : "false",
                // ProgramId: spr[0]?.programId,
                videoDurationEn: spr[0]?.videoDurationEn,
                videoDurationAr: spr[0]?.videoDurationAr,
                priority: spr[0]?.priority
                // day: spr[0]?.day,
            })
            setVideoID(spr[0]?.Video?.id)
            setVideoURL(spr[0]?.Video?.url)
        }
    },[data])

    useEffect(() => {
        if(ImageData!==null){
                setVideoID(ImageData?.id)
        }
      }, [ImageData, mediaName])

    const onVideoUrlUpload = (e) =>{
        var file = [videoURL]
        // var url = URL.createObjectURL(e.target.files[0]) 
        // setVideoURL(url)
    
        //  console.log('Videofile',file);
    
        const formData = new FormData()
    
        formData.append('videoUrls',JSON.stringify(file))
    
        dispatch(UploadMedia(formData))
      }

      const onSubmit = (e) =>{
        e.preventDefault()

        if(workoutData.titleEn===''){
            swal({
              title: 'Error!',
              text: "Please Add Title En!",
              icon: "warning",
              button: "Ok",
            });
            return false
          }else if(workoutData.titleAr===''){
            swal({
              title: 'Error!',
              text: "Please Add Title Ar!",
              icon: "warning",
              button: "Ok",
            });
            return false
          }else if(workoutData.descriptionEn===''){
            swal({
              title: 'Error!',
              text: "Please Add Description En!",
              icon: "warning",
              button: "Ok",
            });
            return false
          }else if(workoutData.descriptionAr===''){
            swal({
              title: 'Error!',
              text: "Please Add Description Ar!",
              icon: "warning",
              button: "Ok",
            });
            return false
          } else if(workoutData.videoDurationAr===''){
            swal({
              title: 'Error!',
              text: "Please Add Video Duration En!",
              icon: "warning",
              button: "Ok",
            })
            return false
          }else if(videoID===''&& videoID===null){
            swal({
              title: 'Error',
              text: "Please Add Vimeo Link!",
              icon: "warning",
              button: "Ok",
            });
            return false
          }

        if(param.workoutId){
            var data = {
                id: parseInt(param.workoutId),
                titleEn: workoutData.titleEn,
                titleAr: workoutData.titleAr,
                isActive: workoutData.isActive === 'true'? true:false,
                descriptionEn: workoutData.descriptionEn,
                descriptionAr: workoutData.descriptionAr,
                videoDurationEn: workoutData.videoDurationEn,
                videoDurationAr: workoutData.videoDurationAr,
                videoId: videoID,
                priority:  parseInt(workoutData.priority)
              }
                dispatch(editPackageProgramWorkout(data))
             console.log("data", data);
        }else{
            var data = {
                titleEn: workoutData.titleEn,
                titleAr: workoutData.titleAr,
                descriptionEn: workoutData.descriptionEn,
                descriptionAr: workoutData.descriptionAr,
                videoDurationEn: workoutData.videoDurationEn,
                videoDurationAr: workoutData.videoDurationAr,
                videoId: videoID,
                priority:  parseInt(workoutData.priority)
              }
              dispatch(addPackageProgramWorkout(data))
            //   console.log("data" , data);
        }
        
      }


      console.log('videoID',videoID);

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
            <span>
                <Link to='/health'><span><GiWeightLiftingUp style={{color:"#CE7777"}}/></span>Workout </Link>/
                <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined"><GiWeightLiftingUp style={{color:"#CE7777"}}/></span>{param.workoutId? "Edit": "Add"} Workout</h3>
            <div>
                <Link to='/package/program/workout'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
            <form  onSubmit={onSubmit} >
                <div className="InputF">
                    <div className="inputField_type_one">
                        <label htmlFor='title'>Title (En)</label>
                        <input type="text" id='title' name='titleEn' placeholder='E.g: packageTitle123' value={workoutData.titleEn} onChange={onHandleChange} />
                       
                        <label htmlFor='videoDuration'>Video-Duration (En)</label>
                        <input type="text" id='videoDuration' name='videoDurationEn' placeholder='E.g: videoDuration' value={workoutData.videoDurationEn} onChange={onHandleChange} />

                        <label htmlFor="description">Description (En)</label>
                        <textarea rows={3}  type="text" name='descriptionEn' placeholder='e.g: Ketogenic diet is very low carb high-fat diet' value={workoutData.descriptionEn} onChange={onHandleChange} />    

                        <label htmlFor='isActive'>Status</label>
                        <select name="isActive" id='isActive' value={workoutData.isActive} onChange={onHandleChange}  >
                            <option value=''>-Select Status-</option>
                            <option value='true'>Active</option>
                            <option value='false'>In-Active</option>
                        </select>

                       

                       
                    </div>
                    <div className="inputField_type_one">
                        {/* <label htmlFor='titleAr'>Title-Ar</label>
                        <input type="text" id='titleAr' name='titleAr' value={workoutData.titleAr} onChange={onHandleChange} placeholder='E.g: packageTitleAr123'/> */}

                        {/* <label htmlFor='day'>Day</label>
                        <input type="text" id='day' name='day' placeholder=' E.g : 1' value={workoutData.day} onChange={onHandleChange} /> */}
                        
                        <label htmlFor='titleAr'>Title (Ar)</label>
                        <input type="text" id='titleAr' name='titleAr' placeholder='E.g: packageTitle123' value={workoutData.titleAr} onChange={onHandleChange} />

                        <label htmlFor='videoDurationAr'>Video-Duration (Ar)</label>
                        <input type="text" id='videoDurationAr' name='videoDurationAr' placeholder='E.g: videoDuration' value={workoutData.videoDurationAr} onChange={onHandleChange} />
                        {/* <label>Program</label>
                        <select name="programId" value={workoutData.programId} onChange={onHandleChange} >
                            <option>- Select Category -</option>
                            {programID.length>0?programID.map((cate,index)=>(
                                <option value={cate.id} key={index}>{cate.title}</option>
                            )):null}
                        </select> */}
                        <label htmlFor="descriptionAr">Description (Ar)</label>
                        <textarea rows={3} id='descriptionAr' type="text" name='descriptionAr' placeholder='e.g: Ketogenic diet is very low carb high-fat diet' value={workoutData.descriptionAr} onChange={onHandleChange} />

                        <label htmlFor='priority'>Priority</label>
                        <input type="number" id='priority' name='priority' placeholder='E.g: 123' value={workoutData.priority} onChange={onHandleChange} />
                    </div>
                </div>

                <div className="MediaUploads">
                <div className="videoURL">
                    <div className="addVideo">
                     <input type="text" placeholder='submit video urls' value={videoURL} onChange={(e)=> setVideoURL(e.target.value)}/>
                     <span name='Video' onClick={onVideoUrlUpload}>Save Url</span>
                    </div>
            </div>
                </div>
                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span> {param.workoutId? "Update": "Save"}</button>
                    <Link to='/package/program/workout'>Cancel</Link>
                </div>
            </form>
        </div>
    </div>
  )
}

export default WorkoutAddEdit