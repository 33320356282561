import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { TfiPencilAlt } from "react-icons/tfi";
import { Link } from 'react-router-dom'
import { addTerms, getTerms } from '../../redux/MainActions';

const TermsAddEdit = () => {
  const dispatch = useDispatch()
  const termstData = useSelector(state=> state?.HitFitAdmin?.Terms)
  const [termEn , setTerm ] = useState({
    termsAndConditionsEn:null
  });
  const [termAr , setTermAr ] = useState({
    termsAndConditionsAr:null
  });

  const  handleChangeEn = (value) => {
        setTerm({termsAndConditionsEn:value});
  };

  const  handleChangeAr = (value) => {
        setTermAr({termsAndConditionsAr:value});
  };

  useEffect(()=>{
    getTermList()
  },[])

  const getTermList = () => {
    var data ={
    }
    dispatch(getTerms(data))
  }

  useEffect(()=>{
    if(termstData){
      var spr = termstData
      console.log("spr",spr);
      setTerm({
        termsAndConditionsEn: spr[0]?.termsAndConditions?.termsAndConditionsEn
      })
      setTermAr({
        termsAndConditionsAr: spr[0]?.termsAndConditions?.termsAndConditionsAr
      })
    }
  },[termstData])

  const onSubmit =(e) => {
    e.preventDefault()
    var data = {
      termsAndConditions: {
        termsAndConditionsEn: termEn.termsAndConditionsEn,
        termsAndConditionsAr: termAr.termsAndConditionsAr
      }
    }
    dispatch(addTerms(data))
    console.log("data",data);
  }
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}><TfiPencilAlt/></span>Terms & Conditions</Link>/
              <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}><TfiPencilAlt/></span>Terms & Conditions</h3>
          <div>
              <Link to='/live-session/create'>
                  Back
              </Link>
          </div>
      </div>

      <div className="Editoe_form">
              <form onSubmit={onSubmit}>
                    <div className='terms'>
                      <label htmlFor="terms">Terms & Conditions (En)</label>
                      <div className='terms_data'  >
                        <ReactQuill
                          name = "tandc"
                          value={termEn.termsAndConditionsEn}
                          onChange={handleChangeEn}
                          className='quill'
                        />
                      </div>
                    </div>
              </form>

              <hr style={{width:"96%" , border: "2px solid #DDDDDD ", borderRadius: "5px", marginLeft:"2%", marginRight:"2%", marginTop:"30px"}}/>
              <form onSubmit={onSubmit}>
                    <div className='terms'>
                      <label htmlFor="terms">Terms & Conditions (Ar)</label>
                      <div className='terms_data'  >
                        <ReactQuill
                          name = "tandc"
                          value={termAr.termsAndConditionsAr}
                          onChange={handleChangeAr}
                          className='quill'
                        />
                      </div>
                    </div>
                    <div className="btn">
                        <button type='submit'><span className="material-icons-outlined">save</span>Save</button>
                        <Link to='/nutrition/category'>Cancel</Link>
                    </div>
              </form>
      </div>
    </div>
  )
}

export default TermsAddEdit