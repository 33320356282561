/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState ,useEffect} from 'react'
import { useDispatch , useSelector} from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { UploadMediaMulti, AddHashtag, UploadMedia, getHashtag, editHashtag  } from '../../redux/MainActions'
import { BiHash } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import swal from 'sweetalert';

const HashtagAddEdit = () => {

  const param = useParams()

  const dispatch = useDispatch()
  const [ hashTag , setHashTag ] = useState({
      hashtag:'',
      hashtagAr:"",
      isActive:'true',
      priority:''
  })
  

  const ImageDataMulti = useSelector(state=>state?.HitFitAdmin?.ImageDataMulti)
  const HashtagList = useSelector(state=>state?.HitFitAdmin?.HashTAg)


  const [imgURL, setImgURL] = useState([])
  const [imgData, setImgData] = useState([])
  const [ priority , setPriority ] = useState([])

  const [submitting, setSubmitting] = useState(false)

  // const [mediaIDs, setMediaIDs] = useState([])

  console.log("param" , HashtagList);

  const [medias, setMedias] = useState([])

  console.log("ImageDataMulti",ImageDataMulti);

  const onHandleChange = (e) => {
    const { value , name } = e.target
    setHashTag({ ...hashTag,[name]:value }) 
  }

  const handlePriority = (e,idx) =>{
    const { name, value } = e.target;
    const list = [...medias];
    list[idx][name] = parseInt(value);
    setMedias(list);
  }

  const onImageChange = (e) =>{
    var formData = new FormData()

    var files = e.target.files
    // var url = URL.createObjectURL(e.target.files[0])
    for (let i = 0; i < files.length; i++) {
      var file = e.target.files[i]
      formData.append('medias',file)
    }

    
    dispatch(UploadMediaMulti(formData))
  }

  useEffect(()=>{
    if(param.hashtagId){
      gethashtagList(param.hashtagId)
    }
  },[param.hashtagId])

  const gethashtagList = (id) => {
    var data = {
      // "hashtagId":id,
    }
    dispatch(getHashtag(data))
  }
  
  useEffect(() => {
    if(ImageDataMulti.length>0){
      var data = ImageDataMulti?.map((image,index)=>{
        return {
          mediaId:image?.id,
          priority:null,
          imgUrl:image?.url,
        }
      })
      setMedias([...medias,...data])
    }
  }, [ImageDataMulti])
  

  useEffect(()=>{
    if(HashtagList&&param.hashtagId){
      var spr = HashtagList.filter(data=>data.id==param.hashtagId)
      console.log("spr ", spr);
      setHashTag({
        hashtag: spr[0]?.hashtag,
        isActive: spr[0]?.isActive?"true":"false",
        priority:spr[0]?.priority,
        hashtagAr:spr[0]?.hashtagAr
      })
      var modData = spr[0]?.medias?.map((med,index)=>{
        return {
          mediaId:med?.id,
          priority:med?.priority,
          imgUrl:med?.url,
        }
      })
      setMedias(modData)

    }
  },[HashtagList,param])

  const deleteImage = (index) => {
    let Files = [...medias];
      Files.splice(index, 1);
      setMedias(Files)   
  };


  const onSubmit = (e) => {
    e.preventDefault()
    if(hashTag.hashtag==''){
      swal({
        title: 'Error!',
        text: "Please Add Title!",
        icon: "warning",
        button: "Ok",
      })
      return false
    }else if(hashTag.hashtagAr==''){
      swal({
        title: 'Error!',
        text: "Please Add Title Arabic!",
        icon: "warning",
        button: "Ok",
      })
      return false
    }else if(hashTag.priority==null && hashTag.priority==""){
      swal({
        title: 'Error!',
        text: "Please Priority!",
        icon: "warning",
        button: "Ok",
      })
      return false
    }


    let final = medias.map(data=>{
      return {
          mediaId:data.mediaId,
          priority:data.priority
      }
    })

    if(param.hashtagId){
      var data = {
        id: parseInt(param.hashtagId),
        hashtag: hashTag?.hashtag,
        isActive: hashTag?.isActive == "true" ? true : false,
        medias: final,
        hashtagAr:hashTag?.hashtagAr,
        priority:parseInt(hashTag.priority)
      }
      dispatch(editHashtag(data))
      console.log("EDIT",data);
    }else {
      var data = {
        hashtag: hashTag?.hashtag,
        medias: final,
        hashtagAr:hashTag?.hashtagAr,
        // isActive: hashTag?.isActive == "true" ? true : false
        priority: parseInt(hashTag.priority)
      }
  
      console.log("data",data);
      dispatch(AddHashtag(data))
    }
    
  }

  // console.log('MediaData',MediaData);
  console.log('medias',medias);
  // console.log('imgData',imgData);

  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
        <span>
            <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}><BiHash/></span>Hashtag </Link>/
            <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
        </span>
     </div>
    
    <div className="Header">
         <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}><BiHash/></span>Add Hashtag</h3>
        <div>
            <Link to='/hashtag'>
                Back
            </Link>
        </div>
    </div>

    <div className="Box_Container">
        <form onSubmit={onSubmit}>
            <div className="InputF">
                <div className="inputField_type_one">
                    <label htmlFor='hashtag'>Hashtag</label>
                    <input type="text" name='hashtag' id='hashtag' placeholder='ex: fasting Diet...' value={hashTag.hashtag} onChange={onHandleChange} />

                    <label htmlFor='priority'>Priority</label>
                    <input type="number" name='priority' id='priority' placeholder='ex: 123' value={hashTag.priority} onChange={onHandleChange} />
                </div>
                <div className="isActive">
                  <label htmlFor='hashtagAr'>Hashtag (Ar)</label>
                    <input type="text" name='hashtagAr' id='hashtagAr' placeholder='ex: fasting Diet...' value={hashTag.hashtagAr} onChange={onHandleChange} />

                    <label>Status</label>
                    <select name="isActive" value={hashTag.isActive} onChange={onHandleChange} >
                        <option value=''>Select Status</option>
                        <option value='true'>Active</option>
                        <option value='false'>InActive</option>
                    </select>
                </div>
            </div>
            <div className='MediaUploads'>
                <div className="imgUploadMulti">
                    <div className='imgContainerMulti'>
                      {medias?.map((med,idx)=>{
                        return <div className='imgBlock'>
                          <img src={med?.imgUrl} alt=""  key={idx} />
                          <GrFormClose onClick={()=>deleteImage(idx)}/>
                          <input type="number" onChange={(e)=>handlePriority(e,idx)} name='priority' value={med?.priority} placeholder= "select priority"/>
                        </div>
                      })}
                        {imgURL.length==0&&<p>Please Select Image!</p>}
                    </div>
                    <label htmlFor="imgButton">Select Media</label>
                    <input multiple onChange={onImageChange} type="file" name="Image" id="imgButton"   accept="image/png, image/gif, image/jpeg"/>
                </div>
              </div>
            <div className="btn">
                <button type='submit'><span className="material-icons-outlined">save</span>{param.hashtagId? "Update": "Save"}</button>
                <Link to='/hashtag'>Cancel</Link>
            </div>
        </form>
    </div>
</div>
  )
}

export default HashtagAddEdit