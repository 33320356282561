/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import { BiHash } from "react-icons/bi";
import Toggle from 'react-toggle'
import { useDispatch, useSelector } from 'react-redux'
import { deleteHashtag, editHashtag, getHashtag } from '../../redux/MainActions'

const Hashtag = () => {
    const dispatch = useDispatch()
    const HashtagList = useSelector(state=>state?.HitFitAdmin?.HashTAg)


    const [ HASHTAG , setHASHTAG ] = useState([])

      console.log("HASHTAG" ,HASHTAG);

    useEffect(()=>{
        getHashtagList()
    },[])

    const getHashtagList = () => {
        var data = {
            // "isActive":true
        }
        dispatch(getHashtag(data))
    }
 
    useEffect(()=>{
        if(HashtagList){
            setHASHTAG(HashtagList)
        }
    },[HashtagList]) 

    const onStatusChange = (isActive, ID)=> {
      console.log("error", ID);
      var data= {
        "id": ID,
        "isActive": !isActive
      }
      dispatch(editHashtag(data))
    }

    const handleDelete = (id)=>{
      if(id){
        swal({
          title: "Are you sure?",
          text: "Once deleted,you will not be able to recover this Data!",
          icon: "warning",
          dangerMode: true,
          buttons: true,
        })
        .then(willDelete => {
          if (willDelete) {
            var data = {
                "hashtagId": id
               }
               dispatch(deleteHashtag(data))
              //  console.log("deleteID", data);
          }
        });
      }
    }

    const columns = [
      {
        name:"medias",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: ()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Image</span>
          },
          customBodyRender:(medias)=>{
            return<div className='tableIMG'>
              <img src={medias[0]?.url} style={{width:"3vw"}} alt='No Image here!'/>
            </div>
          }
        }
      },
        {
            name: "hashtag",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Title (En)</span>
              }
            },
          },
        {
            name: "hashtagAr",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Title (Ar)</span>
              }
            },
          },
          {
            name: "isActive",
              options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Status</span>
                },
                customBodyRender:(isActive,tableMeta)=>{
                  var ID = tableMeta?.rowData[4]
                  return(
                      <div>
                      <Toggle
                          defaultChecked={isActive}
                          onChange={()=>onStatusChange(isActive, ID)}
                           />
                      </div>
                  )
              }
              },
            },
            {
              name: "id",
              options: {
                filter: true,
                sort: true,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Action</span>
                },
                customBodyRender:(id)=>{
                  return(
                      <div>
                        <Link className='editBTN' to={'/hashtag/'+id}>
                          <button>Edit</button>
                        </Link>
                      </div>
                  )
              }
              },
            },
            {
              name: "id",
              options: {
                filter: true,
                sort: true,
                // display:false,
                customHeadLabelRender:()=>{
                  return<span style={{
                    letterSpacing:'0',
                    fontWeight:'600'
                  }}>Action</span>
                },
                customBodyRender:(id)=>{
                  return(
                      <div className='deleteBTN'>
                          <button onClick={()=>handleDelete(id)}>Delete</button>
                      </div>
                  )
              }
              },
            },
    ];

    const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><BiHash style={{color:"#CE7777"}}/></span>Hashtag</Link>/
              <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><BiHash style={{color:"#CE7777"}}/></span>Hashtag</h3>
          <div>
              <Link to='/hashtag/add'>
                  ADD
              </Link>
          </div>
      </div>

      <MUIDataTable
          className="table-responsive"
          data={HASHTAG?HASHTAG:[]}
          columns={columns}
          options={options}
      />
    </div>
  )
}

export default Hashtag