/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import { getUserRole } from '../../redux/MainActions'
import { Link } from 'react-router-dom'
import { BsFillBriefcaseFill } from 'react-icons/bs'
import Toggle from 'react-toggle'

const Role = () => {

    const dispatch = useDispatch()
    const userRoleList = useSelector(state=> state?.HitFitAdmin?.Role)

    const [ ROLE , setRole ] = useState([])

    console.log("ROLE" ,ROLE);
    useEffect(()=>{
        getRoleList()
    },[])

    const getRoleList = () =>{
        var data = {
            // "status":true
        }
        dispatch(getUserRole(data))
    }

    useEffect(()=>{
      if(userRoleList){
        setRole(userRoleList)
      }
    },[userRoleList])


    const columns = [
      {
        name: "type",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Type</span>
          }
        },
      },
      {
        name: "createdAt",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Created Date</span>
            },
            customBodyRender:(createdAt)=>{
              let date = new Date(Date.parse(createdAt))
              let year = date.getFullYear()
              let month = date.getMonth()+1
              let day = date.getDate()
              // console.log(`${day}/${month}/${year}`);
              return<div >
               {`${day}/${month}/${year}`}
              </div>
            }
          },
        },
      {
        name: "status",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Status</span>
            },
            customBodyRender:(status)=>{
              return(
                  <div>
                  <Toggle
                      checked={status}
                      // onChange={onStatusChange}
                       />
                  </div>
              )
          }
          },
        },
        {
          name: "id",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Action</span>
            },
            customBodyRender:(id)=>{
              return(
                  <div>
                    <Link className='editBTN' to={'/user/role/'+ id}>
                      <button>Edit</button>
                    </Link>
                  </div>
              )
          }
          },
        },
  ];

  const options = {
    filterType: "dropdown",
    search:false,
    filter:false,
    viewColumns: false,
    print: false,
    pagination:true,
    download: false,
    selectableRows: "none",
    // responsive: 'scrollMaxHeight',
};
  
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><BsFillBriefcaseFill style={{color:"#CE7777"}}/></span>Roles</Link>/
              <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><BsFillBriefcaseFill style={{color:"#CE7777"}}/></span>Roles</h3>
          <div>
              <Link to='/user/role/add'>
                  ADD
              </Link>
          </div>
      </div>

      <MUIDataTable
          className="table-responsive"
          data={ROLE?ROLE:[]}
          columns={columns}
          options={options}
      />
    </div>
  )
}

export default Role