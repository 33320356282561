/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { addNutritionCategory , getNutritionCategoryList, editNutritionCatrgory,getLanguage} from '../../redux/MainActions'
import swal from "sweetalert";

const NutritionCateAddEdit = () => {
  const param = useParams()
  // console.log(param);
  const dispatch = useDispatch()
  const [ADDCATEGORY , setADDCATEGORY ] = useState({
    titleEn:'',
    titleAr:'',
    priority: '',
    // languageId:null,
    isActive:'true'
  })
  const nutritionCategory = useSelector(state=>state.HitFitAdmin.NutritionCategory)
  // const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
  // console.log("nutritionCategory",nutritionCategory);

  useEffect(() => {
   if(param.categoryId){
    getCetegory(param.categoryId)
   }
},  [param.categoryId])

const getCetegory =()=>{
    var data = {
        // "categoryId":id,
    }
    dispatch(getNutritionCategoryList(data))
}

useEffect(()=>{
  getLanguageData()
},[])

const getLanguageData = () => {
  dispatch(getLanguage())
}

useEffect(()=>{
  if(nutritionCategory&&param.categoryId){
    var spr = nutritionCategory.filter(data=>data.id==param.categoryId)
    console.log('spr',spr);
    setADDCATEGORY({
      titleEn:spr[0]?.titleEn,
      titleAr:spr[0]?.titleAr,
      priority:spr[0]?.priority,

      isActive:spr[0]?.isActive? "true":'false'  
    })
    console.log("spr",spr);
  }
},[nutritionCategory])


// console.log("EditItem" , ADDCATEGORY );

  const onHandleChange = (e) => {
    const { value , name } = e.target
    setADDCATEGORY({ ...ADDCATEGORY,[name]:value }) 
  }

  const onSubmit = (e) => {
    e.preventDefault()
    
    if(ADDCATEGORY.titleEn===''){
      swal({
        title: 'Error!',
        text: "Please Add Title En!",
        icon: "warning",
        button: "Ok",
      });
      return false
    } else if(ADDCATEGORY.titleAr===''){
      swal({
        title: 'Error!',
        text: "Please Add Title Ar!",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else if(ADDCATEGORY.isActive===''){
      swal({
        title: 'Error!',
        text: "Please select status!",
        icon: "warning",
        button: "Ok",
      });
      return false
    }


    if(param.categoryId){
      var data = {
        categoryId:param.categoryId,
        titleEn:ADDCATEGORY?.titleEn,
        titleAr:ADDCATEGORY.titleAr,
        priority: parseInt(ADDCATEGORY.priority),
        // languageId: LanguageID,
        isActive:ADDCATEGORY.isActive == 'true'?true:false
      }
      dispatch(editNutritionCatrgory(data))
    }else{
      var data = {
        titleEn:ADDCATEGORY.titleEn,
        titleAr:ADDCATEGORY.titleAr,
        priority: parseInt(ADDCATEGORY.priority),
        // languageId: LanguageID,
        // isActive:ADDCATEGORY.isActive == 'true'?true:false
      }
      console.log("DATA", data);
      dispatch(addNutritionCategory(data))  
    }
    setADDCATEGORY({
      title:'',
      isActive:'true'
    })
  }
 
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>category</span>Nutrition Category {param?.categoryId?'Edit':'Add'}</Link>/
                <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>category</span>Nutrition Category {param?.categoryId?'Edit':'Add'}</h3>
            <div>
                <Link to='/nutrition/category'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
            <form onSubmit={onSubmit}>
                <div className="InputF">
                    <div className="inputField_type_one">
                        <label htmlFor='titleEn'>Title (En)</label>
                        <input type="text" name='titleEn' placeholder='ex: fasting Diet...' value={ADDCATEGORY.titleEn} onChange={onHandleChange} />
                        
                        <label htmlFor='titleAr'>Title (Ar)</label>
                        <input type="text" name='titleAr' placeholder='ex: fasting Diet...' value={ADDCATEGORY.titleAr} onChange={onHandleChange} />
                        {/* <label htmlFor='languageId'>Language</label>
                        <select name='languageId' value={ADDCATEGORY.languageId} onChange={onHandleChange} >
                            <option>- Select Langauge -</option>
                            {Language?.length>0?Language?.map((lang, index)=>(
                                <option value={lang.id} key={ index}>{lang.name}</option>
                            )):null}        
                        </select> */}
                    </div>
                    <div className="isActive">
                        <label>Status</label>
                        <select name="isActive" value={ADDCATEGORY.isActive} onChange={onHandleChange} >
                            <option value='true'>Active</option>
                            <option value='false'>InActive</option>
                        </select>

                        <label htmlFor='priority'>Priority</label>
                        <input type="number" name='priority' placeholder='ex: 1,2' value={ADDCATEGORY.priority} onChange={onHandleChange} />
                    </div>
                </div>
                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span>{param.categoryId? "Update": "Save"}</button>
                    <Link to='/nutrition/category'>Cancel</Link>
                </div>
            </form>
        </div>
    </div>
  )
}

export default NutritionCateAddEdit