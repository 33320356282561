// USERS TYPES
export const USER_LOGIN_DETAILS = 'USER_LOGIN_DETAILS';
export const USER_SIGNUP = 'USER_SIGNUP';


//GET ROLES
export const GET_USER_ROLE = 'GET_USER_ROLE';

//GET USERLIST 
export const GET_USERS_LIST = 'GET_USERS_LIST';

export const GET_USER_DETAIL = "GET_USER_DETAIL"

//  SEND NOTIFICATION
export const SEND_NOTIFICATION = "SEND_NOTIFICATION"

//GET ADMIN SUBSCRIPTION HISTORY
export const GET_ADMIN_HISTORY ='GET_ADMIN_HISTORY';

// NUTRITIONS TYPES
export const GET_NUTRITION_CATEGORY = 'GET_NUTRITION_CATEGORY';

// ADD NUTRITION CATEGORY 
// export const ADD_NUTRITION_CATEGORY = 'ADD_NUTRITION_CATEGORY';

// NUTRITION GET DIET LIST
export const GET_NUTRITION_DIETPLAN =  'GET_NUTRITION_DIETPLAN';

// NUTRITION GET MEALS LIST
export const GET_NUTRITION_MEAL = 'GET_NUTRITION_MEAL';

// GET SESSION
export const GET_SESSIONS = 'GET_SESSIONS'

// GET PACKAGE Type
export const GET_PACKAGE = 'GET_PACKAGE';

// get PACKAGE PRGRAM
export const GET_PACKAGE_PROGRAM = "GET_PACKAGE_PROGRAM";

// GET PROGRAM WORKOUT
export const GET_PROGRAM_WORKOUT = "GET_PROGRAM_WORKOUT";

// GET USER PROGRAM
export const GET_USER_PROGRAM = 'GET_USER_PROGRAM';

// GET SUBSCRITPTON LIST
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION' ;

//  GET USER SUBSCRIPTION
export const  GET_USER_SUBSCRIPTION ='GET_USER_SUBSCRIPTION'
export const  GET_SUBSCRIPTION_FOR_USER ='GET_SUBSCRIPTION_FOR_USER'
export const  ASSIGN_SUBSCRIPTION_TO_USER ='ASSIGN_SUBSCRIPTION_TO_USER'

//GET HASHTAG
export const GET_HASHTAG = 'GET_HASHTAG';

//GET CONTACT US
export const GET_CONTACT_US = "GET_CONTACT_US"

//GET TERMS CONDITION LIST
export const GET_TERMS = "GET_TERMS"

//GEt PRIVACY PLICY
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY'

//GET REFUND POLICY
export const GET_REFUND_POLICY = "GET_REFUND_POLICY"

// GET LANGUAGES
export const GET_LANGUAGE = "GET_LANGUAGE"

export const SET_LANGUAGE_ID = "SET_LANGUAGE_ID"

// Image Upload
export const GET_IMAGE_DATA = 'GET_IMAGE_DATA';
export const GET_MULTIPLE_MEDIA_DATA = 'GET_MULTIPLE_MEDIA_DATA';

//ENQUIRY
export const GET_ENQUIRY_LIST = "GET_ENQUIRY_LIST"

// WEB ORDERS
export const GET_WEB_ORDERS = "GET_WEB_ORDERS" ;

// Blog For Website
export const GET_BLOG = "GET_BLOG"

// ADS
export const GET_ADS = "GET_ADS"

// get images for Website 
export const GET_WEB_IMAGE = "GET_WEB_IMAGE" 
