import { 
    USER_LOGIN_DETAILS,
    GET_NUTRITION_CATEGORY,
    GET_NUTRITION_DIETPLAN,
    GET_NUTRITION_MEAL,
    GET_IMAGE_DATA,
    GET_PACKAGE,
    GET_PACKAGE_PROGRAM,
    GET_PROGRAM_WORKOUT,
    GET_USER_PROGRAM,
    GET_SUBSCRIPTION,
    GET_HASHTAG,
    GET_SESSIONS,
    GET_MULTIPLE_MEDIA_DATA,
    GET_USER_ROLE,
    GET_USERS_LIST,
    GET_ADMIN_HISTORY,
    GET_CONTACT_US,
    GET_TERMS,
    GET_PRIVACY_POLICY,
    GET_REFUND_POLICY,
    GET_LANGUAGE,
    SET_LANGUAGE_ID,
    GET_USER_DETAIL,
    SEND_NOTIFICATION,
    GET_USER_SUBSCRIPTION,
    GET_ENQUIRY_LIST,
    GET_WEB_ORDERS,
    GET_BLOG,
    GET_ADS,
    GET_WEB_IMAGE,
    USER_SIGNUP,
    GET_SUBSCRIPTION_FOR_USER,
    ASSIGN_SUBSCRIPTION_TO_USER

} from "./types";

const initialState = {
    LoginData: {},
    Role:[],
    User:[],
    AdminHistory:[],
    NutritionCategory: [],
    NutirtionDietPlan:[],
    NutritionMeal:[],
    Session:[],
    Package:[],
    PackageProgram:[],
    ProgramWorkout:[],
    UserProgram:[],
    SubsCription:[],
    HashTAg:[],
    ImageData:{},
    ImageDataMulti:[],
    ContactUs : [],
    Terms: [],
    PrivacyPolicy: [],
    RefundPolicy:[],
    Langauge:[],
    LanguageID: 1,
    userDetail:[],
    Notification:[],
    UserSubsCription:[],
    Enquiry:[],
    weborders:[],
    blogs:[],
    ads:[],
    webImg:[],
    SignupData:{},
    subscriptionForUser:[],
    assignSubscription:{}
};

const mainReducer = (state = initialState, action)=>{
    switch(action.type){
        case USER_LOGIN_DETAILS:
        return {
            ...state,
            LoginData:action.payload
        }
        case USER_SIGNUP:
        return {
            ...state,
            SignupData:action.payload
        }
        case GET_NUTRITION_CATEGORY:
        return {
            ...state,
            NutritionCategory:action.payload
        }
        case GET_NUTRITION_DIETPLAN:
        return {
            ...state,
            NutirtionDietPlan:action.payload
        }
        case GET_NUTRITION_MEAL:
        return {
            ...state,
            NutritionMeal:action.payload
        }
        case GET_IMAGE_DATA:
        return {
            ...state,
            ImageData:action.payload
        }
        case GET_MULTIPLE_MEDIA_DATA:
        return {
            ...state,
            ImageDataMulti:action.payload
        }
        case GET_PACKAGE:
        return {
            ...state,
            Package:action.payload
        }
        case GET_PACKAGE_PROGRAM:
        return {
            ...state,
            PackageProgram:action.payload
        }
        case GET_PROGRAM_WORKOUT:
        return {
            ...state,
            ProgramWorkout: action.payload
        }
        case GET_USER_PROGRAM:
        return {
            ...state,
            UserProgram: action.payload
        }
        case GET_SUBSCRIPTION:
        return {
            ...state,
            SubsCription: action.payload
        }
        case GET_HASHTAG:
        return {
            ...state,
            HashTAg: action.payload
        }
        case GET_SESSIONS:
        return {
            ...state,
            Session: action.payload
        }
        case GET_USER_ROLE:
        return {
            ...state,
            Role: action.payload
        }
        case GET_USERS_LIST:
        return {
            ...state,
            User: action.payload
        }
        case GET_ADMIN_HISTORY:
        return {
            ...state,
            AdminHistory: action.payload
        }
        case GET_CONTACT_US:
        return {
            ...state,
            ContactUs: action.payload
        }
        case GET_TERMS:
        return {
            ...state,
            Terms: action.payload
        }
        case GET_PRIVACY_POLICY:
        return {
            ...state,
            PrivacyPolicy : action.payload
        }
        case GET_REFUND_POLICY:
        return {
            ...state,
            RefundPolicy : action.payload
        }
        case GET_LANGUAGE:
        return {
            ...state,
            Langauge : action.payload
        }
        case SET_LANGUAGE_ID:
        return {
            ...state,
            LanguageID : action.payload
        }
        case GET_USER_DETAIL:
        return {
            ...state,
            userDetail : action.payload
        }
        case SEND_NOTIFICATION:
        return {
            ...state,
            Notification : action.payload
        }
        case GET_USER_SUBSCRIPTION:
        return {
            ...state,
            UserSubsCription : action.payload
        }
        case GET_ENQUIRY_LIST:
        return {
            ...state,
            Enquiry : action.payload
        }
        case GET_WEB_ORDERS:
        return {
            ...state,
            weborders : action.payload
        }
        case GET_BLOG:
        return {
            ...state,
            blogs : action.payload
        }
        case GET_ADS:
        return {
            ...state,
            ads : action.payload
        }
        case GET_WEB_IMAGE:
        return {
            ...state,
            webImg : action.payload
        }
        case GET_SUBSCRIPTION_FOR_USER:
        return {
            ...state,
            subscriptionForUser : action.payload
        }
        case ASSIGN_SUBSCRIPTION_TO_USER:
        return {
            ...state,
            assignSubscription : action.payload
        }
        default:
        return state;
    }   
}

export default mainReducer