import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import { HiUserCircle } from 'react-icons/hi'
// import Toggle from 'react-toggle'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editUserStatus, getUser } from '../../redux/MainActions'
import Toggle from 'react-toggle'

const Trainers = () => {
    const dispatch =useDispatch()
    const UserList = useSelector(state=>state?.HitFitAdmin?.User)
    const [ TRAINER , setTRAINER ] = useState([])

    console.log("UserList",UserList);

    useEffect(()=>{
        getUserList()
      },[])
    
    const getUserList = () =>{
        var data = {
           "status": true
        }
        dispatch(getUser(data))
    } 

    useEffect(()=>{
      if(UserList){
        var spr = UserList?.filter(data=>data.roleId == 2)
        console.log("spr",spr);
        setTRAINER(spr)
      }
    },[UserList])

    const onStatusChange = (status,userID) =>{
      var data = {
        "userId":userID,
        "status":!status
    }
    dispatch(editUserStatus(data))
  }
    const columns = [
    {
      name:"imageUrl",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: ()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Image</span>
        },
        customBodyRender:(imageUrl)=>{
          return<div className='tableIMG1'>
            <img src={imageUrl?imageUrl:'./new1.png'}/>
          </div>
        }
      }
    },
    {
      name: "fullName",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Name</span>
        }
      },
    },
    {
      name: "email",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Email</span>
        }
      },
    },
    {
      name: "phone",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Phone No</span>
        }
      },
    },
    {
      name: "createdAt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Created Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(Date.parse(createdAt))
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            // console.log(`${day}/${month}/${year}`);
            return<div >
             {`${day}/${month}/${year}`}
            </div>
          }
        },
      },
    {
      name: "status",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Status</span>
          },
          customBodyRender:(status,tableMeta)=>{
            var userID = tableMeta?.rowData[6]
            return(
                <div>
                  <Toggle
                      checked={status}
                      onChange={()=>onStatusChange(status,userID)}
                  />
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Action</span>
          },
          customBodyRender:(id)=>{
            return(
                <div>
                  <Link className='editBTN' to={'/user/'+id}>
                    <button>Edit</button>
                  </Link>
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>User Info</span>
          },
          customBodyRender:(id)=>{
            return(
                <div>
                  <Link className='userDetailBtn' to={'/user/details/'+id}>
                    <button>User Details</button>
                  </Link>
                </div>
            )
        }
        },
      },
   
];
    
    const options = {
      filterType: "dropdown",
      search:true,
      filter:false,
      viewColumns: false,
      print: false,
      pagination:true,
      download: false,
      selectableRows: "none",
      // responsive: 'scrollMaxHeight',
    };
    
  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
      <span>
          <Link to='/health'><span><HiUserCircle style={{color:"#CE7777"}}/></span>Trainers</Link>/
          <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
      </span>
    </div>
  
  <div className="Header">
        <h3><span className="material-icons-outlined"><HiUserCircle style={{color:"#CE7777"}}/></span>Trainers</h3>
  </div>

  <MUIDataTable
      className="table-responsive"
      data={TRAINER?TRAINER:[]}
      columns={columns}
      options={options}
  />
</div>
  )
}

export default Trainers