/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNutritionMeal, editNutritionMeal, getNutritionDietPlan, getNutritionMeal } from '../../redux/MainActions'
import { Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import Toggle from 'react-toggle'
import swal from "sweetalert";

const NutritionMeal = () => {
  const dispatch = useDispatch()
  const [ MEAL , setMeal ] = useState([])

  // console.log( "MEAL", MEAL);
  const nutritionMeal = useSelector(state=>state?.HitFitAdmin?.NutritionMeal)
  const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
  const dietId = useSelector(state=>state?.HitFitAdmin?.NutirtionDietPlan)
  // console.log("nutritionMeal" , nutritionMeal);

  console.log("dietId" ,dietId);

  useEffect(() => {
      getMeal()
      getCetegory()
  },[])
 
  const getMeal = (id) =>{
    var data = {
      "limit":1000,
    }
    dispatch(getNutritionMeal(data))
  }

  const getCetegory =(id)=>{
    var data = {
        // "offset":50,
        "limit":1000,
        // "languageId":id,
        // "categoryId":2,
        // "categoryName":"Faltloss",
        // "isActive":true
    }
    dispatch(getNutritionDietPlan(data))
  }


  useEffect(() => {
    if(nutritionMeal){
      setMeal(nutritionMeal)
    }
  },[nutritionMeal])

  const handleDelete = (id) => {
    if(id){
      swal({
        title: "Are you sure?",
        text: "Once deleted,you will not be able to recover this Data!",
        icon: "warning",
        dangerMode: true,
        buttons: true,
      })
      .then(willDelete => {
        if (willDelete) {
          var data = {
             "mealId": id
             }
             dispatch(deleteNutritionMeal(data))
            //  console.log("deleteID", data);
        }
      });
    }
  }

  const onStatusChange = (isActive, ID)=> {
    // console.log("error", ID);
    var data= {
      "mealId": ID,
      "isActive": !isActive
    }
    dispatch(editNutritionMeal(data))
  }

  const columns = [
    {
      name:"titleEn",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Title (En)</span>
        }
      },
    },
    {
      name:"titleAr",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Title (Ar)</span>
        }
      },
    },
    {
      name:"dietPlanId",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>DietPlan</span>
        },customBodyRender:(dietPlanId)=>{
          var Filtered = dietId.filter(cate=>cate.id===dietPlanId)
          return <>
            {Filtered[0]?Filtered[0]?.titleEn:"No Defined"}
          </>
        }
      },
    },
    {
      name: "createdAt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Created Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(Date.parse(createdAt))
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            // console.log(`${day}/${month}/${year}`);
            return<div >
             {`${day}/${month}/${year}`} 
            </div>
          }
        },
      },
    {
      name: "isActive",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Status</span>
        },
        customBodyRender:(isActive,tableMeta)=>{
          var ID = tableMeta?.rowData[7]
          return(
              <div>
              <Toggle
                  checked={isActive}
                  onChange={()=> onStatusChange(isActive,ID)}
                   />
              </div>
          )
      }
      },
    },
    {
      name:"pdfEn",
      options: {
        filter: true,
        sort: true,
        // display:false,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>PDF (En)</span>
        },
        customBodyRender:(pdfEn)=>{
          const check = pdfEn?pdfEn.url? true :false :false
          return(
            <div>
             {check? <a className='pdf' href={pdfEn?pdfEn?.url:null} target="_blank">View Meal En</a> :''}
            </div>
          )
      }
      },
    },
    {
      name:"pdfAr",
      options: {
        filter: true,
        sort: true,
        // display:false,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>PDF (AR)</span>
        },
        customBodyRender:(pdfAr)=>{
          const check = pdfAr?pdfAr.url? true :false :false
          return(
            <div>
             {check? <a className='pdf' href={pdfAr?pdfAr?.url:null} target="_blank">View Meal Ar</a> :''}
            </div>
          )
      }
      },
    },
    {
      name: "id",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Action</span>
        },
        customBodyRender:(id,tableMeta)=>{
          // console.log('tableMeta',tableMeta.rowData);
          return(
            <div>
                <Link className='editBTN' to={'/nutrition/meal/'+id}>
                  <button>Edit</button>
                </Link>
              </div>
          )
      }
      },
    },
    {
      name: "id",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Action</span>
        },
        customBodyRender:(id)=>{
          return(
              <div className='deleteBTN'>
                  <button onClick={()=>handleDelete(id)}>Delete</button>
              </div>
          )
      }
      },
    },
  ];
      const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };
console.log("MEAL " ,  MEAL);
  return (
    <div className='MainContainer'>
         <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>restaurant</span>Nutrition Meal</Link>|
                <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
            </span>
        </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>restaurant</span>Nutrition Meal</h3>
            <div>
            <Link to='/nutrition/meal/add'>
                    ADD
                </Link>
            </div>
        </div>

        <MUIDataTable
            className="table-responsive"
            data={MEAL?MEAL:[]}
            columns={columns}
            options={options}
        />
    </div>
  )
}

export default NutritionMeal