/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useSelector, useDispatch } from 'react-redux'
import { BiPackage } from "react-icons/bi";
import { Link } from 'react-router-dom'
import Toggle from 'react-toggle'
import swal from "sweetalert";
import { deletePackageProgram, editPackageProgram, getPackageProgram } from '../../redux/MainActions'

const Program = () => {
 const dispatch =useDispatch()
 const packageProgram = useSelector(state=>state?.HitFitAdmin?.PackageProgram)
//  const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)

 console.log("packageProgram",packageProgram);

 const [ PROGRAM , setPROGRAM ] = useState([])

 useEffect(()=> {  
   getProgram()
 },[])

 const getProgram = (id) => {
    var data = {
        // "isActive":true,
        includeWorkouts : true,
        // "languageId":id,
        includePackages:true
    }
    dispatch(getPackageProgram(data))
 }

 useEffect(()=> {
    if(packageProgram){
        setPROGRAM(packageProgram)
    }
 },[packageProgram])

 const onStatusChange = (isActive, ID)=> {
  // console.log("error", ID);
  var data= {
    "id":ID,
    "isActive": !isActive
  }
  dispatch(editPackageProgram(data))
}
 const onStatusChangeH = (isActive, ID)=> {
  // console.log("error", ID);
  var data= {
    "id":ID,
    "isHomePage": !isActive
  }
  dispatch(editPackageProgram(data))
}
 const onStatusWeb = (isWebsite, ID)=> {
  // console.log("error", ID);
  var data= {
    "id":ID,
    "isWebsite": !isWebsite
  }
  dispatch(editPackageProgram(data))
console.log("WEBVSITE", data);
}

const handleDelete = (id)=>{
  if(id){
    swal({
      title: "Are you sure?",
      text: "Once deleted,you will not be able to recover this Data!",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    })
    .then(willDelete => {
      if (willDelete) {
        var data = {
             "programId": id
           }
           dispatch(deletePackageProgram(data))
          //  console.log("deleteID", data);
      }
    });
  } 
 }

 const columns = [
    {
      name:"MainImage",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: ()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Image</span>
        },
        customBodyRender:(MainImage)=>{
          return<div className='tableIMG'>
            <img src={MainImage?.url}  alt='No Image here!'/>
          </div>
        }
      }
    },
    {
      name:"BannerImage",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Banner Image</span>
        },
        customBodyRender:(BannerImage)=>{
          return<div className='tableIMG'>
            <img src={BannerImage?.url} alt="No Image here!" />
          </div>
        }
      },
    },
    {
      name: "titleEn",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Title(En)</span>
        }
      },
    },
    {
      name: "titleAr",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender:()=>{
          return<span style={{
            letterSpacing:'0',
            fontWeight:'600'
          }}>Title(Ar)</span>
        }
      },
    },
    {
      name: "createdAt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Created Date</span>
          },
          customBodyRender:(createdAt)=>{
            let date = new Date(Date.parse(createdAt))
            let year = date.getFullYear()
            let month = date.getMonth()+1
            let day = date.getDate()
            return<div >
             {`${day}/${month}/${year}`}
            </div>
          }
        },
      },
    {
      name: "isActive",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Status</span>
          },
          customBodyRender:(isActive,tableMeta)=>{
            var ID = tableMeta?.rowData[7]
            return(
                <div>
                <Toggle
                    checked={isActive}
                    onChange={ ()=> onStatusChange(isActive, ID)}
                     />
                </div>
            )
        }
        },
      },
    {
      name: "isHomePage",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Home Status</span>
          },
          customBodyRender:(isHomePage,tableMeta)=>{
            var ID = tableMeta?.rowData[8]
            return(
                <div>
                <Toggle
                    checked={isHomePage}
                    onChange={ ()=> onStatusChangeH(isHomePage, ID)}
                     />
                </div>
            )
        }
        },
      },
    {
      name: "isWebsite",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Website Status</span>
          },
          customBodyRender:(isWebsite,tableMeta)=>{
            var ID = tableMeta?.rowData[8]
            return(
                <div>
                <Toggle
                    checked={isWebsite}
                    onChange={ ()=> onStatusWeb(isWebsite, ID)}
                     />
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Action</span>
          },
          customBodyRender:(id)=>{
            return(
                <div>
                  <Link className='editBTN' to={'/package/program/'+id}>
                    <button>Edit</button>
                  </Link>
                </div>
            )
        }
        },
      },
      {
        name: "id",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Action</span>
          },
          customBodyRender:(id)=>{
            return(
                <div className='deleteBTN'>
                    <button onClick={()=> handleDelete(id)}>Delete</button>
                </div>
            )
        }
        },
      },
   
];

    const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };

  return (
    <div className='MainContainer'>
    <div className="breadcrumb">
       <span>
           <Link to='/health'><span><BiPackage style={{color:"#CE7777"}}/></span>Package Program</Link>/
           <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
       </span>
    </div>
   
   <div className="Header">
        <h3><span className="material-icons-outlined"><BiPackage style={{color:"#CE7777"}}/></span>Package Program</h3>
       <div>
           <Link to='/package/program/add'>
               ADD
           </Link>
       </div>
   </div>

   <MUIDataTable
       className="table-responsive"
       data={PROGRAM?PROGRAM:[]}
       columns={columns}
       options={options}
   />
</div>
  )
}

export default Program