/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { useSelector, useDispatch } from 'react-redux'
import { BiPackage } from "react-icons/bi";
import { Link } from 'react-router-dom'
import Toggle from 'react-toggle'
import swal from "sweetalert";
import { deletePackage, editPackage, getPackage } from '../../redux/MainActions'

const Package = () => {
    
    const dispatch =useDispatch()
    const getPackageLIST = useSelector(state=>state?.HitFitAdmin?.Package)
    // const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
    const [ PACKAGE , setPACKAGE ] = useState([])

    useEffect(()=> {
      getList()
    },[]) 

    const getList = (id) => {
        var data ={
            // "startRange": 0,
            // "rangeLimit": 10,
            // "isActive":true,
            // "languageId":id,
        }
        dispatch(getPackage(data))
    }

    useEffect(() => {
        if(getPackageLIST){
            setPACKAGE(getPackageLIST)
        }
    }, [getPackageLIST])

    const onStatusChange = (isActive, ID)=> {
      console.log("error", ID);
      var data= {
        "id":ID,
        "isActive": !isActive
      }
      dispatch(editPackage(data))
    }

    const onStatusChangeHome = (isHomePage, ID)=> {
      console.log("error", ID);
      var data= {
        "id":ID,
        "isHomePage": !isHomePage
      }
      dispatch(editPackage(data))
    }

    const handleDelete = (id)=>{
      if(id){
        swal({
          title: "Are you sure?",
          text: "Once deleted,you will not be able to recover this Data!",
          icon: "warning",
          dangerMode: true,
          buttons: true,
        })
        .then(willDelete => {
          if (willDelete) {
            var data = {
                "packageId": id
               }
               dispatch(deletePackage(data))
              //  console.log("deleteID", data);
          }
        });
      }   
    }

      // console.log("getPackageLIST",getPackageLIST);
    const columns = [
        {
          name: "titleEn",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Title (En)</span>
            }
          },
        },
        {
          name: "titleAr",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Title (Ar)</span>
            }
          },
        },
        {
          name: "createdAt",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Created Date</span>
              },
              customBodyRender:(createdAt)=>{
                let date = new Date(Date.parse(createdAt))
                let year = date.getFullYear()
                let month = date.getMonth()+1
                let day = date.getDate()
                // console.log(`${day}/${month}/${year}`);
                return<div >
                 {`${day}/${month}/${year}`}
                </div>
              }
            },
          },
        {
          name: "isActive",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Status</span>
              },
              customBodyRender:(isActive,tableMeta)=>{
                var ID = tableMeta?.rowData[5]
                return(
                    <div>
                    <Toggle
                        checked={isActive}
                        onChange={()=>onStatusChange(isActive,ID)}
                         />
                    </div>
                )
            }
            },
          },
        {
          name: "isHomePage",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>HomePage Status</span>
              },
              customBodyRender:(isHomePage,tableMeta)=>{
                var ID = tableMeta?.rowData[5]
                return(
                    <div>
                    <Toggle
                        checked={isHomePage}
                        onChange={()=>onStatusChangeHome(isHomePage,ID)}
                         />
                    </div>
                )
            }
            },
          },
          {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Action</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div>
                      <Link className='editBTN' to={'/package/'+id}>
                        <button>Edit</button>
                      </Link>
                    </div>
                )
            }
            },
          },
          {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Action</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div className='deleteBTN'>
                        <button onClick={()=> handleDelete(id)}>Delete</button>
                    </div>
                )
            }
            },
          },
       
    ];
    

      const options = {
        filterType: "dropdown",
        search:false,
        filter:false,
        viewColumns: false,
        print: false,
        pagination:true,
        download: false,
        selectableRows: "none",
        // responsive: 'scrollMaxHeight',
    };
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><BiPackage style={{color:"#CE7777"}}/></span>Package</Link>/
              <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><BiPackage style={{color:"#CE7777"}}/></span>Package</h3>
          <div>
              <Link to='/package/add'>
                  ADD
              </Link>
          </div>
      </div>

      <MUIDataTable
          className="table-responsive"
          data={PACKAGE?PACKAGE:[]}
          columns={columns}
          options={options}
      />
    </div>
  )
}

export default Package