import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {BsFillImageFill} from "react-icons/bs"
import { Link, useParams } from 'react-router-dom'
import { UploadMedia , addWebImg , getWebImg,editWebImg } from "../../redux/MainActions"
import swal from 'sweetalert'

const WebImgAdd = () => {
    const param = useParams()
    const dispatch = useDispatch()
    const webI = useSelector(state=> state?.HitFitAdmin?.webImg)

    useEffect(()=>{
        getRoleList()
    },[])

    const getRoleList = () =>{
        var data = {
            // "status":true
        }
        dispatch(getWebImg(data))
    }

    useEffect(()=> {
        if(webI!==undefined && param?.id){
            var spr = webI?.filter(data=>data.id==param?.id)
            console.log("spr",spr);
            setImgURLEn(spr[0]?.MediaEn?.url)
            setImgURLAr(spr[0]?.MediaAr?.url)
            setImgIDEn(spr[0]?.MediaEnId)
            setImgIDAr(spr[0]?.MediaArId)
            setWebD({
                priority:spr[0]?.priority,
                titleEn: spr[0]?.titleEn,
                titleAr: spr[0]?.titleAr,
                // isActive:spr[0]?.isActive,
            })
        }
    },[webI])

    const [imgURLEn, setImgURLEn] = useState('')
    const [imgIDEn, setImgIDEn] = useState('')
   
    const [imgURLAr, setImgURLAr] = useState('')
    const [imgIDAr, setImgIDAr] = useState('')

    const [webD , setWebD ] = useState({
        priority:"",
        titleEn:"",
        titleAr:"",
        // isActive:"true",
      })

    // const [bannerURL, setBannerURL] = useState('')
    // const [bannerID, setBannerID] = useState('')

    const onChangeHandle = (e) => {
        const { value , name } = e.target
        setWebD({...webD,[name]: value})
    }

    const [mediaName, setMediaName] = useState('')

    const ImageData = useSelector(state=>state?.HitFitAdmin?.ImageData)

    const onImageChange = (e) =>{
        setMediaName(e.target.name)
        var file = e.target.files[0]
        var url = URL.createObjectURL(e.target.files[0]) 
        setImgURLEn(url)
    
        const formData = new FormData()
    
        formData.append('medias',file)
    
    
        dispatch(UploadMedia(formData))
    }

    const onBannerChange = (e) =>{
        setMediaName(e.target.name)
        var file = e.target.files[0]
        var url = URL.createObjectURL(e.target.files[0]) 
        setImgURLAr(url)
    
        const formData = new FormData()
    
        formData.append('medias',file)
    
        dispatch(UploadMedia(formData))
    }
    useEffect(() => {
        if(ImageData!==null){
          if(mediaName==='ImageEn'){
            setImgIDEn(ImageData?.id)
          }else if(mediaName==='ImageAr'){
            setImgIDAr(ImageData?.id)
          }
        }
      }, [ImageData, mediaName])

      const submitHandle = (e) =>{
        e.preventDefault()
        if(imgIDEn===''){
            swal({
              title: 'Error!',
              text: "Please Add Image  En!",
              icon: "warning",
              button: "Ok",
            });
            return false
        }else if(imgIDAr===""){
            swal({
                title: 'Error!',
                text: "Please Add Image  Ar!",
                icon: "warning",
                button: "Ok",
              });
              return false
        }
       if(param?.id){
        var data = {
            id: param?.id,
            MediaEnId: imgIDEn,
            MediaArId: imgIDAr,
            titleEn: webD?.titleEn,
            titleAr: webD?.titleAr,
            priority: parseInt(webD.priority),
            isActive: webD?.isActive==="true"? true : false
        }
        dispatch(editWebImg(data))
       }else{
        var data = {
            MediaEnId: imgIDEn,
            MediaArId: imgIDAr,
            titleEn: webD?.titleEn,
            titleAr: webD?.titleAr,
            priority: parseInt(webD.priority),
        }
        dispatch(addWebImg(data))
       }
      }
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
            <span>
                <Link to='/health'><span><BsFillImageFill style={{color:"#CE7777"}}/></span>Package </Link>/
                <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined"><BsFillImageFill style={{color:"#CE7777"}}/></span>{param?.id?"Edit":"Add"} Website Images</h3>
            <div>
                <Link to='/website-image'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
            <form onSubmit={submitHandle} >
                <div className="InputF">
                <div className="inputField_type_one">
                <label htmlFor="titleData">Name (En)</label>
                <input id='titleData' value={webD.titleEn} onChange={onChangeHandle} type="text" name='titleEn' placeholder='e.g: Mohamad' />                
                <label htmlFor='priority'>Priority</label>
                        <input type="number" id='priority' name='priority' placeholder='E.g: 123' value={webD?.priority} onChange={onChangeHandle} />
                </div>
                        <div className="isActive">
                        <label htmlFor="description">Name (Ar)</label>
                        <input value={webD.titleAr} onChange={onChangeHandle} id="description" type="text" name='titleAr' placeholder='e.g: Mohamad' />
                    </div>
                </div>
                <div className='MediaUploads'>
                    <div className="imgUpload">
                        <div className='imgContainer'>
                            <img src={imgURLEn} alt=""   />
                            {!imgURLEn&&<p>Please Select Image!</p>}
                        </div>
                        <label htmlFor="imgButton">Select Image En</label>
                        <input onChange={onImageChange} type="file" name="ImageEn" id="imgButton"  accept="image/png, image/gif, image/jpeg"/>
                    </div>

                    <div className="imgUpload">
                        <div className='imgContainer'>
                            <img src={imgURLAr} alt=""   />
                            {!imgURLAr&&<p>Please Select ImageAr!</p>}
                        </div>
                        <label htmlFor="bannerButton">Select Image Ar</label>
                        <input onChange={onBannerChange} type="file" name="ImageAr" id="bannerButton"  accept="image/png, image/gif, image/jpeg"/>
                    </div>
                </div>
               

                <div className="btn">
                    <button type='submit'><span className="material-icons-outlined">save</span>{"param".id? "Update": "Save"}</button>
                    <Link to='/website-image'>Cancel</Link>
                </div>
            </form>
        </div>
    </div>
  )
} 

export default WebImgAdd