import './topbar.css'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { MdLogout } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
// import { getLanguage } from '../../redux/MainActions'


const Topbar = () => {
    const dispatch = useDispatch()

    // const Language = useSelector(state=>state?.HitFitAdmin?.Langauge)

    // const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)

    const onLogout =()=>{
        localStorage.clear()
        window.location.href='#/'
        window.location.reload();
    }
    // useEffect(()=>{
    //     getLanguageData()
    // },[])
      

    // const getLanguageData = () => {
    //     dispatch(getLanguage())
    // }

    // useEffect(() => {
    //  if(LanguageID){
    //     localStorage.setItem('languageId',LanguageID)
    //  }
    // }, [LanguageID])
    


    // const onHandleChange = (e) => {
    //     dispatch(setLanguageID(e.target.value))
    // }
     

    // console.log("LanguageID" , LanguageID);

      
  return (
    <div className='topbar'>
        <div className="topbarWraper">
            <div className="topLeft">
                <span className="logo">HITFIT Admin</span>
            </div>
            <div className="topRight">
                {/* <div className="language">
                    <select name="languageId" id='languageId' onChange={onHandleChange}>
                       {Language?.length>0?Language.map((lang, index)=> (
                        <option value={lang.id} key={index}>{lang.name} ({lang.code})</option>
                       )):null}
                    </select>
                </div> */}
                <div className="topbarIconContainer">
                   <IoMdNotificationsOutline/>
                </div>
                <div className="topbarIconContainer">
                    <MdLogout onClick={onLogout}/>
                </div>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTu3_qIHtXBZ7vZeMQhyD8qLC1VRB9ImHadL09KET_iSQEX6ags4ICknfmqEKz8Nf6IOsA&usqp=CAU" alt="" className="topAvatar" />
            </div>
        </div>
    </div>
  )
}

export default Topbar