/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect,useState} from 'react'
import ReactQuill from 'react-quill';
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getNutritionDietPlan , addNutritionMeal, getNutritionMeal , editNutritionMeal, getLanguage,UploadMedia } from '../../redux/MainActions'
import { MdDeleteOutline } from "react-icons/md";
import swal from 'sweetalert';
import Select from 'react-select';

 
const NutritionMealAddEdit = () => {
  const param = useParams()
  // console.log("param", param);

  const dispatch = useDispatch()

  const nutritionDietPlan = useSelector(state=>state.HitFitAdmin.NutirtionDietPlan)
  // const Language = useSelector(state=>state?.HitFitAdmin?.Langauge)
  const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
  console.log("Language",LanguageID);

  const NutritionMeal = useSelector(state=> state.HitFitAdmin.NutritionMeal)
  // console.log("nutritionMeal",NutritionMeal);
  
  const [ DietOptions , setDietOptions ] = useState([])
  const [ DietValue , setDietValue ] = useState([])

  const [ mealDetailEn , setMealDetalEn ] = useState([{ mealTitle:'' , description:[]}])
  const [ mealDetailAr , setMealDetalAr ] = useState([{ mealTitle:'' , description:[]}])
  const [ mealData , setMealData ] = useState({
    titleEn: '',
    titleAr: '',
    descriptionEn: '',
    descriptionAr: '',
    isActive: 'true' ,
    dietPlanId: null ,
    priority:''
  })   

  const [ descEn , setDescEn ] = useState(null)
  const [ descAr , setDescAr ] = useState(null)

  const [pdfURL, setPdfURL] = useState('')
  const [pdfID, setPdfID] = useState('')

  const [pdfURLAr, setPdfURLAr] = useState('')
  const [pdfIDAr, setPdfIDAr] = useState('')

  const [mediaName, setMediaName] = useState('')

  const ImageData = useSelector(state=>state?.HitFitAdmin?.ImageData)

  const onImageChange = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    // var url = URL.createObjectURL(e.target.files[0]) 
    // setPdfURL(url)

    const formData = new FormData()

    formData.append('medias',file)


    dispatch(UploadMedia(formData))
}
  const onImageChangeAr = (e) =>{
    setMediaName(e.target.name)
    var file = e.target.files[0]
    // var url = URL.createObjectURL(e.target.files[0]) 
    // setPdfURL(url)

    const formData = new FormData()

    formData.append('medias',file)


    dispatch(UploadMedia(formData))
}

  const onHandleChange = (e) => {
    const {name , value} = e.target
    setMealData({...mealData,[name]:value})
     if(e.target.name === 'dietPlanId'){
      setMealData({...mealData,[name]:parseInt(value)})
     }
  }

  useEffect(() => {
    getCetegory()
 }, [])

  useEffect(() => {
    if(param.mealId){
      getMeal(param.mealId)
    }
  },[param.mealId])

  const getMeal = (id) => {
    var data = {
      "mealId":id
    }
    dispatch(getNutritionMeal(data))
  }


  useEffect(() => {
    if(ImageData!==null){
      if(mediaName==='ImageEn'){
        setPdfID(ImageData?.id)
      }else if (mediaName==='ImageAr'){
        setPdfIDAr(ImageData?.id)
      }
    }
  }, [ImageData, mediaName])

  const getCetegory = (id)=>{
   var data = {
       "offset":0,
       "limit":1000,
       // "categoryId":2,
      //  "languageId":id,
       // "categoryName":"Faltloss",
      //  "isActive":true
   }
   dispatch(getNutritionDietPlan(data))
 }
 
 useEffect(()=>{
  if(nutritionDietPlan?.length>0){
    var data = nutritionDietPlan?.map(opt=>{
      return {
        label: opt.titleEn,
        value:opt.id
      }
    })
    setDietOptions(data)
  }
},[nutritionDietPlan])

const onCateChange = (value) => {
  console.log("VALUE", value);
  setDietValue(value)
}

const handleChangeEn = (value) => {
  setDescEn(value)
};
const handleChangeAr = (value) => {
  setDescAr(value)
};


 useEffect(()=>{
  if(NutritionMeal&&param.mealId){
    var spr = NutritionMeal.filter(data=>data.id==param.mealId)
    var spr2 = nutritionDietPlan.filter(diet=>diet.id==spr[0]?.dietPlanId)
    console.log("spr",spr);
    setMealData({
      titleEn: spr[0]?.titleEn,
      titleAr: spr[0]?.titleAr,
      // descriptionAr: spr[0]?.descriptionAr,
      // descriptionEn: spr[0]?.descriptionEn,
      // dietPlanId: spr[0]?.dietPlanId,
      priority: spr[0]?.priority,
      isActive:spr[0]?.isActive?"true":"false",
    })
    setMealDetalEn(spr[0]?.mealDetailsEn!== null?spr[0]?.mealDetailsEn?.data:[])
    setMealDetalAr(spr[0]?.mealDetailsAr!==null?spr[0]?.mealDetailsAr?.data:[])
    setDietValue({label: spr2[0]?.titleEn, value: spr2[0]?.id})
    setDescEn(spr[0]?.descriptionEn)
    setDescAr(spr[0]?.descriptionAr)
    setPdfID(spr[0]?.pdfEnId)
    setPdfURL(spr[0]?.pdfEn?.url)
    setPdfURLAr(spr[0]?.pdfAr?.url)
    setPdfIDAr(spr[0]?.pdfArId)
  }
 },[NutritionMeal])

  const addDietDetailEn = () =>{
    setMealDetalEn([...mealDetailEn, { mealTitle:'' , description:[]}])
  }
  const addDietDetailAr = () =>{
    setMealDetalAr([...mealDetailAr, { mealTitle:'' , description:[]}])
  }

  const onChangeAdd= (e, index) => {
    const  mealEn = mealDetailEn.map((meals, i ) => index === i ? 
    Object.assign(meals,{[e.target.name]: e.target.value}) : 
    meals )
    // const { name, value } = e.target;
    // const list = [...mealDetail];
    // list[index][name] = value;
     setMealDetalEn(mealEn);
  }

  const onChangeAddEn = (e , index)=>{
    const  mealEn = mealDetailEn.map((meals, i ) => index === i ? 
    Object.assign(meals,{"description": e}) : 
    meals )
    setMealDetalEn(mealEn);
  }

  const onChangeAddAr = (e, index) => {
    const  mealAr = mealDetailAr.map((meals, i ) => index === i ? 
    Object.assign(meals,{[e.target.name]: e.target.value}) : 
    meals )
    // const { name, value } = e.target;
    // const list = [...mealDetail];
    // list[index][name] = value;
     setMealDetalAr(mealAr);
  }

  const onChangedescAr = (e, index)=>{
    const  mealAr = mealDetailAr.map((meals, i ) => index === i ? 
    Object.assign(meals,{description: e}) : 
    meals )
    // const { name, value } = e.target;
    // const list = [...mealDetail];
    // list[index][name] = value;
     setMealDetalAr(mealAr);
  }

  const removeDietD = (index) => {
    const filterDiet = [...mealDetailEn];
    filterDiet.splice(index, 1);
    setMealDetalEn(filterDiet);
  }
  const removeDietDAr = (index) => {
    const filterDiet = [...mealDetailAr];
    filterDiet.splice(index, 1);
    setMealDetalAr(filterDiet);
  }

  console.log("pdfURLAr",pdfURLAr);
  console.log("pdfIDAr",pdfIDAr);

  // const handleMealChange = (event,index,idx) =>{
    
	// 	var checkedItem = event.target.value
  
	// 	// let newArray = [...mealDetail];

	// 	// if (mealDetail[index].description.includes(checkedItem)) {
	// 	// 	newArray[index].description = newArray[index].description.filter(meal => meal !== checkedItem);
	// 	// }else{
  //   //   newArray[index].description = [...mealDetail[index].description,checkedItem]
  //   // }

	// 	// setMealDetal(newArray);
	// }

  const onSubmit = (e) => {
    e.preventDefault()

    if(mealData.titleEn===''){
      swal({
        title: 'Error!',
        text: "Please Add TitleEn !",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else if(mealData.titleAr===''&& mealData.titleAr=== null){
      swal({
        title: 'Error!',
        text: "Please Add TitleAr !",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else if(mealData.dietPlanId===''){
      swal({
        title: ' Error!',
        text: "Please Add Diet Plan!",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else if(mealData.isActive===''){
      swal({
        title: 'Error!',
        text: "Please select status!",
        icon: "warning",
        button: "Ok",
      });
      return false
    }

    if(param.mealId){
      var data = {
        mealId:parseInt(param.mealId),
        titleEn: mealData.titleEn,
        titleAr: mealData.titleAr,
        dietPlanId: DietValue.value,
        descriptionEn:descEn,
        descriptionAr: descAr,
        isActive: mealData.isActive === 'true'?true : false,
        // languageId: LanguageID,
        pdfEnId: parseInt(pdfID),
        pdfArId: parseInt(pdfIDAr),
        mealDetailsEn:{data : mealDetailEn},
        mealDetailsAr:{data : mealDetailAr},
        priority: parseInt(mealData.priority)
      }
      dispatch(editNutritionMeal(data))
      console.log("data" , data);
    }else{
      var data = {
      titleEn: mealData.titleEn,
      titleAr: mealData.titleAr,
      dietPlanId: DietValue.value,
      descriptionEn:descEn,
      descriptionAr: descAr,
      pdfEnId: parseInt(pdfID),
      pdfArId: parseInt(pdfIDAr),
      // languageId: LanguageID,
      isActive: mealData.isActive === 'true'?true : false,
      mealDetailsEn:{data : mealDetailEn},
      mealDetailsAr:{data : mealDetailAr},
      priority: parseInt(mealData.priority)
    }
     dispatch(addNutritionMeal(data))
    }
    
    // console.log("data" , data);
  }

  console.log('mealDetail',mealDetailEn);


  
 
  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
            <span>
                <Link to='/health'><span className="material-icons-outlined" style={{color:"#CE7777"}}>restaurant</span>{param.mealId? "Edit" : "Add"} Nutrition Meal</Link>/
                <Link to='/'><span className="material-icons-outlined" style={{color:"#CE7777"}}>home</span>Home</Link>
            </span>
         </div>
        
        <div className="Header">
             <h3><span className="material-icons-outlined" style={{color:"#CE7777"}}>restaurant</span>{param.mealId? "Edit" : "Add"} Nutrition Meal</h3>
            <div>
                <Link to='/nutrition/meal'>
                    Back
                </Link>
            </div>
        </div>

        <div className="Box_Container">
          <form onSubmit={onSubmit} >
                <div className="InputF">
                  <div className="inputField_type_one">
                    <label htmlFor="titleData">Title (En)</label>
                    <input type="text"  id='titleData' value={mealData.titleEn} onChange={onHandleChange} name='titleEn' placeholder='E.g:  System 25'/>
                    
                    <label htmlFor="isActive">Status</label>
                    <select name="isActive" id="isActive" value={mealData.isActive} onChange={onHandleChange} >
                      <option value="">- Select Status -</option>
                      <option value="true">Active</option>
                      <option value="false">In-Active</option>
                    </select>

                   

                    <label htmlFor="descriptionEn">Description (En)</label>
                    <ReactQuill
                          name = "descriptionEn"
                          value={descEn}
                          onChange={handleChangeEn}
                          className='mealDesc'
                    />

                    <label htmlFor="titleData" style={{marginTop:'60px'}}>Meal Pdf En</label>
                    <input type="file"  id='titleData' onChange={onImageChange} name="ImageEn" placeholder='E.g:  System 25'/>

                    {<a style={{fontSize:"10px"}} href={pdfURL} target="_blank">{pdfURL}</a>}

                    <label htmlFor="priority" style={{marginTop:'15px'}}>Priority</label>
                    <input type="number"  id='priority' value={mealData.priority} onChange={onHandleChange} name='priority' placeholder='E.g:  1, 2 ,3'/>
                   
                  </div>

                  <div className="isActive">
                    <label htmlFor="titleData1">Title (Ar)</label>
                    <input type="text"  id='titleData1' value={mealData.titleAr} onChange={onHandleChange} name='titleAr' placeholder='E.g:  System 25'/>

                    <label htmlFor="dietID">{LanguageID==1?"Diet-Plan":"Diet-Plan-Ar"}</label>
                    <Select
                      className='selctField'
                      onChange={onCateChange}
                      options={DietOptions}
                      value={DietValue}
                    />
                    
                    <label htmlFor="descriptionAr">Description (Ar)</label>
                    <ReactQuill
                          name = "descriptionAr"
                          value={descAr}
                          onChange={handleChangeAr}
                          className='quill'
                    />

                    <label htmlFor="titleData" style={{marginTop:'50px'}}>Meal Pdf Ar</label>
                    <input type="file"  id='titleData' onChange={onImageChangeAr} name="ImageAr" placeholder='E.g:  System 25'/>

                    {<a style={{fontSize:"10px"}} href={pdfURLAr} target="_blank">{pdfURLAr}</a>}
                  
                  </div>
                </div>
                
                <div className="subInputF">
                 <span>Meal Details (En)</span>
                {mealDetailEn?.map((diet , index )=>(
                  <div className="DietInPutF" key={index}>

                    <div className="dietType">
                      <div className="inputField_type_one">
                        <label htmlFor='title'>Meal-Title</label>
                        <input type="text" name='mealTitle' id='title' placeholder='e.g: Protien Diet' onChange={(e)=> onChangeAdd( e, index )} value={diet.mealTitle} />
                      </div>
                      <div className="mealTitles">
                        <label htmlFor='description'>Description</label>
                        {/* <textarea name="description" id="description" cols="30" rows="3"  placeholder='E.g: Water , Eggs , Protien'  onChange={(e)=> onChangeAdd( e, index )} value={diet.description} /> */}
                        <ReactQuill name="description" value={diet.description} onChange={(e)=> onChangeAddEn( e, index )} className='descEn' />
                      </div>

                    </div>

                    {mealDetailEn.length>1&&<MdDeleteOutline className='delete' onClick={()=> removeDietD(index)} />}
                  </div>
                ))}
               <p className='addBTn' onClick={ addDietDetailEn } >Add More</p>
              </div>
              <hr style={{width:"auto" , border: "2px solid #DDDDDD ", borderRadius: "5px"}}/>
              <div className="subInputF">
                 <span>Meal Details (Ar)</span>
                {mealDetailAr?.map((diet , index )=>(
                  <div className="DietInPutF" key={index}>

                    <div className="dietType">
                      <div className="inputField_type_one">
                        <label htmlFor='title'>Meal-Title</label>
                        <input type="text" name='mealTitle' id='title' placeholder='e.g: Protien Diet' onChange={(e)=> onChangeAddAr( e, index )} value={diet?.mealTitle} />
                      </div>
                      <div className="mealTitles">
                        <label htmlFor='descriptionA'>Description</label>
                        <ReactQuill  name="description" style={{direction:"rtl" , textAlign:"right"}} value={diet?.description} onChange={(e)=> onChangedescAr( e, index )} className='descEn' />

                        {/* <textarea name="description" id="descriptionA" cols="30" rows="3"  placeholder='E.g: Water , Eggs , Protien'  onChange={(e)=> onChangeAddAr( e, index )} value={diet.description} /> */}
                      </div>
                    </div>
                    {mealDetailAr.length>1&&<MdDeleteOutline className='delete' onClick={()=> removeDietDAr(index)} />}
                  </div>
                ))}
               <p className='addBTn' onClick={ addDietDetailAr } >Add More</p>
              </div>

            <div className="btn">
                <button type='submit'><span className="material-icons-outlined">save</span>{param.mealId? "Update": "Save"}</button>
                <Link to='/nutrition/meal'>Cancel</Link>
            </div>
          </form>
        </div>
    </div>
  )
}

export default NutritionMealAddEdit