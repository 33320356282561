import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import Toggle from 'react-toggle'
import { useDispatch, useSelector } from 'react-redux'
import { deletePackageProgramWorkout, editPackageProgramWorkout, getPackageProgramWorkout } from '../../redux/MainActions'
import { GiWeightLiftingUp } from 'react-icons/gi'
import swal from "sweetalert";

const Workout = () => {

    const dispatch = useDispatch()
    const workoutList = useSelector(state=> state?.HitFitAdmin?.ProgramWorkout)
    const LanguageID = useSelector(state=>state?.HitFitAdmin?.LanguageID)
     console.log("LanguageID",LanguageID);

    const [ WORKOUT , setWORKOUT ] = useState([])

    useEffect(()=> {
      getWorkout(LanguageID)  
    },[])

    const getWorkout = (id) => {
        var data = {
            // "isActive":true,
            // "languageId":id,
        }
        dispatch(getPackageProgramWorkout(data))
    }

    useEffect(()=> {
        if(workoutList){
          setWORKOUT(workoutList)
        }
    },[workoutList])

    const onStatusChange = (isActive, ID)=> {
      // console.log("error", ID);
      var data= {
        "id": ID,
        "isActive": !isActive
      }
      dispatch(editPackageProgramWorkout(data))
    }

    const handleDelete = (id)=>{
      if(id){
        swal({
          title: "Are you sure?",
          text: "Once deleted,you will not be able to recover this Data!",
          icon: "warning",
          dangerMode: true,
          buttons: true,
        })
        .then(willDelete => {
          if (willDelete) {
            var data = {
                "workoutId": id
               }
               dispatch(deletePackageProgramWorkout(data))
              //  console.log("deleteID", data);
          }
        });
      }   
    }
   const columns = [

      {
       name: "titleEn",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Title (En)</span>
          }
        },
      },
      {
       name: "titleAr",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender:()=>{
            return<span style={{
              letterSpacing:'0',
              fontWeight:'600'
            }}>Title (Ar)</span>
          }
        },
      },
      {
        name: "createdAt",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Created Date</span>
            },
            customBodyRender:(createdAt)=>{
              let date = new Date(Date.parse(createdAt))
              let year = date.getFullYear()
              let month = date.getMonth()+1
              let day = date.getDate()
              // console.log(`${day}/${month}/${year}`);
              return<div >
               {`${day}/${month}/${year}`}
              </div>
            }
          },
        },
      {
        name: "isActive",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender:()=>{
              return<span style={{
                letterSpacing:'0',
                fontWeight:'600'
              }}>Status</span>
            },
            customBodyRender:(isActive,tableMeta)=>{
              var ID = tableMeta?.rowData[5]
              return(
                  <div>
                  <Toggle
                      checked={isActive}
                      onChange={()=>onStatusChange(isActive, ID)}
                       />
                  </div>
              )
          }
          },
        },
        {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Action</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div>
                      <Link className='editBTN' to={'/package/program/workout/'+id}>
                        <button>Edit</button>
                      </Link>
                    </div>
                )
            }
            },
          },
          {
            name: "id",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender:()=>{
                return<span style={{
                  letterSpacing:'0',
                  fontWeight:'600'
                }}>Action</span>
              },
              customBodyRender:(id)=>{
                return(
                    <div className='deleteBTN'>
                        <button onClick={ ()=> handleDelete(id)}>Delete</button>
                    </div>
                )
            }
            },
          },

   ] 

   const options = {
    filterType: "dropdown",
    search:false,
    filter:false,
    viewColumns: false,
    print: false,
    pagination:true,
    download: false,
    selectableRows: "none",
    // responsive: 'scrollMaxHeight',
  };

  return (
    <div className='MainContainer'>
        <div className="breadcrumb">
          <span>
              <Link to='/health'><span><GiWeightLiftingUp style={{color:"#CE7777"}}/></span>Workout</Link>/
              <Link to='/'><span className="material-icons-outlined">home</span>Home</Link>
          </span>
        </div>
      
      <div className="Header">
            <h3><span className="material-icons-outlined"><GiWeightLiftingUp style={{color:"#CE7777"}}/></span>Workout</h3>
          <div>
              <Link to='/package/program/workout/add'>
                  ADD
              </Link>
          </div>
      </div>

      <MUIDataTable
          className="table-responsive"
          data={WORKOUT?WORKOUT:[]}
          columns={columns}
          options={options}
      />
    </div>
  )
}

export default Workout